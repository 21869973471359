import React, { useState } from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  Box,
  Chip,
  Divider,
} from "@mui/material";
import { HobbyModel } from "../model/ResumeBuilderModel";
import '../myStyles/inputInformation.css';

const Hobby: React.FC<Type.IHobby> = (props) => {
  const { t } = useTranslation();
  console.log("Hobby");
  const [hobbyModel, setHobbyModel] = useState<HobbyModel>({
    Name: "",
  });
  const [hobbyError, setHobbyError] = useState("");
  const hobbyNameRef = React.useRef<null | HTMLInputElement>(null);

  const resetData = () => {
    setHobbyModel({ Name: "" });
  };

  const addHobby=()=>{
     if (hobbyModel.Name == "") {
       setHobbyError(t("SpecifyHobbyName"));
       hobbyNameRef.current?.focus();
     } else {
        props.AddHobbyHandler(hobbyModel.Name, resetData);
     }
  }

  return (
    <React.Fragment>
      <Grid container spacing={2} className="hobby_main_cont">
        <Grid className="hobby_main" item xs={12}>
          <TextField
            id="Hobby_Header"
            label={t("Header")}
            variant="outlined"
            size="small"
            fullWidth
            className='resume_input'
            name="Hobby.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
          />
        </Grid>
        <Divider sx={{ mb: 2 }} />
        <Grid item xs={12} md={10} className="hobby_field">
          <TextField
            id="Add_Hobby_txt"
            label={t("Hobby")}
            variant="outlined"
            size="small"
            className='resume_input'
            fullWidth
            value={hobbyModel.Name}
            inputRef={hobbyNameRef}
            error={Boolean(hobbyError)}
            helperText={hobbyError}
            onChange={(e) => {
              setHobbyError("");
              setHobbyModel({
                ...hobbyModel,
                Name: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={2} className="add_hobby">
          <Button
          className="add_btn_resume"
            variant="contained"
            sx={{ mt: "2px" }}
            fullWidth
            onClick={addHobby}
          >
            {t("Add")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              borderRadius: 1,
              border: 1,
              borderColor: "grey.500",
              height: 100,
              p: 1,
              overflow: "auto",
            }}
            component="div"
          >
            {props.HobbiesDetail?.map((s, index) => {
              let chiplabel = s.Name;
              return (
                <Chip
                  key={index}
                  label={chiplabel}
                  size="small"
                  variant="outlined"
                  sx={{ mr: 1, mt: 1 }}
                  onDelete={(e) => props.RemoveHobbyHandler(index)}
                />
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(Hobby);
