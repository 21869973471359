import * as yup from 'yup';

export const LoginValidationSchema = yup.object({
    email: yup.string()
              .email('SpecifyEmail')
              .required('SpecifyValidEmail'),
    password : yup.string()
                  .required('SpecifyPassword')
});

export type LoginModel = yup.InferType<typeof LoginValidationSchema>;