import * as yup from "yup";


export const FeedbackSchema = yup.object({
  Id:yup.string().notRequired(),
  Feedback: yup
    .string()
    .required("SpecifyFeedback")
    .test("FeedbackLength", "FeedbackLengthMsg", function (value) {
      if (value) {
        return value.length < 3999;
      }
      return false;
    }),
  User: yup.object({
    Id: yup.string(),
    Name: yup.string(),
    Mobile: yup.string(),
    Email: yup.string().email(),
  }),
  FeedbackRating:yup.number().default(0),
  Read: yup.boolean(),
  ReadDate: yup.date().default(() => new Date()),
  FeedbackDate: yup.date().default(() => new Date()),
  ImagePath:yup.string().notRequired()
});

export type FeedbackModel = yup.InferType<typeof FeedbackSchema>;