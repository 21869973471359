import React, { useState } from "react";
import { ResumeBuilderModel } from "../model/ResumeBuilderModel";
import { GetFormattedDate } from "../Utility/common";
import * as Type from "../Utility/typeDef";
import {
  Grid,
  Box,
  Paper,
  Typography,
  CardActions,
  CardHeader,
  Card,
  CardContent,
  Divider,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { AuthoriseUri, UnauthoriseUri } from "../Utility/PageUrls";
import "../myStyles/DashboardTemplateViewStyle.css";
import { useTranslation } from "react-i18next";
import ArticleIcon from "@mui/icons-material/Article";
import InfiniteScroll from "react-infinite-scroll-component";

const DashboardTemplateView: React.FC<Type.IDashboardTemplateView> = (
  props
) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [page,setPage] = useState(1);
  const [userAlltemplats, setUserAllTeplate] = useState<ResumeBuilderModel[]>(
    props.UserResumes as ResumeBuilderModel[]
  );

  const defalutTextValue = (testSize: number) => {
    switch (testSize) {
      case 1:
        return "#### #### ###";
      case 2:
        return "#### #### #### ###";
      case 3:
        return `#### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###`;

      default:
        return "#### #### ###";
    }
  };

  let model: ResumeBuilderModel = {
    UserId: "",
    TemplateId: "",
    PageBreakPoints: [],
    CreateDate: new Date(),
    ModifyDate: new Date(),
    Awards: {
      IsAwardThere: true,
      Header: "Award",
      AwardsDetails: [
        {
          Awarder: defalutTextValue(1),
          Date: new Date(),
          Summary: defalutTextValue(3),
          Title: defalutTextValue(2),
        },
      ],
    },
    Certification: {
      IsCertificationThere: true,
      Header: "Certification",
      CertificationDetails: [
        {
          Date: new Date(),
          Issuer: defalutTextValue(1),
          Summary: defalutTextValue(3),
          Title: defalutTextValue(2),
          IsPursuing: false,
        },
      ],
    },
    Education: {
      IsEducationThere: true,
      Header: "Education",
      AcedemyDetails: [
        {
          EndDate: new Date(),
          FieldOfStudy: defalutTextValue(1),
          Institution: defalutTextValue(1),
          IsPursuing: false,
          MarkInPercentage: "###",
          StartDate: new Date(),
          Summary: defalutTextValue(3),
          TypeOfDegree: defalutTextValue(1),
        },
      ],
    },
    Hobby: {
      IsHobbyThere: true,
      Header: "Hobbie",
      Hobbies: [{ Name: defalutTextValue(2) }],
    },
    Language: {
      IsLanguageThere: true,
      Header: "Language",
      LanguageDetails: [
        { Name: defalutTextValue(2), Fluency: defalutTextValue(1) },
      ],
    },
    Objective: {
      IsObjectiveThere: true,
      Header: "Summary",
      Summary: defalutTextValue(3),
    },
    Profile: {
      Address: defalutTextValue(2),
      DateOfBirth: new Date(),
      Email: defalutTextValue(1),
      Name: defalutTextValue(2),
      PhoneNumber: defalutTextValue(1),
      Subtitle: defalutTextValue(1),
      Website: defalutTextValue(1),
      Header: "Profile",
    },
    Project: {
      IsProjectThere: true,
      Header: "Project",
      ProjectsDetail: [
        {
          EndDate: new Date(),
          IsYourCurrentProject: false,
          StartDate: new Date(),
          Summary: defalutTextValue(3),
          Title: defalutTextValue(2),
          Website: defalutTextValue(1),
        },
      ],
    },
    Reference: {
      IsReferenceThere: true,
      Header: "Reference",
      ReferencesDetail: [
        {
          Email: defalutTextValue(1),
          Name: defalutTextValue(2),
          PoneNumber: defalutTextValue(1),
          Position: defalutTextValue(1),
          Summary: defalutTextValue(3),
        },
      ],
    },
    SocialNetworks: [],
    WorkExperience: {
      IsWorkExperienceThere: true,
      Header: "Work Experience",
      WorkHistory: [
        {
          Company: defalutTextValue(1),
          CurrentJob: false,
          Summary: defalutTextValue(3),
          Website: defalutTextValue(1),
          Position: defalutTextValue(1),
          StartDate: new Date(),
          EndDate: new Date(),
        },
      ],
    },
    Skill: {
      IsSkillThere: true,
      Header: "Skills",
      Skills: [{ Name: defalutTextValue(2), Level: defalutTextValue(1) }],
    },
  };

  const populateValue = async () => {
    let templateView = document.getElementById("templateGridItem");
    //let templateView = document.getElementsByClassName("resumeViewpreview")[0];

    let updatePofile = async () => {
      let profileHeader = templateView?.getElementsByClassName("profileHeader");
      let profileName = templateView?.getElementsByClassName("profileName");
      let profileSubtitle =
        templateView?.getElementsByClassName("profileSubtitle");
      let profileDoB = templateView?.getElementsByClassName("profileDoB");
      let profileMobile = templateView?.getElementsByClassName("profileMobile");
      let profileEmail = templateView?.getElementsByClassName("profileEmail");
      let profileWebsite =
        templateView?.getElementsByClassName("profileWebsite");
      let profileAddress =
        templateView?.getElementsByClassName("profileAddress");

      
      if (profileHeader !== undefined) {
        for (let i = 0; i < profileHeader.length; i++) {
          profileHeader[i].innerHTML = model.Profile.Header as string;
        }
      }
      if (profileName !== undefined) {
        for (let i = 0; i < profileName.length; i++) {
          profileName[i].innerHTML = model.Profile.Name as string;
        }
      }

      if (profileSubtitle !== undefined) {
        for (let i = 0; i < profileSubtitle.length; i++) {
          profileSubtitle[i].innerHTML = model.Profile.Subtitle as string;
        }
      }

      if (profileDoB !== undefined) {
        for (let i = 0; i < profileDoB.length; i++) {
          profileDoB[i].innerHTML = GetFormattedDate(model.Profile.DateOfBirth);
        }
      }

      if (profileMobile !== undefined) {
        for (let i = 0; i < profileMobile.length; i++) {
          profileMobile[i].innerHTML = model.Profile.PhoneNumber as string;
        }
      }

      if (profileEmail !== undefined) {
        for (let i = 0; i < profileEmail.length; i++) {
          profileEmail[i].innerHTML = model.Profile.Email as string;
        }
      }

      if (profileWebsite !== undefined) {
        for (let i = 0; i < profileWebsite.length; i++) {
          profileWebsite[i].innerHTML = model.Profile.Website as string;
        }
      }

      if (profileAddress !== undefined) {
        for (let i = 0; i < profileAddress.length; i++) {
          profileAddress[i].innerHTML = model.Profile.Address as string;
        }
      }
    };

    let updateObjective = async () => {
      let objectiveHeader =
        templateView?.getElementsByClassName("objectiveHeader");
      let objectiveSummary =
        templateView?.getElementsByClassName("objectiveSummary");

      if (objectiveHeader !== undefined) {
        for (let i = 0; i < objectiveHeader.length; i++) {
          objectiveHeader[i].innerHTML = model.Objective.Header as string;
        }
      }
      if (objectiveSummary !== undefined) {
        for (let i = 0; i < objectiveSummary.length; i++) {
          objectiveSummary[i].innerHTML = model.Objective.Summary as string;
        }
      }
    };

    let updateSkill = async () => {
      let skillHeader = templateView?.getElementsByClassName("skillHeader");
      let skillTemplate = templateView?.getElementsByClassName("skillTemplate");

      if (skillHeader !== undefined) {
        for (let i = 0; i < skillHeader.length; i++) {
          skillHeader[i].innerHTML = model.Skill.Header as string;
        }
      }

      if (skillTemplate !== undefined) {
        for (let i = 0; i < skillTemplate.length; i++) {
          let arrayLength = model.Skill.Skills
            ? model.Skill?.Skills?.length
            : 0;
          let injectPoint = skillTemplate[i]
            .closest(".resumeViewpreview")!
            .querySelector(".skillTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                skillTemplate[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let skillName = cloneElement.querySelector(".skillName");
              let skillLevel = cloneElement.querySelector(".skillLevel");
              if (skillName !== null) {
                skillName.innerHTML = model.Skill.Skills![x].Name as string;
              }
              if (skillLevel !== null) {
                skillLevel.innerHTML = model.Skill?.Skills![x].Level as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateWorkingExpe = async () => {
      let header = templateView?.getElementsByClassName("workExperienceHeader");
      let template = templateView?.getElementsByClassName(
        "workExperienceTemplate"
      );

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.WorkExperience.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.WorkExperience.WorkHistory
            ? model.WorkExperience?.WorkHistory?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".workExperienceTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let companyName = cloneElement.querySelector(
                ".WorkExperienceCompanyName"
              );
              let position = cloneElement.querySelector(
                ".workExperiencePosition"
              );
              let website = cloneElement.querySelector(".wokExperienceWebsite");
              let Doj = cloneElement.querySelector(".workExperienceDoj");
              let Dol = cloneElement.querySelector(".workExperienceDoL");
              let summary = cloneElement.querySelector(
                ".workExperienceSummary"
              );
              if (companyName !== null) {
                companyName.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Company as string;
              }

              if (position !== null) {
                position.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Position as string;
              }

              if (website !== null) {
                website.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Website as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.WorkExperience.WorkHistory![x].StartDate
                );
              }

              if (Dol !== null) {
                Dol.innerHTML = GetFormattedDate(
                  model.WorkExperience.WorkHistory![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateEducation = async () => {
      let header = templateView?.getElementsByClassName("educationHeader");
      let template = templateView?.getElementsByClassName("educationTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Education.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Education.AcedemyDetails
            ? model.Education?.AcedemyDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".educationTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(
                ".educationInstitutionName"
              );
              let fieldOfStudy = cloneElement.querySelector(
                ".educationFieldOfStudy"
              );
              let typeOfDegree = cloneElement.querySelector(
                ".educationTypeOfDegree"
              );
              let Gpa = cloneElement.querySelector(".educationGpa");
              let Doj = cloneElement.querySelector(".educationStartDate");
              let Dol = cloneElement.querySelector(".educationEndDate");
              let summary = cloneElement.querySelector(".educationSummary");
              if (name !== null) {
                name.innerHTML = model.Education.AcedemyDetails![x]
                  .Institution as string;
              }

              if (fieldOfStudy !== null) {
                fieldOfStudy.innerHTML = model.Education.AcedemyDetails![x]
                  .FieldOfStudy as string;
              }

              if (typeOfDegree !== null) {
                typeOfDegree.innerHTML = model.Education.AcedemyDetails![x]
                  .TypeOfDegree as string;
              }

              if (Gpa !== null) {
                Gpa.innerHTML = model.Education.AcedemyDetails![x]
                  .MarkInPercentage as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.Education.AcedemyDetails![x].StartDate
                );
              }

              if (Dol !== null) {
                Dol.innerHTML = GetFormattedDate(
                  model.Education.AcedemyDetails![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Education.AcedemyDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateCertification = async () => {
      let header = templateView?.getElementsByClassName("certificationHeader");
      let template = templateView?.getElementsByClassName(
        "certificationTemplate"
      );

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Certification.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Certification.CertificationDetails
            ? model.Certification.CertificationDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".certificationTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".certificationTitle");
              let issuer = cloneElement.querySelector(".certificationIssuer");
              let Doj = cloneElement.querySelector(
                ".certificationCompleteDate"
              );
              let summary = cloneElement.querySelector(".certificationSummary");

              if (title !== null) {
                title.innerHTML = model.Certification.CertificationDetails![x]
                  .Title as string;
              }

              if (issuer !== null) {
                issuer.innerHTML = model.Certification.CertificationDetails![x]
                  .Issuer as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.Certification.CertificationDetails![x].Date
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Certification.CertificationDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateAward = async () => {
      let header = templateView?.getElementsByClassName("awardHeader");
      let template = templateView?.getElementsByClassName("awardTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Awards.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Awards.AwardsDetails
            ? model.Awards.AwardsDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".awardTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".awardTitle");
              let issuer = cloneElement.querySelector(".awardAwarder");
              let Doj = cloneElement.querySelector(".awardDate");
              let summary = cloneElement.querySelector(".awardSummary");

              if (title !== null) {
                title.innerHTML = model.Awards.AwardsDetails![x]
                  .Title as string;
              }

              if (issuer !== null) {
                issuer.innerHTML = model.Awards.AwardsDetails![x]
                  .Awarder as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.Awards.AwardsDetails![x].Date
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Awards.AwardsDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateProject = async () => {
      let header = templateView?.getElementsByClassName("projectHeader");
      let template = templateView?.getElementsByClassName("projectTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Project.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Project.ProjectsDetail
            ? model.Project.ProjectsDetail?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".projectTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".projectTitle");
              let website = cloneElement.querySelector(".projectWebsite");
              let startDate = cloneElement.querySelector(".projectStartDate");
              let endDate = cloneElement.querySelector(".projectEndDate");
              let summary = cloneElement.querySelector(".projectSummary");

              if (title !== null) {
                title.innerHTML = model.Project.ProjectsDetail![x]
                  .Title as string;
              }

              if (website !== null) {
                website.innerHTML = model.Project.ProjectsDetail![x]
                  .Website as string;
              }

              if (startDate !== null) {
                startDate.innerHTML = GetFormattedDate(
                  model.Project.ProjectsDetail![x].StartDate
                );
              }

              if (endDate !== null) {
                endDate.innerHTML = GetFormattedDate(
                  model.Project.ProjectsDetail![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Project.ProjectsDetail![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateLanguage = async () => {
      let header = templateView?.getElementsByClassName("languageHeader");
      let template = templateView?.getElementsByClassName("languageTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Language.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Language.LanguageDetails
            ? model.Language.LanguageDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".languageTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".languageName");
              let level = cloneElement.querySelector(".languageLevel");

              if (name !== null) {
                name.innerHTML = model.Language.LanguageDetails![x]
                  .Name as string;
              }

              if (level !== null) {
                level.innerHTML = model.Language.LanguageDetails![x]
                  .Fluency as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateHobby = async () => {
      let header = templateView?.getElementsByClassName("hobbyHeader");
      let template = templateView?.getElementsByClassName("hobbyTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Hobby.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Hobby.Hobbies
            ? model.Hobby.Hobbies?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".hobbyTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".hobbyName");

              if (name !== null) {
                name.innerHTML = model.Hobby.Hobbies![x].Name as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateReference = async () => {
      let header = templateView?.getElementsByClassName("referenceHeader");
      let template = templateView?.getElementsByClassName("referenceTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Reference.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Reference.ReferencesDetail
            ? model.Reference.ReferencesDetail?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".referenceTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".referenceName");
              let email = cloneElement.querySelector(".referenceEmail");
              let mobile = cloneElement.querySelector(".referenceMobile");
              let position = cloneElement.querySelector(".referencePosition");
              let summary = cloneElement.querySelector(".referenceSummary");

              if (name !== null) {
                name.innerHTML = model.Reference.ReferencesDetail![x]
                  .Name as string;
              }

              if (email !== null) {
                email.innerHTML = model.Reference.ReferencesDetail![x]
                  .Email as string;
              }

              if (mobile !== null) {
                mobile.innerHTML = model.Reference.ReferencesDetail![x]
                  .PoneNumber as string;
              }

              if (position !== null) {
                position.innerHTML = model.Reference.ReferencesDetail![x]
                  .Position as string;
              }

              if (summary !== null) {
                summary.innerHTML = model.Reference.ReferencesDetail![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    await Promise.all([
      updatePofile(),
      updateObjective(),
      updateSkill(),
      updateWorkingExpe(),
      updateEducation(),
      updateCertification(),
      updateAward(),
      updateProject(),
      updateLanguage(),
      updateHobby(),
      updateReference(),
    ]);
  };

  const goToResumebuilder = (templateId:string)=>{

    if(userAlltemplats?.length > 0)
    {
      let temp = userAlltemplats[0];
      temp.TemplateId = templateId;
      history.push({
        pathname: AuthoriseUri.Resume,
        state: temp,
      });

    }
    else
    {
      history.push({
        pathname: AuthoriseUri.Resume,
        search: "?temp=" + templateId,
      });
    }

  }

  React.useEffect(() => {
    populateValue();
  }, [props.Template,page]);

  React.useEffect(() => {
    setUserAllTeplate(props.UserResumes as ResumeBuilderModel[]);
  }, [props.UserResumes]);

  console.log("Page " + page);

  return (
    <Box sx={{ width: "100%" }} >
      {/* <Typography variant="h6">{t("DashboardTemplateHeader")}</Typography> */}
      <br />
      <br />

      <InfiniteScroll
        dataLength={(page * 10)+1}
        next={() =>{setPage(page + 1); } }
        hasMore={true}
        loader={<h4></h4>}
      >
        <Grid container spacing={3} id="templateGridItem" sx={{ width: '100%' }} >
          {props.Template?.slice(0, 10 * page).map((item, index) => {
            return (
              <Grid item md={3} sm={6} xs={12} key={index}>
                <Card className="CardMain">
                <CardContent className="CardContentMain" sx={{ pt: 0 }} >
                    <div className="resumeViewpreview">
                      <div
                        className="templateDiv CardContentImage"
                        dangerouslySetInnerHTML={{ __html: item.Template }}
                      ></div>                    
                    </div>
                    <div className="chooseTemplateBtn">
                    <Tooltip title={t("CreateResume") as string} arrow>
                      <IconButton
                        size="small"
                        onClick={() => goToResumebuilder(item.Id)}
                        className="CardHeaderMain"
                      >
                        <div className="label-icon-container">
                          <ArticleIcon />
                          <span className="label-text">Choose Template</span>
                        </div>
                      </IconButton>
                    </Tooltip>
                    </div>
                  </CardContent>
                  <CardHeader
                    // action={
                    //   <Tooltip title={t("CreateResume") as string} arrow>
                    //     <IconButton
                    //       size="small"
                    //       onClick={() => goToResumebuilder(item.Id)}
                    //       className="CardHeaderMain"
                    //     >
                    //       <ArticleIcon />
                    //     </IconButton>
                    //   </Tooltip>
                    // }
                    title={
                      <Typography variant="subtitle2"> <b>{item.Name}</b></Typography>
                    }
                    subheader={
                      <Typography variant="subtitle2">
                    
                      </Typography>
                    }
                    sx = {{
                      borderBottomColor: "rgba(0, 0, 0, 0.4)",
                      borderBottomStyle: "inset",
                      borderBottomWidth: "2px",
                      marginBottom: "5px"
                    }}
                  ></CardHeader>
                  
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </Box>
  );
};

export default DashboardTemplateView;
