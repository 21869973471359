import * as yup from 'yup';

export const ResetPasswordSchema = yup.object({
  password: yup
    .string()
    .required("SpecifyPassword")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "StrongPasswordMsg"
    ),
  passwordConfirmation: yup
    .string()
    .test("passwords-match", "PasswordMustMatch", function (value) {
      return this.parent.password === value;
    }),
});

export type ResetPasswordModel = yup.InferType<typeof ResetPasswordSchema>;