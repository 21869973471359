import React from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  FormHelperText,
} from "@mui/material";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../myStyles/editor.css"
import '../myStyles/inputInformation.css';


const Objective: React.FC<Type.IObjective> = (props) => {
  const { t } = useTranslation();
  console.log("Objective");

  const customToolbar = {
    options: ["inline", "list"],
    inline: {
      options: ["bold", "italic", "underline"],
    },

    list: {
      options: ["unordered", "ordered"],
    },
  };

  const result = convertFromHTML(props.Summary.value as string)
  const [editorVal, setEditorVal] = React.useState(() =>
    EditorState.createWithContent(result)
  );

  const onEditorStateChange = (editorState:any) => {
    props.SetValueByPropNameHandler(
      "Objective.Summary",
      convertToHTML(editorState.getCurrentContent())
    );
    setEditorVal(editorState);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="objectice_div">
          <TextField
            id="Objective_Header"
            label={t("Header")}
            className='resume_input'
            variant="outlined"
            size="small"
            fullWidth
            name="Objective.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            onBlur={props.onBlurHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
            required
          />
        </Grid>
        <Grid item xs={12} className="objectice_div">
          <Box sx={{ height: "200px" }}>
            <Editor
              editorState={editorVal}
              editorClassName="editor-class"
              toolbar={customToolbar}
              onEditorStateChange={onEditorStateChange}
            />
          </Box>
          {props.Summary.isError && (
            <FormHelperText error={true}>
              {props.Summary.errorMessage}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(Objective);
