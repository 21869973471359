import * as yup from "yup";

export const AddTemplateSchema = yup.object({
  Name: yup
    .string()
    .trim()
    .required("SpecifyName")
    .test("len", "NameNotExceedFiftyCharecter", function (val) {
      if (val) {
        return val.toString().length <= 50;
      }
      return false;
    }),
  Template: yup.string().trim().required("SpecifyTemplate"),
  Status: yup.string().trim().required("SpecifyStatus"),
  Deleted: yup.boolean().default(false),
  CreatedBy: yup
    .object({
      Id: yup.string(),
      Name: yup.string(),
      Mobile: yup.string(),
      Email: yup.string().email(),
    })
    .optional()
    .default(undefined),
  CreateDate: yup.date().notRequired(),
  ModifyBy: yup.object({
    Id: yup.string(),
    Name: yup.string(),
    Mobile: yup.string(),
    Email: yup.string().email(),
  }),
  ModifyDate: yup.date().notRequired(),
  Sequence: yup
    .number()
    .moreThan(-1, "OnlyPositiveNumberAllow")
    .required("OnlyPositiveNumberAllow"),
  Weightage: yup
    .number()
    .moreThan(-1, "OnlyPositiveNumberAllow")
    .required("OnlyPositiveNumberAllow"),
});

export type AddTemplateModel = yup.InferType<typeof AddTemplateSchema>;
