import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import {
  ChangePasswordModel,
  ChangePasswordSchema,
} from "../model/ChangePassword";
import FormGroup from "@mui/material/FormGroup";
import Alert from "@mui/material/Alert";
import { AuthContext } from "../context/AuthContext";
import { Box } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";

type ChangePasswordProp = {
  openflag: boolean;
  closeHandler: () => void;
};

function ChangePassword({ openflag, closeHandler }: ChangePasswordProp) {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordUpdatedSuccessful, setpasswordUpdatedSuccessful] =
    useState(false);
  const [apiError, setApiError] = useState("");
  let model: ChangePasswordModel = { password: "", passwordConfirmation: "" };

  const afterChangePasswordHandler = (
    isError: boolean,
    message: string
  ): void => {
      debugger;
    if (isError) {
      setApiError(message);
    } else {
      setpasswordUpdatedSuccessful(true);
    }
  };

  const formik = useFormik({
    initialValues: model,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values) => {
        debugger;
      setApiError("");
      authContext.updatePassword(values.password, afterChangePasswordHandler);
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div>
      <Dialog open={openflag} fullWidth={true} maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            {apiError !== "" && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {apiError}
              </Alert>
            )}
            {passwordUpdatedSuccessful == true && (
              <Alert severity="success" sx={{ mb: 2 }}>
                {t("PasswordResetSuccessful")}
              </Alert>
            )}
            <Box>
              <FormGroup row={true} sx={{ mb: 2 }}>
                <TextField
                  label={t("Password")}
                  id="password"
                  type="password"
                  size="small"
                  fullWidth={true}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password &&
                    t(formik.errors.password as string)
                  }
                />
              </FormGroup>

              <FormGroup row={true} sx={{ mb: 2 }}>
                <TextField
                  label={t("ConfirmPassword")}
                  id="password"
                  size="small"
                  fullWidth={true}
                  name="passwordConfirmation"
                  type={showPassword ? "text" : "password"}
                  value={formik.values.passwordConfirmation}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.passwordConfirmation &&
                    Boolean(formik.errors.passwordConfirmation)
                  }
                  helperText={
                    formik.touched.passwordConfirmation &&
                    t(formik.errors.passwordConfirmation as string)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormGroup>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeHandler} style={{color: '#51A2BD'}}>{t("Close")}</Button>
            <Button type="submit" style={{color: '#fff'}}>{t("Update")}</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default ChangePassword;
