import { Button, CardContent, Grid, TextField, Box } from "@mui/material";
import React, { useContext,useRef,useState } from "react";
import Layout from '../components/Layout'
import { AuthoriseUri } from '../Utility/PageUrls'
import {FeedbackSchema, FeedbackModel} from "../model/FeedbackModel"
import { AuthContext } from "../context/AuthContext";
import { useFormik } from "formik";
import { addFeedback, filePath } from "../service/firebase";
import { useTranslation } from "react-i18next";
import { Card, Typography, Rating, Input } from "@mui/material";
import {Accordion,AccordionSummary,AccordionDetails} from "../components/CustomAccordion"
import UserFeedback from "../components/UserFeedback";
import '../myStyles/UserFeedback.css';

export const Feedback:React.FC = () => {
  const authContext = useContext(AuthContext);
  const [file,setFile] = useState<FileList | null>();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(
    authContext?.getUserDatail?.Role == "admin"
      ? "feedbackPanel2"
      : "feedbackPanel1"
  );

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    if (!event.target.className.includes("MuiSwitch-input")) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const generateNewFile = (fileName:string) =>{
    const myArray = fileName.split(".");
    let x = Math.floor(Math.random() * 100000000 + 1);
    let newFileName = x +"."+ myArray[myArray.length - 1];
    return newFileName;
  }

  let model: FeedbackModel = {
    Id:"",
    Feedback: "",
    User: {
      Id: authContext?.getUserDatail?.Id,
      Name: authContext?.getUserDatail?.Name,
      Mobile: authContext?.getUserDatail?.Mobile,
      Email: authContext?.getUserDatail?.Email,
    },
    FeedbackRating:0,
    Read: false,
    ReadDate:new Date(),
    FeedbackDate:new Date(),
    ImagePath:"",
  };

    const formik = useFormik({
      initialValues: model,
      validationSchema: FeedbackSchema,
      onSubmit: (values) => {
        const feedbackCallbackFunc = (isError:boolean,message:string)=>{
          window.showDialog(undefined,t(message));
          if(!isError)
          {
            values.Feedback="";
            setFile(null);
            (document.getElementById("btn-upload") as HTMLInputElement).value =
              "";
          }
        };

        window.showProgressbar();
        let temp  = values;
        delete temp.Id;
        if(file)
        {
        temp.ImagePath = generateNewFile( file[0].name)
        }
        addFeedback(temp,file ,feedbackCallbackFunc);
        window.hideProgressbar();
      },
    });

    const feedbackForm = (
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography component="legend">{t("Rating")}</Typography>
          </Grid>
          <Grid item sm={12} md={8}>
            <Rating
              name="FeedbackRating"
              value={formik.values.FeedbackRating}
              onChange={(event, newValue) => {
                formik.setFieldValue("FeedbackRating", newValue ?? 0);
              }}
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              name="Feedback"
              label={t("FeedbackLabel")}
              fullWidth
              value={formik.values.Feedback}
              onChange={formik.handleChange}
              multiline
              rows={5}
              error={formik.touched.Feedback && Boolean(formik.errors.Feedback)}
              helperText={
                formik.touched.Feedback && t(formik.errors.Feedback as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="btn-upload">
              <input
                accept="image/*"
                id="btn-upload"
                name="btn-upload"
                style={{ display: "none" }}
                type="file"
                onChange={(e) => {setFile(e.target.files);} }
              />
              <Button
                className="btn-choose"
                variant="contained"
                component="span"
              >
                Choose Files
              </Button>
            </label>

            <span  style={{marginLeft:"10px"}} className="file-name">
              {file && file.length > 0 ? file[0].name : null}
            </span>
          </Grid>
          <Grid item sm={12}>
            <Button type="submit" variant="contained" fullWidth>
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );

    const userRoleFeedbackForm = (
      <Card>
        <CardContent>{feedbackForm}</CardContent>
      </Card>
    );

    const adminRoleFeedbackForm = (
      <Accordion
      className="feedback_accordion"
        expanded={expanded === "feedbackPanel1"}
        onChange={handleChange("feedbackPanel1")}
      >
        <AccordionSummary className="feedback_summary" aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{t("FeedbackCaption")}</Typography>
        </AccordionSummary>
        <AccordionDetails>{feedbackForm}</AccordionDetails>
      </Accordion>
    );

    console.log(formik.values.FeedbackRating)

    filePath("Untitled.png");
    return (
      <Layout menuRoute={AuthoriseUri.Feedback}>
        <Grid
          container
          spacing={2}
          alignContent="flex-start"
          justifyContent="center"
        >
          {authContext?.getUserDatail?.Role == "admin" && (
            <Grid className="feedback_main" item xs={12} md={8}>
              <Accordion
              className="feedback_accordion"
                expanded={expanded === "feedbackPanel2"}
                onChange={handleChange("feedbackPanel2")}
              >
                <AccordionSummary
                className="feedback_summary"
                  aria-controls="panel1d-content"
                  id="panel2d-header"
                >
                  <Typography>{t("UserFeedbackCaption")}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <UserFeedback />
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}
          <Grid item xs={12} md={8}>
            {authContext?.getUserDatail?.Role == "admin"
              ? adminRoleFeedbackForm
              : userRoleFeedbackForm}
          </Grid>
        </Grid>
      </Layout>
    );
}
