
import { width } from "@mui/system";
import React, {useEffect} from "react";
import {
  signInWithGoogle,
  CreateUserWithEmailAndPassword,
  SignInWithEmailAndPassword,
  SendEmailVerification,
  SignOut,
  CheckUserStatus,
  SendPasswordResetEmail,
  UpdatePassword,
  ConfirmPasswordReset,
  getUser,
  ApplyActionCode,
} from "../service/firebase";

interface IAuthContext {
  isAuthenticated: boolean;
  isGetCurrentUserCompleted: boolean;
  signupHandler: (
    email: string,
    password: string,
    callback: (isError: boolean, message: string, response: {}) => void
  ) => void;
  SendEmailVerification: () => void;
  googleLoginHandler: (
    callback: (isError: boolean, message: string, response: {}) => void
  ) => void;
  loginHandler: (
    email: string,
    password: string,
    callback: (isError: boolean, message: string, response: {}) => void
  ) => void;
  signOut: (callback: (isError: boolean, message: string) => void) => void;
  sendResetPasswordEmail: (
    email: string,
    callBack: (isError: boolean, message: string) => void
  ) => void;
  updatePassword: (
    password: string,
    callBack: (isError: boolean, message: string) => void
  ) => void;
  confirmPasswordReset: (
    oobCode: string,
    password: string,
    callBack: (isError: boolean, message: string) => void
  ) => void;
  addSessionUser: (authUser: {}) => void;
  getSessionUser: () => {} | null;
  removeSessionUser: () => void;
  setUserDatailMissing: (value: boolean) => void;
  userDetailStatus: {isDetailMissig:boolean,isGettingProcessCompleted:boolean};
  getUserDatail: any;
  applyActionCode: (
    oobCode: string,
    callBack: (isError: boolean, message: string) => void
  ) => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  isAuthenticated: false,
  isGetCurrentUserCompleted: false,
  signupHandler: (
    email: string,
    password: string,
    callback: (isError: boolean, message: string, response: {}) => void
  ) => {},
  SendEmailVerification: () => {},
  googleLoginHandler: (
    callback: (isError: boolean, message: string, response: {}) => void
  ) => {},
  loginHandler: (
    email: string,
    password: string,
    callback: (isError: boolean, message: string, response: {}) => void
  ) => {},
  signOut: (callback: (isError: boolean, message: string) => void) => {},
  sendResetPasswordEmail: (
    email: string,
    callBack: (isError: boolean, message: string) => void
  ) => {},
  updatePassword: (
    password: string,
    callBack: (isError: boolean, message: string) => void
  ) => {},
  confirmPasswordReset: (
    oobCode: string,
    password: string,
    callBack: (isError: boolean, message: string) => void
  ) => {},
  addSessionUser: (authUser: {}) => {},
  getSessionUser: () => {
    return null;
  },
  removeSessionUser: () => {},
  setUserDatailMissing: (value: boolean) => {},
  userDetailStatus: {
    isDetailMissig: true,
    isGettingProcessCompleted: false,
  },
  getUserDatail: {},
  applyActionCode: (
    oobCode: string,
    callBack: (isError: boolean, message: string) => void
  ) => {},
});

const AuthContextProvider: React.FC=(props)=>{

    const [isAuthentication,setIsAuthenticate ]= React.useState(false);
    const [isGetCurrentUserCompleted, setIsGetCurrentUserCompleted] = React.useState(false);
    const [userDetailStatus, setUserDetailStatus] = React.useState({
      isDetailMissig: true,
      isGettingProcessCompleted: false,
    });
    const [userdetails, setUserDetails] = React.useState<any>();
    const sessionKey="auth";

    const addSessionUser = (authUser: {}) => {
      if (authUser.hasOwnProperty('user'))
      {
       let stringfyUser = JSON.stringify(authUser);
       window.sessionStorage.setItem(sessionKey, stringfyUser);
       setIsAuthenticate(true);
      }
    };

    const getSessionUser =()=>{
      let stringifyUser=  window.sessionStorage.getItem(sessionKey);
      if(stringifyUser != null)
      {
        return JSON.parse(stringifyUser);
      }

      return null;
    };

    const removeSessionUser =()=>{
      window.sessionStorage.removeItem(sessionKey);
      setIsAuthenticate(false);
    }
    
    const signupHandler= (
    email: string,
    password: string,
    callback: (isError: boolean, message: string, response: {}) => void
    ) => {
      window.showProgressbar();
      CreateUserWithEmailAndPassword(email, password, callback);
      window.hideProgressbar();
    }

    const googleLoginHandler = (
      callback: (isError: boolean, message: string, response: {}) => void
    ) => {
      window.showProgressbar();
      signInWithGoogle(callback);
       window.hideProgressbar();
    };

    const GetCurrentUser= async(currentUser:any)=>{
      window.showProgressbar();
      if (currentUser?.hasOwnProperty("auth")) {
        let user = currentUser.auth.currentUser;
        let token = currentUser.auth.currentUser.stsTokenManager;
        await userGet(user.uid);
        addSessionUser({ user, token });
      }
      setIsGetCurrentUserCompleted(true);
      window.hideProgressbar();
    }

    const loginHandler= (
    email: string,
    password: string,
    callback: (isError: boolean, message: string, response: {}) => void)=>{
      window.showProgressbar();
      SignInWithEmailAndPassword(email, password, callback);
      window.hideProgressbar();
    }

    const sendResetPasswordEmail = (
      email: string,
      callback: (isError: boolean, message: string) => void
    ) => {
      window.showProgressbar();
      SendPasswordResetEmail(email, callback);
      window.hideProgressbar();
    };

    const confirmPasswordReset=(
    oobCode:string,
    password: string,
    callBack: (isError: boolean, message: string)=>void)=>{
      window.showProgressbar();
      ConfirmPasswordReset(oobCode, password, callBack);
      window.hideProgressbar();
    }

    const applyActionCode = (
      oobCode: string,
      callBack: (isError: boolean, message: string) => void
    ) => {
      window.showProgressbar();
       ApplyActionCode(oobCode, callBack);
      window.hideProgressbar();
    };

    const updatePassword = (
      password: string,
      callback: (isError: boolean, message: string) => void
    ) => {
      window.showProgressbar();
      UpdatePassword(password, callback);
      window.hideProgressbar();
    };

    const Logout = (
      funCallback: (isError: boolean, message: string) => void
    ) => {
      window.showProgressbar();
      SignOut(funCallback);
      removeSessionUser();
      window.hideProgressbar();
    };

    const userGet=async (userId:string)=>{
      window.showProgressbar();
      let result = await getUser(userId);
      if(result)
      {
        result.Id = userId;
        setUserDetails(result);
        console.log(result);
      }
      setUserDetailStatus({isDetailMissig: !Boolean(result), isGettingProcessCompleted:true});
      window.hideProgressbar();
    }
    
    const SetUserDatailsMissing=(value:boolean)=>{
      setUserDetailStatus({isDetailMissig: value,isGettingProcessCompleted:true});
    }

    useEffect(() => {
       CheckUserStatus(GetCurrentUser);
    }, [])

    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: isAuthentication,
          isGetCurrentUserCompleted: isGetCurrentUserCompleted,
          signupHandler: signupHandler,
          SendEmailVerification: SendEmailVerification,
          googleLoginHandler: googleLoginHandler,
          loginHandler: loginHandler,
          signOut: Logout,
          sendResetPasswordEmail: sendResetPasswordEmail,
          updatePassword: updatePassword,
          confirmPasswordReset: confirmPasswordReset,
          addSessionUser: addSessionUser,
          getSessionUser: getSessionUser,
          removeSessionUser: removeSessionUser,
          setUserDatailMissing: SetUserDatailsMissing,
          userDetailStatus: userDetailStatus,
          getUserDatail: userdetails,
          applyActionCode: applyActionCode,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    );
}

export default AuthContextProvider;