import React from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Switch,
  Alert,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import CloseIcon from "@mui/icons-material/Close";
import SummaryEditor from "./SummaryEditor";
import '../myStyles/inputInformation.css';


const Project: React.FC<Type.IProject> = (props) =>{
    const { t } = useTranslation();
    console.log("Project");

  const getErrorString = (index: number, propsName: string): string => {
    if (
      Array.isArray(props.ProjectHistoryError) &&
      props.ProjectHistoryError[index] !== null &&
      props.ProjectHistoryError[index] !== undefined &&
      props.ProjectHistoryError[index].hasOwnProperty(propsName)
    ) {
      return t(props.ProjectHistoryError[index][propsName]);
    }
    return "";
  };

  const getTouchedValue = (index: number, propsName: string): boolean => {
    if (
      Array.isArray(props.ProjectHistoryTouched) &&
      props.ProjectHistoryTouched[index] !== null &&
      props.ProjectHistoryTouched[index] !== undefined &&
      props.ProjectHistoryTouched[index].hasOwnProperty(propsName)
    ) {
      return props.ProjectHistoryTouched[index][propsName];
    }
    return false;
  };

  const noProjectHistoryError =
    Array.isArray(props.ProjectHistoryTouched) &&
    props.ProjectHistoryTouched.length == 0 &&
    typeof props.ProjectHistoryError == "string" &&
    props.ProjectHistoryError != undefined ? (
      <Alert severity="error" sx={{ mt: 1 }}>
        {t(props.ProjectHistoryError)}
      </Alert>
    ) : (
      <span></span>
    );
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} className="project_div">
        <TextField
          id="Project_Header"
          label={t("Header")}
          variant="outlined"
          className='resume_input'
          size="small"
          fullWidth
          name="Project.Header"
          value={props.Header.value}
          onChange={props.onChangeHandler}
          onBlur={props.onBlurHandler}
          error={props.Header.isError}
          helperText={props.Header.errorMessage}
          required
        />
        {noProjectHistoryError}
      </Grid>
      <Grid item xs={12}>
        {props.ProjectHistory?.map((w, i) => {
          let titleError =
            getTouchedValue(i, "Title") && getErrorString(i, "Title");
          let endDateError =
            getTouchedValue(i, "EndDate") && getErrorString(i, "EndDate");
          let startDateError =
            getTouchedValue(i, "StartDate") && getErrorString(i, "StartDate");
          return (
            <React.Fragment key={i}>
              <Card variant="outlined" sx={{ mb: 2 }}>
                <CardHeader
                  action={
                    <IconButton
                      aria-label="close"
                      onClick={(e) => props.RemoveProjectHistory(i)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  }
                  sx={{ pb: 0, pt: 1 }}
                />
                <CardContent sx={{ pt: "1px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label={t("Title")}
                        variant="outlined"
                        size="small"
                        className='resume_input'
                        fullWidth
                        name={`Project.ProjectsDetail[${i}].Title`}
                        value={w.Title}
                        onChange={props.onChangeHandler}
                        onBlur={props.onBlurHandler}
                        helperText={titleError}
                        error={Boolean(titleError)}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label={t("Website")}
                        variant="outlined"
                        size="small"
                        className='resume_input'
                        fullWidth
                        name={`Project.ProjectsDetail[${i}].Website`}
                        value={w.Website}
                        onChange={props.onChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Switch
                            name={`Project.ProjectsDetail[${i}].IsYourCurrentProject`}
                            checked={w?.IsYourCurrentProject}
                            onChange={props.onChangeHandler}
                          />
                        }
                        label={t("CurrentWorkingProject")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className="project_date">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label={t("StartDate")}
                          format="dd/MM/yyyy"
                          className='resume_input DOB'
                          value={w.StartDate}
                          onChange={(newValue: any) => {
                            props.SetValueByPropNameHandler(
                              `Project.ProjectsDetail[${i}].StartDate`,
                              newValue
                            );
                          }}
                          // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                          //   <TextField
                          //     {...params}
                          //     size="small"
                          //     fullWidth
                          //     error={Boolean(startDateError)}
                          //     helperText={startDateError}
                          //     required
                          //   />
                          // )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} className="project_date">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label={t("EndDate")}
                          format="dd/MM/yyyy"
                          disabled={w.IsYourCurrentProject}
                          className='resume_input DOB'
                          value={w.EndDate}
                          onChange={(newValue: any) => {
                            props.SetValueByPropNameHandler(
                              `Project.ProjectsDetail[${i}].EndDate`,
                              newValue
                            );
                          }}
                          // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                          //   <TextField
                          //     {...params}
                          //     size="small"
                          //     fullWidth
                          //     error={Boolean(endDateError)}
                          //     helperText={endDateError}
                          //   />
                          // )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <SummaryEditor
                        Summary={w.Summary}
                        ParentArrayLenght={props.ProjectHistory?.length}
                        CallbackFun={(html: any) => {
                          props.SetValueByPropNameHandler(
                            `Project.ProjectsDetail[${i}].Summary`,
                            html
                          );
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </React.Fragment>
          );
        })}
      </Grid>
      <Grid item xs={12} className="project_btn">
        <Button
          variant="contained"
          fullWidth
          onClick={props.AddProjectHistrory}
          className="btn_work"
        >
          {t("AddMoreProject")}
        </Button>
      </Grid>
    </Grid>
  );
}


export default React.memo(Project);
