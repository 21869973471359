import React, { useState } from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  Box,
  Chip,
  Divider,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { SkillModel,SkillSchema } from "../model/ResumeBuilderModel";
import { useFormik } from "formik";
import '../myStyles/inputInformation.css';

const Skill: React.FC<Type.ISkill> = (props) => {
  const { t } = useTranslation();
  console.log("Skill");
   let model:SkillModel={Name:"",Level:""}
   const skillformik = useFormik({
     initialValues: model,
     validationSchema: SkillSchema,
     onSubmit: (values) => {
        props.AddSkillHandler &&
          props.AddSkillHandler(values.Name, values.Level, resetData);
     },
   });

  const skillNameRef = React.useRef<null | HTMLInputElement>(null);

  const resetData =()=>{
     (async()=>{
      await skillformik.setFieldValue("Name", "");
      await skillformik.setFieldValue("Level", "");
      skillNameRef.current!.focus();
     })();
  }

  const addSkill=()=>{
    skillformik.handleSubmit();
  }

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="skills_div">
          <TextField
            id="Skill_Header"
            label={t("Header")}
            className='resume_input'
            variant="outlined"
            size="small"
            fullWidth
            name="Skill.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            onBlur={props.onBlurHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
            required
          />
        </Grid>
        <Divider sx={{ mb: 2 }} />
        <Grid item xs={12} md={5} className="skills_div">
          <TextField
            id="Add_Skill_txt"
            className='resume_input'
            label={t("Skill")}
            autoFocus={true}
            inputRef={skillNameRef}
            variant="outlined"
            size="small"
            fullWidth
            name="Name"
            value={skillformik.values.Name}
            onChange={skillformik.handleChange}
          />
        </Grid>
        <Grid item xs={12} md={5} className="skills_div">
          <FormControl fullWidth size="small" className="skills_div_width">
            <InputLabel id="lbl_level" >{t("Level")}</InputLabel>
            <Select labelId="lbl_level" label={t("Level")} onChange={skillformik.handleChange} value={skillformik.values.Level}  name="Level">
              <MenuItem value={t("Novice")} selected={true}>{t("Novice")}</MenuItem>
              <MenuItem value={t("AdvancedBeginner")}>
                {t("AdvancedBeginner")}
              </MenuItem>
              <MenuItem value={t("Competent")}>{t("Competent")}</MenuItem>
              <MenuItem value={t("Proficient")}>{t("Proficient")}</MenuItem>
              <MenuItem value={t("Expert")}>{t("Expert")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2} className="skills_div">
          <Button
            className="add_btn_resume"
            variant="contained"
            sx={{ mt: "2px" }}
            fullWidth
            onClick={addSkill}
            disabled={!Boolean(skillformik.values.Name.trim())}
          >
            {t("Add")}
          </Button>
        </Grid>

        <Grid item xs={12} className="skills_div">
          <Box
            sx={{
              borderRadius: 1,
              border: 1,
              borderColor: "grey.500",
              height: 100,
              p: 1,
              overflow: "auto",
              pb: 1,
            }}
            component="div"
          >
            {props.SkillsDetails &&
              props.SkillsDetails.map((s, index) => {
                let chiplabel = s.Level ? s.Name + " | " + s.Level : s.Name;
                return (
                  <Chip
                    key={index}
                    label={chiplabel}
                    size="small"
                    variant="outlined"
                    sx={{ mr: 1, mt: 1 }}
                    onDelete={(e) =>
                      props.RemoveSkillHandler &&
                      props.RemoveSkillHandler(index)
                    }
                  />
                );
              })}
          </Box>
          {Boolean(props.SkillsDetailError) && (
            <FormHelperText error={true}>
              {t(props.SkillsDetailError as string)}
            </FormHelperText>
          )}
          <br></br>
        </Grid>
      </Grid>

      <Grid container spacing={2}></Grid>
    </React.Fragment>
  );
};

export default React.memo(Skill);
