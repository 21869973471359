import React,{useContext} from 'react';
import CssBaseline from "@mui/material/CssBaseline";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Switch, Route} from "react-router-dom";
import { AuthoriseUri,UnauthoriseUri } from "./Utility/PageUrls";
import * as Types from "./Utility/typeDef";
import AlertDialog from "./components/AlertDialog";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import { AuthContext } from "./context/AuthContext";
import Home from "./pages/Home";
import CreateBuilder from "./pages/ResumeBuilder";
import About from "./pages/About";
import Login from "./pages/Login";
import Signup from './pages/Signup';
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Template from './pages/Template';
import Users from './pages/Users';
import EmailVerifying from './pages/EmailVerifying';
import { useHistory, useLocation } from "react-router-dom";
import { Feedback } from './pages/Feedback';
import LandingPage from "./pages/LandingPage";


const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#dde2e7",
          scrollbarColor: "#555 #F5F5F5",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            width: "10px",
            backgroundColor: "#F5F5F5",
          },
          "&::-webkit-scrollbar-track":
            {
              webkitBozShadow:"inset 0 0 6px rgba(0,0,0,0.3)",
	            borderRadius: "10px",
	            backgroundColor: "#F5F5F5",
            },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            backgroundColor: " #555",
            minHeight: 24,
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#555",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#555",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#555",
          },
        },
      },
    },
  },
});

// const theme = createTheme({
//   components: {
//     MuiCssBaseline: {
//       styleOverrides: {
//         body: {
//           backgroundColor: "#dde2e7",
//         },
//       },
//     },
//   },
// });

class ProgressBar {
  private static instance: ProgressBar;
  private static progressRequest:number=0
  private constructor() {}
  public static getInstance(): ProgressBar {
    if (!ProgressBar.instance) {
      ProgressBar.instance = new ProgressBar();
    }

    return ProgressBar.instance;
  }
  
  public Increament(){
    ProgressBar.progressRequest ++;
  }

  public Decreament(){
    if (ProgressBar.progressRequest >= 1)
    {
      ProgressBar.progressRequest--;
    } 
  }
  public GetProgressRequestCount(){
    return ProgressBar.progressRequest;
  }
}



function App(props:any) {
  const authContext = useContext(AuthContext);
  const [alertDialogProps, setAlertDialogProps] =
    React.useState<Types.IDialogProps>({
      description: "",
      showAlert: false,
      onCloseHandler: () => {},
    });
  const progressbarRequest = ProgressBar.getInstance();
  const [showProgressbar, setShowProgressbar] = React.useState(false);
  const history = useHistory();
  const search = useLocation().search;
  const actionCode = new URLSearchParams(search).get("mode");
  const oobCode = new URLSearchParams(search).get("oobCode");


  if (actionCode && oobCode) {
    if (actionCode === "resetPassword") {
      history.push({
        pathname: UnauthoriseUri.ResetPassword,
        search: "?oobCode=" + oobCode,
      });
    }

    if (actionCode == "verifyEmail") {
      history.push({
        pathname: UnauthoriseUri.VerifyingEmail,
        search: "?oobCode=" + oobCode,
      });
    }
  }

  window.showProgressbar=()=>{
    progressbarRequest.Increament();
    setShowProgressbar(progressbarRequest.GetProgressRequestCount() > 0);
  }

  window.hideProgressbar =()=>{
    progressbarRequest.Decreament();
    if (progressbarRequest.GetProgressRequestCount() == 0)
    {
      setShowProgressbar(false);
    }
      
  }
  
  window.showDialog = (title, message) => {
    setAlertDialogProps({
      title: title,
      description: message,
      showAlert: true,
      onCloseHandler: closeAlertDialog,
    });
  };

  window.showConfirmationDialog = (
    title,
    message,
    yesClickHandler,
    noClickHandler
  ) => {
    const NoClickHandler = () => {
      if (noClickHandler !== undefined) {
        noClickHandler();
      }
    };

    const YesClickHandler = () => {
      yesClickHandler();
    };

    const buttons: Array<Types.DialogButtonProps> = [
      { title: "No", fn: NoClickHandler },
      { title: "Yes", fn: YesClickHandler },
    ];

    setAlertDialogProps({
      title: title,
      description: message,
      showAlert: true,
      onCloseHandler: closeAlertDialog,
      buttons: buttons,
    });
  };

  window.showGenericAlertDialog = (title, message, buttons) => {
    setAlertDialogProps({
      title: title,
      description: message,
      showAlert: true,
      onCloseHandler: closeAlertDialog,
      buttons: buttons,
    });
  };

  const closeAlertDialog = () => {
    setAlertDialogProps({
      description: "",
      showAlert: false,
      onCloseHandler: () => {},
    });
  };


  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {authContext.isGetCurrentUserCompleted && (
          <Switch>
            <AuthenticatedRoute path={AuthoriseUri.Resume}>
              <CreateBuilder />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={AuthoriseUri.About}>
              <About />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={AuthoriseUri.Users}>
              <Users />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={AuthoriseUri.Template}>
              <Template />
            </AuthenticatedRoute>
            <AuthenticatedRoute path={AuthoriseUri.Feedback}>
              <Feedback />
            </AuthenticatedRoute>
            <Route exact path='/'> 
              <LandingPage /> 
            </Route>
            <Route path={UnauthoriseUri.Login}>
              <Login />
            </Route>
            <Route path={UnauthoriseUri.Signup}>
              <Signup />
            </Route>
            <Route path={UnauthoriseUri.ForgotPassword}>
              <ForgotPassword />
            </Route>
            <Route path={UnauthoriseUri.ResetPassword}>
              <ResetPassword />
            </Route>
            <Route path={UnauthoriseUri.VerifyingEmail}>
              <EmailVerifying />
            </Route>
            <AuthenticatedRoute path={AuthoriseUri.Dashboard}>
              <Home />
            </AuthenticatedRoute>
          </Switch>
        )}
        <AlertDialog
          description={alertDialogProps.description}
          onCloseHandler={alertDialogProps.onCloseHandler}
          showAlert={alertDialogProps.showAlert}
          buttons={alertDialogProps.buttons}
          title={alertDialogProps.title}
        ></AlertDialog>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showProgressbar}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
