import * as yup from 'yup';

export const ChangePasswordSchema = yup.object({
  password: yup.string().required("SpecifyPassword"),
  passwordConfirmation: yup
    .string()
    .test("passwords-match", "PasswordMustMatch", function (value) {
      return this.parent.password === value;
    }),
});

export type ChangePasswordModel = yup.InferType<typeof ChangePasswordSchema>;