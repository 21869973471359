
import PropTypes from "prop-types";
import * as yup from 'yup';

export type DialogButtonProps ={
  title : string;
  fn : ()=> void;
}

export interface IDialogProps {
  showAlert: boolean;
  onCloseHandler: () => void;
  title?: string;
  description: string;
  buttons?: Array<DialogButtonProps>;
}

export interface IFormData<T>{
  value?:T | undefined;
  isError?:boolean |undefined;
  errorMessage?: any;
}

export interface IFormikEvent {
  onBlurHandler: any;
  onChangeHandler: any;
  SetValueByPropNameHandler: (propName: string, newValue: any) => void;
}


export interface IPersonalInfo extends IFormikEvent {
  Header:IFormData<string>;
  Name:IFormData<string>;
  Subtitle:IFormData<string>;
  Email:IFormData<string>;
  Mobile:IFormData<string>;
  DOB:IFormData<Date>;
  Website:IFormData<string>;
  Address:IFormData<string>;
}

export interface IObjective extends IFormikEvent {
   Header:IFormData<string>;
   Summary:IFormData<string>;
}

export interface ISummary extends IFormikEvent {
  Header: IFormData<string>;
  Summary: IFormData<string>;
}

export interface ISkill extends IFormikEvent {
   Header:IFormData<string>;
   AddSkillHandler:(Name:string,Level:string|undefined,callbackFun:()=>void)=>void;
   RemoveSkillHandler:(index:number)=>void;
   SkillsDetails:any[] | undefined;
   SkillsDetailError:any;
}

export interface IWorkExperience extends IFormikEvent {
  Header:IFormData<string>;
  WorkHistory:any[] | undefined;
  WorkHistoryError:string | undefined;
  WorkHistoryTouched:any;
  AddWorkHistrory:()=>void;
  RemoveWorkHistory:(index:number)=>void;
}

export interface IEducation extends IFormikEvent {
  Header: IFormData<string>;
  EducationHistory: any[] | undefined;
  EducationHistoryError: string | undefined;
  EducationHistoryTouched: any;
  AddEducationHistrory: () => void;
  RemoveEducationHistory: (index: number) => void;
}

export interface ICertification extends IFormikEvent {
  Header: IFormData<string>;
  CertificationHistory: any[] | undefined;
  CertificationHistoryError: string | undefined;
  CertificationHistoryTouched: any;
  AddCertificationHistrory: () => void;
  RemoveCertificationHistory: (index: number) => void;
}

export interface IAward extends IFormikEvent {
  Header: IFormData<string>;
  AwardHistory: any[] | undefined;
  AwardHistoryError: string | undefined;
  AwardHistoryTouched: any;
  AddAwardHistrory: () => void;
  RemoveAwardHistory: (index: number) => void;
}

export interface IProject extends IFormikEvent {
  Header: IFormData<string>;
  ProjectHistory: any[] | undefined;
  ProjectHistoryError: string | undefined;
  ProjectHistoryTouched: any;
  AddProjectHistrory: () => void;
  RemoveProjectHistory: (index: number) => void;
}

export interface IReference extends IFormikEvent {
  Header: IFormData<string>;
  ReferenceHistory: any[] | undefined;
  ReferenceHistoryError: string | undefined;
  ReferenceHistoryTouched: any;
  AddReferenceHistrory: () => void;
  RemoveReferenceHistory: (index: number) => void;
}

export interface IHobby extends IFormikEvent {
   Header:IFormData<string>;
   AddHobbyHandler:(Name:string|undefined,callbackFun:()=>void)=>void;
   RemoveHobbyHandler:(index:number)=>void;
   HobbiesDetail:any[] | undefined;
}
export interface ILanguage extends IFormikEvent {
  Header: IFormData<string>;
  AddLanguageHandler: (
    Name: string | undefined,
    Fluency: string | undefined,
    callbackFun: () => void
  ) => void;
  RemoveLanguageHandler: (index: number) => void;
  LanguagesDetail: any[] | undefined;
  LanguagesDetailError: any;
}

export interface IResumeTemplateView {
  //Templates:{__html:string}[];
  Templates: IResumeTemplate[] | undefined;
  TemplateSelecteHandler: (id: string) => void;
}

export interface IResumeTemplate
{
  Id:string,
  Name?:string;
  Template:string;
  Status:string;
  Deleted:boolean;
  DateTime:Date;
}

export  interface ISummaryEditor{
  Summary:string,
  ErrorMessage?:string;
  ParentArrayLenght?:number;
  CallbackFun:(value:any)=>void;
}

export interface IDashboardTemplateView {
  Template: any[] | undefined;
  UserResumes: any[] | undefined;
}

export interface IUserAllResume {
  Template: any[] | undefined;
  UserResumes: any[] | undefined;
}

export interface IUserForm{
  UserId:string;
  Name:string;
  Email:string;
}

export interface IAddTemplateForm {
  TemplateId?: string;
  TemplateDetail?: {
    Name: string;
    Template: string;
    Status: string;
    Sequence: number;
    Weightage: number;
  };
  CloseAndResetParent: () => void;
}

//=====Enm=======

export enum OrderBy {
  Asc="asc",
  Desc = "desc",
}

export enum TemplateStatus{
  Publish="publish",
  Unpublish="unpublish"
}

export enum FeedbackSearchCriteria{
  All,
  Read,
  Unread
}