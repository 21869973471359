import React, { useState } from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  Box,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { LanguageModel } from "../model/ResumeBuilderModel";
import '../myStyles/inputInformation.css';

const Language: React.FC<Type.ILanguage> = (props) => {
  const { t } = useTranslation();
  console.log("Language");
  const [languageModel, setLanguageModel] = useState<LanguageModel>({
    Name: "",
    Fluency: "",
  });
  const [languageError, setLanguageError] = useState("");
  const languageNameRef = React.useRef<null | HTMLInputElement>(null);
  const resetData = () => {
    setLanguageModel({ Name: "", Fluency: "" });
  };

  const addLanguage = () => {
    if (languageModel.Name == "") {
      setLanguageError(t("SpecifyLanguageName"));
      languageNameRef.current?.focus();
    } else {
      props.AddLanguageHandler(
        languageModel.Name,
        languageModel.Fluency,
        resetData
      );
    }
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="language_div">
          <TextField
            id="Language_Header"
            label={t("Header")}
            variant="outlined"
            className='resume_input'
            size="small"
            fullWidth
            name="Language.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
          />
        </Grid>
        <Divider sx={{ mb: 2 }} />
        <Grid item xs={12} md={5}>
          <TextField
            id="Add_Language_txt"
            label="Language"
            variant="outlined"
            className='resume_input'
            size="small"
            fullWidth
            inputRef={languageNameRef}
            value={languageModel.Name}
            error={Boolean(languageError)}
            helperText={languageError}
            onChange={(e) => {
              setLanguageError("");
              setLanguageModel({
                ...languageModel,
                Name: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth size="small" className="skills_div_width">
            <InputLabel id="lbl_language_level">{t("Level")}</InputLabel>
            <Select
              labelId="lbl_language_level"
              label={t("Level")}
              value={languageModel.Fluency}
              name="Level"
              onChange={(e) => {
                setLanguageModel({
                  ...languageModel,
                  Fluency: e.target.value,
                });
              }}
            >
              <MenuItem value={t("Native")} selected={true}>
                {t("Native")}
              </MenuItem>
              <MenuItem value={t("Fluent")}>{t("Fluent")}</MenuItem>
              <MenuItem value={t("Proficient")}>{t("Proficient")}</MenuItem>
              <MenuItem value={t("Advanced")}>{t("Advanced")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
          className="add_btn_resume"
            variant="contained"
            sx={{ mt: "2px" }}
            fullWidth
            onClick={addLanguage}
          >
            {t("Add")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              borderRadius: 1,
              border: 1,
              borderColor: "grey.500",
              height: 100,
              p: 1,
              overflow: "auto",
            }}
            component="div"
          >
            {props.LanguagesDetail?.map((s, index) => {
              let chiplabel = s.Fluency ? s.Name + " | " + s.Fluency : s.Name;
              return (
                <Chip
                  key={index}
                  label={chiplabel}
                  size="small"
                  variant="outlined"
                  sx={{ mr: 1, mt: 1 }}
                  onDelete={(e) => props.RemoveLanguageHandler(index)}
                />
              );
            })}
          </Box>
          {Boolean(props.LanguagesDetailError) && (
            <FormHelperText error={true}>
              {t(props.LanguagesDetailError as string)}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(Language);
