import React, { useState, useRef, useContext, useEffect } from "react";
import Layout from "../components/Layout";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "../myStyles/createResumeStyle.css";
import { jsPDF } from "jspdf";
import { FloatButton } from "antd";
import { PlusOutlined, SaveFilled, FileTextFilled, VerticalAlignBottomOutlined } from "@ant-design/icons";
import DescriptionIcon from "@mui/icons-material/Description";
import CloudDownload from "@mui/icons-material/CloudDownload";
import Save from "@mui/icons-material/Save";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useTranslation } from "react-i18next";
import {
  ResumeBuilderModel,
  ResumeBuilderSchema,
  SkillModel,
  SkillSchema,
  WorkExperienceModel,
  EducationModel,
  CertificationModel,
  AwardModel,
  ProjectModel,
  LanguageModel,
  LanguageSchema,
  HobbyModel,
  HobbySchema,
  ReferenceModel,
} from "../model/ResumeBuilderModel";
import { useFormik } from "formik";
import WorkExperience from "../components/WorkExperience";

import * as Type from "../Utility/typeDef";
import PersonalInfo from "../components/PersonalInfo";
import Objective from "../components/Objective";
import Skill from "../components/Skill";
import Education from "../components/Education";
import Certification from "../components/Certification";
import Award from "../components/Award";
import Project from "../components/Project";
import Reference from "../components/Reference";
import Hobby from "../components/Hobby";
import Language from "../components/Language";
import { AuthoriseUri } from "../Utility/PageUrls";

import { ResumeData } from "../Utility/ResumeData";
import { GetFormattedDate, TryToGetDateTime } from "../Utility/common";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import Draggable from "react-draggable";
import ResumeTemplateView from "../components/ResumeTemplateView";

import Hidden from "@mui/material/Hidden";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Switch from "@mui/material/Switch";
import { grey } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";
import { Fab, Tooltip } from "@mui/material";
import {
  getAllResumeTemplate,
  addUserResume,
  updateUserResume,
} from "../service/firebase";
import { AuthContext } from "../context/AuthContext";
import { useLocation } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "../components/CustomAccordion";
import { PlusOneOutlined } from "@mui/icons-material";



const PanelSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: grey[900],
    "&:hover": {
      backgroundColor: alpha(grey[900], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: grey[900],
  },
}));

function CreateBuilder() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState("panel1");
  const [selectedTemplate, setSelectedTemplate] = useState({ __html: "" });
  const [swipeableDrawerOpenFlag, setSwipeableDrawerOpenFlag] = useState(false);
  const [arrayOfDeltaPosition, setArrayOfDeltaPosition] = useState([
    { x: 0, y: 0 },
  ]);
  const [initialResumeSize, setInitialResumeSize] = useState(0);
  const [deltaPosition, setDeltaPosition] = useState({ x: 0, y: 1028 });
  const resumeContainer = document.getElementById("resumeForDownload");
  const defaultPageLenght = 1028;
  const resumeTopPadding = 20;
  const resumeBottomPadding = 20;
  const allResumeTemplate: { __html: string }[] = [
    { __html: ResumeData.TemplateOne },
    { __html: ResumeData.TemplateTwo },
  ];
  const [docId, setDocId] = useState<string>("");
  const [alltemplats, setAllTeplate] = useState<Type.IResumeTemplate[]>();
  const [startDownload, setStartDownload] = useState(false);
  const authContext = useContext(AuthContext);
  const user = authContext.getSessionUser();
  const location = useLocation();

  const componentRef = useRef<HTMLElement>(null);
  const formRef = useRef<HTMLFormElement>(null);

  React.useEffect(() => {
    populateValue();
  }, []);

  const getTemplate = async () => {
    window.showProgressbar();
    let result = await getAllResumeTemplate(
      authContext?.getUserDatail?.Role == "admin"
    );
    result?.sort(function (a, b) {
      return (
        a.Sequence - b.Sequence ||
        b.Weightage - a.Weightage ||
        a.Name.localeCompare(b.Name)
      );
    })
    setAllTeplate(result);
    window.hideProgressbar();
  };

  const handleDrag = (e: any, ui: any) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  const dragHandler = (index: number) => {
    //console.log("dragHandler " + index);
    let item = arrayOfDeltaPosition[index];
    return (e: any, ui: any) => {
      item.y = item.y + ui.deltaY;
      setArrayOfDeltaPosition(
        arrayOfDeltaPosition.map((it, i) =>
          i == index ? { ...it, y: item.y } : it
        )
      );
    };
  };

  let model: ResumeBuilderModel = {
    UserId: "",
    TemplateId: "",
    PageBreakPoints: [],
    CreateDate: new Date(),
    ModifyDate: new Date(),
    Awards: {
      IsAwardThere: true,
      Header: "Award",
      AwardsDetails: [
        { Awarder: "", Date: new Date(), Summary: "", Title: "" },
      ],
    },
    Certification: {
      IsCertificationThere: true,
      Header: "Certification",
      CertificationDetails: [
        {
          Date: new Date(),
          Issuer: "",
          Summary: "",
          Title: "",
          IsPursuing: false,
        },
      ],
    },
    Education: {
      IsEducationThere: true,
      Header: "Education",
      AcedemyDetails: [
        {
          EndDate: new Date(),
          FieldOfStudy: "",
          Institution: "",
          IsPursuing: false,
          MarkInPercentage: "",
          StartDate: new Date(),
          Summary: "",
          TypeOfDegree: "",
        },
      ],
    },
    Hobby: { IsHobbyThere: true, Header: "Hobby", Hobbies: [] },
    Language: {
      IsLanguageThere: true,
      Header: "Language",
      LanguageDetails: [],
    },
    Objective: {
      IsObjectiveThere: true,
      Header: "Objective",
      Summary: "",
    },
    Profile: {
      Address: "",
      DateOfBirth: new Date(),
      Email: "",
      Name: "",
      PhoneNumber: "",
      Subtitle: "",
      Website: "",
      Header: "Profile",
    },
    Project: {
      IsProjectThere: true,
      Header: "Project",
      ProjectsDetail: [
        {
          EndDate: new Date(),
          IsYourCurrentProject: false,
          StartDate: new Date(),
          Summary: "",
          Title: "",
          Website: "",
        },
      ],
    },
    Reference: {
      IsReferenceThere: true,
      Header: "Reference",
      ReferencesDetail: [
        { Email: "", Name: "", PoneNumber: "", Position: "", Summary: "" },
      ],
    },
    SocialNetworks: [],
    WorkExperience: {
      IsWorkExperienceThere: true,
      Header: "Work Experience",
      WorkHistory: [
        {
          Company: "",
          CurrentJob: false,
          Summary: "",
          Website: "",
          Position: "",
          StartDate: new Date(),
          EndDate: new Date(),
        },
      ],
    },
    Skill: { IsSkillThere: true, Header: "Skills", Skills: [] },
  };

  if (location.state) {
    model = { ...(location.state as ResumeBuilderModel) };
    model.Profile.DateOfBirth = TryToGetDateTime(model.Profile.DateOfBirth);
    model.WorkExperience.WorkHistory?.forEach((item) => {
      item.StartDate = TryToGetDateTime(item.StartDate);
      item.EndDate =
        TryToGetDateTime(
          item.EndDate
        );
    })
    model.Education.AcedemyDetails?.forEach((item) => {
      item.EndDate = TryToGetDateTime(item.EndDate);
      item.StartDate = TryToGetDateTime(item.StartDate)
    })
    model.Certification.CertificationDetails?.forEach((item) => {
      item.Date = TryToGetDateTime(item.Date)
    })
    model.Awards.AwardsDetails?.forEach((item) => {
      item.Date = TryToGetDateTime(
        item.Date
      );
    })
    model.Project.ProjectsDetail?.forEach((item) => {
      item.EndDate = TryToGetDateTime(item.EndDate);
      item.StartDate = TryToGetDateTime(item.StartDate);
    })
    model.CreateDate = TryToGetDateTime(model.CreateDate);
    model.ModifyDate = new Date();
  }

  const formik = useFormik({
    initialValues: model,
    validationSchema: ResumeBuilderSchema,
    onSubmit: (values) => {
      //DownloadPdf();
      let data = values;
      data.PageBreakPoints = arrayOfDeltaPosition;
      if (docId == "" || docId == null || docId == undefined) {
        createResume(data);
      } else {
        delete data.CreateDate;
        udateResume(data);
      }
    },
  });

  const createResume = async (values: any) => {
    window.showProgressbar();
    await addUserResume(values, createResumeCallBack);
    window.hideProgressbar();
  };

  const createResumeCallBack = (
    isError: boolean,
    message: string,
    docId: string
  ) => {
    !isError && Boolean(docId) && setDocId(docId);
    if (!isError && startDownload) {
      setStartDownload(false);
      DownloadPdf();
    } else {
      window.showGenericAlertDialog(undefined, t(message));
    }
  };

  const udateResume = async (values: any) => {
    window.showProgressbar();
    await updateUserResume(docId, values, updateResumeCallback);
    window.hideProgressbar();
  };

  const updateResumeCallback = (isError: boolean, message: string) => {
    if (!isError && startDownload) {
      setStartDownload(false);
      DownloadPdf();
    } else {
      window.showGenericAlertDialog(undefined, t(message));
    }
  };

  const populateValue = async () => {
    let templateView = document.getElementById("templateGridItem");
    //let templateView = document.getElementsByClassName("resumeViewpreview")[0];

    let updatePofile = async () => {
      let profileHeader = templateView?.getElementsByClassName("profileHeader");
      let profileName = templateView?.getElementsByClassName("profileName");
      let profileSubtitle =
        templateView?.getElementsByClassName("profileSubtitle");
      let profileDoB = templateView?.getElementsByClassName("profileDoB");
      let profileMobile = templateView?.getElementsByClassName("profileMobile");
      let profileEmail = templateView?.getElementsByClassName("profileEmail");
      let profileWebsite =
        templateView?.getElementsByClassName("profileWebsite");
      let profileAddress =
        templateView?.getElementsByClassName("profileAddress");

      
      if (profileHeader !== undefined) {
        for (let i = 0; i < profileHeader.length; i++) {
          profileHeader[i].innerHTML = model.Profile.Header as string;
        }
      }
      if (profileName !== undefined) {
        for (let i = 0; i < profileName.length; i++) {
          profileName[i].innerHTML = model.Profile.Name as string;
        }
      }

      if (profileSubtitle !== undefined) {
        for (let i = 0; i < profileSubtitle.length; i++) {
          profileSubtitle[i].innerHTML = model.Profile.Subtitle as string;
        }
      }

      if (profileDoB !== undefined) {
        for (let i = 0; i < profileDoB.length; i++) {
          profileDoB[i].innerHTML = GetFormattedDate(model.Profile.DateOfBirth);
        }
      }

      if (profileMobile !== undefined) {
        for (let i = 0; i < profileMobile.length; i++) {
          profileMobile[i].innerHTML = model.Profile.PhoneNumber as string;
        }
      }

      if (profileEmail !== undefined) {
        for (let i = 0; i < profileEmail.length; i++) {
          profileEmail[i].innerHTML = model.Profile.Email as string;
        }
      }

      if (profileWebsite !== undefined) {
        for (let i = 0; i < profileWebsite.length; i++) {
          profileWebsite[i].innerHTML = model.Profile.Website as string;
        }
      }

      if (profileAddress !== undefined) {
        for (let i = 0; i < profileAddress.length; i++) {
          profileAddress[i].innerHTML = model.Profile.Address as string;
        }
      }
    };

    let updateObjective = async () => {
      let objectiveHeader =
        templateView?.getElementsByClassName("objectiveHeader");
      let objectiveSummary =
        templateView?.getElementsByClassName("objectiveSummary");

      if (objectiveHeader !== undefined) {
        for (let i = 0; i < objectiveHeader.length; i++) {
          objectiveHeader[i].innerHTML = model.Objective.Header as string;
        }
      }
      if (objectiveSummary !== undefined) {
        for (let i = 0; i < objectiveSummary.length; i++) {
          objectiveSummary[i].innerHTML = model.Objective.Summary as string;
        }
      }
    };

    let updateSkill = async () => {
      let skillHeader = templateView?.getElementsByClassName("skillHeader");
      let skillTemplate = templateView?.getElementsByClassName("skillTemplate");

      if (skillHeader !== undefined) {
        for (let i = 0; i < skillHeader.length; i++) {
          skillHeader[i].innerHTML = model.Skill.Header as string;
        }
      }

      if (skillTemplate !== undefined) {
        for (let i = 0; i < skillTemplate.length; i++) {
          let arrayLength = model.Skill.Skills
            ? model.Skill?.Skills?.length
            : 0;
          let injectPoint = skillTemplate[i]
            .closest(".resumeViewpreview")!
            .querySelector(".skillTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                skillTemplate[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let skillName = cloneElement.querySelector(".skillName");
              let skillLevel = cloneElement.querySelector(".skillLevel");
              if (skillName !== null) {
                skillName.innerHTML = model.Skill.Skills![x].Name as string;
              }
              if (skillLevel !== null) {
                skillLevel.innerHTML = model.Skill?.Skills![x].Level as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateWorkingExpe = async () => {
      let header = templateView?.getElementsByClassName("workExperienceHeader");
      let template = templateView?.getElementsByClassName(
        "workExperienceTemplate"
      );

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.WorkExperience.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.WorkExperience.WorkHistory
            ? model.WorkExperience?.WorkHistory?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".workExperienceTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let companyName = cloneElement.querySelector(
                ".WorkExperienceCompanyName"
              );
              let position = cloneElement.querySelector(
                ".workExperiencePosition"
              );
              let website = cloneElement.querySelector(".wokExperienceWebsite");
              let Doj = cloneElement.querySelector(".workExperienceDoj");
              let Dol = cloneElement.querySelector(".workExperienceDoL");
              let summary = cloneElement.querySelector(
                ".workExperienceSummary"
              );
              if (companyName !== null) {
                companyName.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Company as string;
              }

              if (position !== null) {
                position.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Position as string;
              }

              if (website !== null) {
                website.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Website as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.WorkExperience.WorkHistory![x].StartDate
                );
              }

              if (Dol !== null) {
                Dol.innerHTML = GetFormattedDate(
                  model.WorkExperience.WorkHistory![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.WorkExperience.WorkHistory![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateEducation = async () => {
      let header = templateView?.getElementsByClassName("educationHeader");
      let template = templateView?.getElementsByClassName("educationTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Education.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Education.AcedemyDetails
            ? model.Education?.AcedemyDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".educationTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(
                ".educationInstitutionName"
              );
              let fieldOfStudy = cloneElement.querySelector(
                ".educationFieldOfStudy"
              );
              let typeOfDegree = cloneElement.querySelector(
                ".educationTypeOfDegree"
              );
              let Gpa = cloneElement.querySelector(".educationGpa");
              let Doj = cloneElement.querySelector(".educationStartDate");
              let Dol = cloneElement.querySelector(".educationEndDate");
              let summary = cloneElement.querySelector(".educationSummary");
              if (name !== null) {
                name.innerHTML = model.Education.AcedemyDetails![x]
                  .Institution as string;
              }

              if (fieldOfStudy !== null) {
                fieldOfStudy.innerHTML = model.Education.AcedemyDetails![x]
                  .FieldOfStudy as string;
              }

              if (typeOfDegree !== null) {
                typeOfDegree.innerHTML = model.Education.AcedemyDetails![x]
                  .TypeOfDegree as string;
              }

              if (Gpa !== null) {
                Gpa.innerHTML = model.Education.AcedemyDetails![x]
                  .MarkInPercentage as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.Education.AcedemyDetails![x].StartDate
                );
              }

              if (Dol !== null) {
                Dol.innerHTML = GetFormattedDate(
                  model.Education.AcedemyDetails![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Education.AcedemyDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateCertification = async () => {
      let header = templateView?.getElementsByClassName("certificationHeader");
      let template = templateView?.getElementsByClassName(
        "certificationTemplate"
      );

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Certification.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Certification.CertificationDetails
            ? model.Certification.CertificationDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".certificationTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".certificationTitle");
              let issuer = cloneElement.querySelector(".certificationIssuer");
              let Doj = cloneElement.querySelector(
                ".certificationCompleteDate"
              );
              let summary = cloneElement.querySelector(".certificationSummary");

              if (title !== null) {
                title.innerHTML = model.Certification.CertificationDetails![x]
                  .Title as string;
              }

              if (issuer !== null) {
                issuer.innerHTML = model.Certification.CertificationDetails![x]
                  .Issuer as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.Certification.CertificationDetails![x].Date
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Certification.CertificationDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateAward = async () => {
      let header = templateView?.getElementsByClassName("awardHeader");
      let template = templateView?.getElementsByClassName("awardTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Awards.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Awards.AwardsDetails
            ? model.Awards.AwardsDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".awardTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".awardTitle");
              let issuer = cloneElement.querySelector(".awardAwarder");
              let Doj = cloneElement.querySelector(".awardDate");
              let summary = cloneElement.querySelector(".awardSummary");

              if (title !== null) {
                title.innerHTML = model.Awards.AwardsDetails![x]
                  .Title as string;
              }

              if (issuer !== null) {
                issuer.innerHTML = model.Awards.AwardsDetails![x]
                  .Awarder as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  model.Awards.AwardsDetails![x].Date
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Awards.AwardsDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateProject = async () => {
      let header = templateView?.getElementsByClassName("projectHeader");
      let template = templateView?.getElementsByClassName("projectTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Project.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Project.ProjectsDetail
            ? model.Project.ProjectsDetail?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".projectTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".projectTitle");
              let website = cloneElement.querySelector(".projectWebsite");
              let startDate = cloneElement.querySelector(".projectStartDate");
              let endDate = cloneElement.querySelector(".projectEndDate");
              let summary = cloneElement.querySelector(".projectSummary");

              if (title !== null) {
                title.innerHTML = model.Project.ProjectsDetail![x]
                  .Title as string;
              }

              if (website !== null) {
                website.innerHTML = model.Project.ProjectsDetail![x]
                  .Website as string;
              }

              if (startDate !== null) {
                startDate.innerHTML = GetFormattedDate(
                  model.Project.ProjectsDetail![x].StartDate
                );
              }

              if (endDate !== null) {
                endDate.innerHTML = GetFormattedDate(
                  model.Project.ProjectsDetail![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = model.Project.ProjectsDetail![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateLanguage = async () => {
      let header = templateView?.getElementsByClassName("languageHeader");
      let template = templateView?.getElementsByClassName("languageTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Language.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Language.LanguageDetails
            ? model.Language.LanguageDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".languageTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".languageName");
              let level = cloneElement.querySelector(".languageLevel");

              if (name !== null) {
                name.innerHTML = model.Language.LanguageDetails![x]
                  .Name as string;
              }

              if (level !== null) {
                level.innerHTML = model.Language.LanguageDetails![x]
                  .Fluency as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateHobby = async () => {
      let header = templateView?.getElementsByClassName("hobbyHeader");
      let template = templateView?.getElementsByClassName("hobbyTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Hobby.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Hobby.Hobbies
            ? model.Hobby.Hobbies?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".hobbyTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".hobbyName");

              if (name !== null) {
                name.innerHTML = model.Hobby.Hobbies![x].Name as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateReference = async () => {
      let header = templateView?.getElementsByClassName("referenceHeader");
      let template = templateView?.getElementsByClassName("referenceTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = model.Reference.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = model.Reference.ReferencesDetail
            ? model.Reference.ReferencesDetail?.length
            : 0;
          let injectPoint = template[i]
            .closest(".resumeViewpreview")!
            .querySelector(".referenceTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".referenceName");
              let email = cloneElement.querySelector(".referenceEmail");
              let mobile = cloneElement.querySelector(".referenceMobile");
              let position = cloneElement.querySelector(".referencePosition");
              let summary = cloneElement.querySelector(".referenceSummary");

              if (name !== null) {
                name.innerHTML = model.Reference.ReferencesDetail![x]
                  .Name as string;
              }

              if (email !== null) {
                email.innerHTML = model.Reference.ReferencesDetail![x]
                  .Email as string;
              }

              if (mobile !== null) {
                mobile.innerHTML = model.Reference.ReferencesDetail![x]
                  .PoneNumber as string;
              }

              if (position !== null) {
                position.innerHTML = model.Reference.ReferencesDetail![x]
                  .Position as string;
              }

              if (summary !== null) {
                summary.innerHTML = model.Reference.ReferencesDetail![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    await Promise.all([
      updatePofile(),
      updateObjective(),
      updateSkill(),
      updateWorkingExpe(),
      updateEducation(),
      updateCertification(),
      updateAward(),
      updateProject(),
      updateLanguage(),
      updateHobby(),
      updateReference(),
    ]);
  };

  const printResume = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    if (!event.target.className.includes("MuiSwitch-input")) {
      setExpanded(newExpanded ? panel : false);
    }
  };

  const selectTemplate = (templateId: string) => {
    let result = alltemplats?.filter((item) => item.Id == templateId);
    if (result && result.length > 0) {
      setSelectedTemplate({ __html: result[0].Template });
      formik.setFieldValue("TemplateId", templateId);
    }
  };

  const DownloadPdf = async () => {
    window.showProgressbar();
    let allPageBreakerElement = Array.from(
      document.getElementsByClassName("draggableDiv")
    );
    for (const x of allPageBreakerElement) {
      const y = x as HTMLElement;
      y.style.display = "none";
    }
    let doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
      putOnlyUsedFonts: true,
    });

    let element = document.getElementById("resumeForDownload");
    let resumeContainerDivHeight =
      document.getElementById("container")!.clientHeight;
    if (element != null) {
      for (let i = 0; i < arrayOfDeltaPosition.length; i++) {
        let option: any = {};
        let topMargin = i > 0 ? 30 : 0;
        option.y = arrayOfDeltaPosition[i].y;
        if (i !== arrayOfDeltaPosition.length - 1) {
          option.height =
            arrayOfDeltaPosition[i + 1].y - arrayOfDeltaPosition[i].y;
        }

        try {
          if (resumeContainerDivHeight >= option.y) {
            option.scale = 3
            let canvas = await html2canvas(element, option);
            let docInternalwidth = doc.internal.pageSize.getWidth();
            let docInternalheight = doc.internal.pageSize.getHeight();
            let imgData = canvas.toDataURL("image/jpeg", 0.5);
            let imageWidth = canvas.width;
            let imageHeight =
              docInternalwidth * (canvas.height / canvas.width) - 20;
            //doc.addImage(imgData, "PNG", 0, 0, docInternalwidth, imageHeight);

            if (i > 0) {
              doc.addPage();
            }

            doc.addImage(
              imgData,
              "JPEG",
              0,
              topMargin,
              docInternalwidth,
              imageHeight
            );
          }


          if (i === arrayOfDeltaPosition.length - 1) {
            let fileName = "Resume " + authContext?.getUserDatail?.Name + ".pdf"
            doc.save(fileName);
          }
        } catch (e) { }
      }
    }

    for (const x of allPageBreakerElement) {
      const y = x as HTMLElement;
      y.style.display = "";
    }
    window.hideProgressbar();
  };

  const formikHandlers = (): Type.IFormikEvent => {
    return {
      onBlurHandler: formik.handleBlur,
      onChangeHandler: formik.handleChange,
      SetValueByPropNameHandler: (propsName: string, newValue: any) => {
        formik.setFieldTouched(propsName, true);
        formik.setFieldValue(propsName, newValue);
      },
    };
  };


  const memoizedPersonalInfo = React.useMemo(() => {
    let obj: Type.IPersonalInfo = {
      Address: {
        errorMessage:
          formik.touched.Profile?.Address &&
          t(formik.errors.Profile?.Address as string),
        isError:
          formik.touched.Profile?.Address &&
          Boolean(formik.errors.Profile?.Address),
        value: formik.values.Profile.Address,
      },
      DOB: {
        errorMessage:
          formik.touched.Profile?.DateOfBirth &&
          t(formik.errors.Profile?.DateOfBirth as string),
        isError:
          formik.touched.Profile?.DateOfBirth &&
          Boolean(formik.errors.Profile?.DateOfBirth),
        value: formik.values.Profile.DateOfBirth,
      },
      Email: {
        errorMessage:
          formik.touched.Profile?.Email &&
          t(formik.errors.Profile?.Email as string),
        isError:
          formik.touched.Profile?.Email &&
          Boolean(formik.errors.Profile?.Email),
        value: formik.values.Profile.Email,
      },
      Header: {
        errorMessage:
          formik.touched.Profile?.Header &&
          t(formik.errors.Profile?.Header as string),
        isError:
          formik.touched.Profile?.Header &&
          Boolean(formik.errors.Profile?.Header),
        value: formik.values.Profile.Header,
      },
      Mobile: {
        errorMessage:
          formik.touched.Profile?.PhoneNumber &&
          t(formik.errors.Profile?.PhoneNumber as string),
        isError:
          formik.touched.Profile?.PhoneNumber &&
          Boolean(formik.errors.Profile?.PhoneNumber),
        value: formik.values.Profile.PhoneNumber,
      },
      Name: {
        errorMessage:
          formik.touched.Profile?.Name &&
          t(formik.errors.Profile?.Name as string),
        isError:
          formik.touched.Profile?.Name && Boolean(formik.errors.Profile?.Name),
        value: formik.values.Profile.Name,
      },
      Subtitle: {
        errorMessage:
          formik.touched.Profile?.Subtitle &&
          t(formik.errors.Profile?.Subtitle as string),
        isError:
          formik.touched.Profile?.Subtitle &&
          Boolean(formik.errors.Profile?.Subtitle),
        value: formik.values.Profile.Subtitle,
      },
      Website: {
        errorMessage:
          formik.touched.Profile?.Website &&
          t(formik.errors.Profile?.Website as string),
        isError:
          formik.touched.Profile?.Website &&
          Boolean(formik.errors.Profile?.Website),
        value: formik.values.Profile.Website,
      },
      ...formikHandlers(),
    };
    return obj;
  }, [formik.values.Profile, formik.errors.Profile, formik.touched.Profile]);

  const memoizedObjective = React.useMemo(() => {
    let obj: Type.IObjective = {
      Header: {
        errorMessage:
          formik.touched.Objective?.Header &&
          t(formik.errors.Objective?.Header as string),
        isError:
          formik.touched.Objective?.Header &&
          Boolean(formik.errors.Objective?.Header),
        value: formik.values.Objective.Header,
      },
      Summary: {
        errorMessage:
          formik.touched.Objective?.Summary &&
          t(formik.errors.Objective?.Summary as string),
        isError:
          formik.touched.Objective?.Summary &&
          Boolean(formik.errors.Objective?.Summary),
        value: formik.values.Objective.Summary,
      },
      ...formikHandlers(),
    };
    return obj;
  }, [
    formik.values.Objective,
    formik.errors.Objective,
    formik.touched.Objective,
  ]);

  const memoizedSkill = React.useMemo(() => {
    let obj: Type.ISkill = {
      Header: {
        errorMessage:
          formik.touched.Skill?.Header &&
          t(formik.errors.Skill?.Header as string),
        isError:
          formik.touched.Skill?.Header && Boolean(formik.errors.Skill?.Header),
        value: formik.values.Skill.Header,
      },
      AddSkillHandler: (
        name: string,
        level: string | undefined,
        callbackFun: () => void
      ) => {
        let skillModel: SkillModel = { Name: name, Level: level };
        SkillSchema.validate(skillModel)
          .then(function (value) {
            let index = formik.values.Skill.Skills?.length;
            formik.setFieldValue(`Skill.Skills[${index}]`, skillModel);
            callbackFun();
          })
          .catch(function (err) {
            console.log(err);
          });
      },
      RemoveSkillHandler: (index: number) => {
        let model = formik.values;
        model.Skill.Skills = model.Skill.Skills?.filter(
          (model, ind) => ind != index
        );
        //formik.setValues({ ...model });
        formik.setFieldValue("Skill.Skills", model.Skill.Skills);
      },
      SkillsDetails: formik.values.Skill.Skills,
      SkillsDetailError:
        formik.touched.Skill?.Skills && formik.errors.Skill?.Skills,
      ...formikHandlers(),
    };

    return obj;
  }, [
    formik.values.Skill,
    formik.values.Skill.Skills,
    formik.errors.Skill,
    formik.touched.Skill?.Header,
    formik.touched.Skill?.Skills,
  ]);

  const memoizeWorkExperience = React.useMemo(() => {
    let obj: Type.IWorkExperience = {
      Header: {
        errorMessage:
          formik.touched.WorkExperience?.Header &&
          t(formik.errors.WorkExperience?.Header as string),
        isError:
          formik.touched.WorkExperience?.Header &&
          Boolean(formik.errors.WorkExperience?.Header),
        value: formik.values.WorkExperience.Header,
      },
      WorkHistory: formik.values.WorkExperience.WorkHistory,
      WorkHistoryError: formik.errors.WorkExperience?.WorkHistory,
      WorkHistoryTouched: formik.touched.WorkExperience?.WorkHistory,
      AddWorkHistrory: () => {
        let index = formik.values.WorkExperience.WorkHistory?.length;
        let workHistory: WorkExperienceModel = {
          Company: "",
          CurrentJob: false,
          EndDate: new Date(),
          Position: "",
          StartDate: new Date(),
          Summary: "",
          Website: "",
        };
        formik.setFieldValue(
          `WorkExperience.WorkHistory[${index}]`,
          workHistory
        );
      },
      RemoveWorkHistory: (index: number) => {
        let model = formik.values;
        model.WorkExperience.WorkHistory =
          model.WorkExperience.WorkHistory?.filter(
            (model, ind) => ind != index
          );
        //formik.setValues({ ...model });
        formik.setFieldValue(
          "WorkExperience.WorkHistory",
          model.WorkExperience.WorkHistory
        );

        let touchedModel = formik.touched.WorkExperience?.WorkHistory;
        if (touchedModel != null && Array.isArray(touchedModel)) {
          let temp = touchedModel.filter((item, ind) => ind != index);
          formik.setTouched({
            ...formik.touched,
            WorkExperience: {
              ...formik.touched.WorkExperience,
              // @ts-ignore
              WorkHistory: temp,
            },
          });
        }
      },
      ...formikHandlers(),
    };
    return obj;
  }, [
    formik.values.WorkExperience,
    formik.errors.WorkExperience,
    formik.touched.WorkExperience,
    formik.values.WorkExperience.WorkHistory,
  ]);

  const memoizeEducation = React.useMemo(() => {
    let obj: Type.IEducation = {
      Header: {
        errorMessage:
          formik.touched.Education?.Header &&
          t(formik.errors.Education?.Header as string),
        isError:
          formik.touched.Education?.Header &&
          Boolean(formik.errors.Education?.Header),
        value: formik.values.Education.Header,
      },
      EducationHistory: formik.values.Education.AcedemyDetails,
      EducationHistoryError: formik.errors.Education?.AcedemyDetails,
      EducationHistoryTouched: formik.touched.Education?.AcedemyDetails,
      AddEducationHistrory: () => {
        let index = formik.values.Education.AcedemyDetails?.length;
        let acedemyDetails: EducationModel = {
          EndDate: new Date(),
          FieldOfStudy: "",
          Institution: "",
          IsPursuing: false,
          MarkInPercentage: "",
          StartDate: new Date(),
          Summary: "",
          TypeOfDegree: "",
        };
        formik.setFieldValue(
          `Education.AcedemyDetails[${index}]`,
          acedemyDetails
        );
      },
      RemoveEducationHistory: (index: number) => {
        let model = formik.values;
        model.Education.AcedemyDetails = model.Education.AcedemyDetails?.filter(
          (model, ind) => ind != index
        );
        //formik.setValues({ ...model });
        formik.setFieldValue(
          "Education.AcedemyDetails",
          model.Education.AcedemyDetails
        );
        let touchedModel = formik.touched.Education?.AcedemyDetails;
        if (touchedModel != null && Array.isArray(touchedModel)) {
          let temp = touchedModel.filter((item, ind) => ind != index);
          formik.setTouched({
            ...formik.touched,
            Education: {
              ...formik.touched.Education,
              // @ts-ignore
              AcedemyDetails: temp,
            },
          });
        }
      },
      ...formikHandlers(),
    };
    return obj;
  }, [
    formik.values.Education,
    formik.errors.Education,
    formik.touched.Education,
    formik.values.Education.AcedemyDetails,
  ]);

  const memoizeCertificate = React.useMemo(() => {
    let obj: Type.ICertification = {
      Header: {
        errorMessage:
          formik.touched.Certification?.Header &&
          t(formik.errors.Certification?.Header as string),
        isError:
          formik.touched.Certification?.Header &&
          Boolean(formik.errors.Certification?.Header),
        value: formik.values.Certification.Header,
      },
      CertificationHistory: formik.values.Certification.CertificationDetails,
      CertificationHistoryError:
        formik.errors.Certification?.CertificationDetails,
      CertificationHistoryTouched:
        formik.touched.Certification?.CertificationDetails,
      AddCertificationHistrory: () => {
        let index = formik.values.Certification.CertificationDetails?.length;
        let certificationDetails: CertificationModel = {
          Date: new Date(),
          IsPursuing: false,
          Issuer: "",
          Summary: "",
          Title: "",
        };
        formik.setFieldValue(
          `Certification.CertificationDetails[${index}]`,
          certificationDetails
        );
      },
      RemoveCertificationHistory: (index: number) => {
        let model = formik.values;
        model.Certification.CertificationDetails =
          model.Certification.CertificationDetails?.filter(
            (model, ind) => ind != index
          );
        //formik.setValues({ ...model });
        formik.setFieldValue(
          "Certification.CertificationDetails",
          model.Certification.CertificationDetails
        );

        let touchedModel = formik.touched.Certification?.CertificationDetails;
        if (touchedModel != null && Array.isArray(touchedModel)) {
          let temp = touchedModel.filter((item, ind) => ind != index);
          formik.setTouched({
            ...formik.touched,
            Certification: {
              ...formik.touched.Certification,
              // @ts-ignore
              CertificationDetails: temp,
            },
          });
        }
      },
      ...formikHandlers(),
    };
    return obj;
  }, [
    formik.values.Certification,
    formik.errors.Certification,
    formik.touched.Certification,
    formik.values.Certification.CertificationDetails,
  ]);

  const memoizeAward = React.useMemo(() => {
    let obj: Type.IAward = {
      Header: {
        errorMessage:
          formik.touched.Awards?.Header &&
          t(formik.errors.Awards?.Header as string),
        isError:
          formik.touched.Awards?.Header &&
          Boolean(formik.errors.Awards?.Header),
        value: formik.values.Awards.Header,
      },
      AwardHistory: formik.values.Awards.AwardsDetails,
      AwardHistoryError: formik.errors.Awards?.AwardsDetails,
      AwardHistoryTouched: formik.touched.Awards?.AwardsDetails,
      AddAwardHistrory: () => {
        let index = formik.values.Awards.AwardsDetails?.length;
        let awardDetails: AwardModel = {
          Awarder: "",
          Date: new Date(),
          Summary: "",
          Title: "",
        };
        formik.setFieldValue(`Awards.AwardsDetails[${index}]`, awardDetails);
      },
      RemoveAwardHistory: (index: number) => {
        let model = formik.values;
        model.Awards.AwardsDetails = model.Awards.AwardsDetails?.filter(
          (model, ind) => ind != index
        );
        //formik.setValues({ ...model });
        formik.setFieldValue(
          "Awards.AwardsDetails",
          model.Awards.AwardsDetails
        );

        let touchedModel = formik.touched.Awards?.AwardsDetails;
        if (touchedModel != null && Array.isArray(touchedModel)) {
          let temp = touchedModel.filter((item, ind) => ind != index);
          formik.setTouched({
            ...formik.touched,
            Awards: {
              ...formik.touched.Awards,
              // @ts-ignore
              AwardsDetails: temp,
            },
          });
        }
      },
      ...formikHandlers(),
    };
    return obj;
  }, [
    formik.values.Awards,
    formik.errors.Awards,
    formik.touched.Awards,
    formik.values.Awards.AwardsDetails,
  ]);

  const memoizeProject = React.useMemo(() => {
    let obj: Type.IProject = {
      Header: {
        errorMessage:
          formik.touched.Project?.Header &&
          t(formik.errors.Project?.Header as string),
        isError:
          formik.touched.Project?.Header &&
          Boolean(formik.errors.Project?.Header),
        value: formik.values.Project.Header,
      },
      ProjectHistory: formik.values.Project.ProjectsDetail,
      ProjectHistoryError: formik.errors.Project?.ProjectsDetail,
      ProjectHistoryTouched: formik.touched.Project?.ProjectsDetail,
      AddProjectHistrory: () => {
        let index = formik.values.Project.ProjectsDetail?.length;
        let projectDetails: ProjectModel = {
          EndDate: new Date(),
          IsYourCurrentProject: false,
          StartDate: new Date(),
          Summary: "",
          Title: "",
          Website: "",
        };
        formik.setFieldValue(
          `Project.ProjectsDetail[${index}]`,
          projectDetails
        );
      },
      RemoveProjectHistory: (index: number) => {
        let model = formik.values;
        model.Project.ProjectsDetail = model.Project.ProjectsDetail?.filter(
          (model, ind) => ind != index
        );
        //formik.setValues({ ...model });
        formik.setFieldValue(
          "Project.ProjectsDetail",
          model.Project.ProjectsDetail
        );

        let touchedModel = formik.touched.Project?.ProjectsDetail;
        if (touchedModel != null && Array.isArray(touchedModel)) {
          let temp = touchedModel.filter((item, ind) => ind != index);
          formik.setTouched({
            ...formik.touched,
            Project: {
              ...formik.touched.Project,
              // @ts-ignore
              ProjectsDetail: temp,
            },
          });
        }
      },
      ...formikHandlers(),
    };
    return obj;
  }, [
    formik.values.Project,
    formik.errors.Project,
    formik.touched.Project,
    formik.values.Project.ProjectsDetail,
  ]);

  const memoizeReference = React.useMemo(() => {
    let obj: Type.IReference = {
      Header: {
        errorMessage:
          formik.touched.Reference?.Header &&
          t(formik.errors.Reference?.Header as string),
        isError:
          formik.touched.Reference?.Header &&
          Boolean(formik.errors.Reference?.Header),
        value: formik.values.Reference.Header,
      },
      ReferenceHistory: formik.values.Reference.ReferencesDetail,
      ReferenceHistoryError: formik.errors.Reference?.ReferencesDetail,
      ReferenceHistoryTouched: formik.touched.Reference?.ReferencesDetail,
      AddReferenceHistrory: () => {
        let index = formik.values.Reference.ReferencesDetail?.length;
        let referenceDetail: ReferenceModel = {
          Email: "",
          Name: "",
          PoneNumber: "",
          Position: "",
          Summary: "",
        };
        formik.setFieldValue(
          `Reference.ReferencesDetail[${index}]`,
          referenceDetail
        );
      },
      RemoveReferenceHistory: (index: number) => {
        let model = formik.values;
        model.Reference.ReferencesDetail =
          model.Reference.ReferencesDetail?.filter(
            (model, ind) => ind != index
          );
        //formik.setValues({ ...model });
        formik.setFieldValue(
          "Reference.ReferencesDetail",
          model.Reference.ReferencesDetail
        );

        let touchedModel = formik.touched.Reference?.ReferencesDetail;
        if (touchedModel != null && Array.isArray(touchedModel)) {
          let temp = touchedModel.filter((item, ind) => ind != index);
          formik.setTouched({
            ...formik.touched,
            Reference: {
              ...formik.touched.Reference,
              // @ts-ignore
              ReferencesDetail: temp,
            },
          });
        }
      },
      ...formikHandlers(),
    };
    return obj;
  }, [
    formik.values.Reference,
    formik.errors.Reference,
    formik.touched.Reference,
    formik.values.Reference.ReferencesDetail,
  ]);

  const memoizedHobby = React.useMemo(() => {
    let obj: Type.IHobby = {
      Header: {
        errorMessage:
          formik.touched.Hobby?.Header &&
          t(formik.errors.Hobby?.Header as string),
        isError:
          formik.touched.Hobby?.Header && Boolean(formik.errors.Hobby?.Header),
        value: formik.values.Hobby.Header,
      },
      AddHobbyHandler: (name: string | undefined, callbackFun: () => void) => {
        let hobbyModel: HobbyModel = { Name: name };
        HobbySchema.validate(hobbyModel)
          .then(function (value) {
            let index = formik.values.Hobby.Hobbies?.length;
            formik.setFieldValue(`Hobby.Hobbies[${index}]`, hobbyModel);
            callbackFun();
          })
          .catch(function (err) {
            console.log(err);
          });
      },
      RemoveHobbyHandler: (index: number) => {
        let model = formik.values;
        model.Hobby.Hobbies = model.Hobby.Hobbies?.filter(
          (model, ind) => ind != index
        );
        //formik.setValues({ ...model });
        formik.setFieldValue("Hobby.Hobbies", model.Hobby.Hobbies);
      },
      HobbiesDetail: formik.values.Hobby.Hobbies,
      ...formikHandlers(),
    };

    return obj;
  }, [formik.values.Hobby, formik.values.Hobby.Hobbies, formik.errors.Hobby]);

  const memoizedLanguage = React.useMemo(() => {
    let obj: Type.ILanguage = {
      Header: {
        errorMessage:
          formik.touched.Language?.Header &&
          t(formik.errors.Language?.Header as string),
        isError:
          formik.touched.Language?.Header &&
          Boolean(formik.errors.Language?.Header),
        value: formik.values.Language.Header,
      },
      AddLanguageHandler: (
        name: string | undefined,
        fluency: string | undefined,
        callbackFun: () => void
      ) => {
        let languageModel: LanguageModel = { Fluency: fluency, Name: name };
        LanguageSchema.validate(languageModel)
          .then(function (value) {
            let index = formik.values.Language.LanguageDetails?.length;
            formik.setFieldValue(
              `Language.LanguageDetails[${index}]`,
              languageModel
            );
            callbackFun();
          })
          .catch(function (err) {
            console.log(err);
          });
      },
      RemoveLanguageHandler: (index: number) => {
        let model = formik.values;
        model.Language.LanguageDetails = model.Language.LanguageDetails?.filter(
          (model, ind) => ind != index
        );
        //formik.setValues({ ...model });
        formik.setFieldValue(
          "Language.LanguageDetails",
          model.Language.LanguageDetails
        );
      },
      LanguagesDetail: formik.values.Language.LanguageDetails,
      LanguagesDetailError: formik.touched.Language?.LanguageDetails && formik.errors.Language?.LanguageDetails,
      ...formikHandlers(),
    };

    return obj;
  }, [
    formik.values.Language,
    formik.values.Language.LanguageDetails,
    formik.errors.Language,
    formik.touched.Language?.Header,
    formik.touched.Language?.LanguageDetails
  ]);

  const setAllResumeElementsVisibility = () => {
    setResumeElementVisibility(
      "objective",
      formik.values.Objective?.IsObjectiveThere ?? true
    );
    setResumeElementVisibility(
      "skill",
      formik.values.Skill?.IsSkillThere ?? true
    );
    setResumeElementVisibility(
      "workExperience",
      formik.values.WorkExperience?.IsWorkExperienceThere ?? true
    );
    setResumeElementVisibility(
      "education",
      formik.values.Education?.IsEducationThere ?? true
    );
    setResumeElementVisibility(
      "certification",
      formik.values.Certification?.IsCertificationThere ?? true
    );
    setResumeElementVisibility(
      "award",
      formik.values.Awards?.IsAwardThere ?? true
    );
    setResumeElementVisibility(
      "project",
      formik.values.Project?.IsProjectThere ?? true
    );
    setResumeElementVisibility(
      "language",
      formik.values.Language?.IsLanguageThere ?? true
    );
    setResumeElementVisibility(
      "hobby",
      formik.values.Hobby?.IsHobbyThere ?? true
    );
    setResumeElementVisibility(
      "reference",
      formik.values.Reference?.IsReferenceThere ?? true
    );
  };

  const setResumeElementVisibility = (className: string, show: boolean) => {
    if (resumeContainer) {
      let el = resumeContainer.getElementsByClassName(className);
      if (el.length > 0) {
        (el[0] as HTMLElement).style.display = show ? "" : "none";
      }
    }
  };

  const updateResumeTemplate = async (className: string, newValue: any) => {
    if (resumeContainer) {
      let el = resumeContainer.getElementsByClassName(className);
      if (el.length > 0) {
        el[0].innerHTML = newValue;
      }
    }
  };

  const getTemplateElement = function (
    templateClassName: string,
    templateInjectPoint: string
  ): [HTMLTemplateElement, Element] | undefined {
    if (resumeContainer) {
      let el = resumeContainer.getElementsByClassName(templateClassName);
      let injectPoint =
        resumeContainer.getElementsByClassName(templateInjectPoint);
      if (el.length > 0 && injectPoint.length > 0) {
        injectPoint[0].innerHTML = "";
        let selectedTemplate = el[0] as HTMLTemplateElement;
        let injectHere = injectPoint[0];
        return [el[0] as HTMLTemplateElement, injectPoint[0]];
      }
      return undefined;
    }
  };

  React.useEffect(() => {
    getTemplate();
    let userDetails = user as any;
    if (userDetails != null) {
      formik.setFieldValue("UserId", userDetails.user.uid);
    }
  }, []);

  React.useEffect(() => {
    let tempValue = new URLSearchParams(location.search).get("temp");
    if (
      alltemplats != null &&
      alltemplats != undefined &&
      alltemplats.length > 0
    ) {
      if (tempValue) {
        let requestedTemplate = alltemplats.filter(
          (item) => item.Id == tempValue
        );
        setSelectedTemplate({ __html: requestedTemplate[0].Template });
        formik.setFieldValue("TemplateId", requestedTemplate[0].Id);
      }
      else if (formik.values.TemplateId) {
        let requestedTemplate = alltemplats.filter(
          (item) => item.Id == formik.values.TemplateId
        );
        if (requestedTemplate.length == 0) {
          setSelectedTemplate({ __html: alltemplats[0].Template });
          formik.setFieldValue("TemplateId", alltemplats[0].Id);
        }
        else {
          setSelectedTemplate({ __html: requestedTemplate[0].Template });
          formik.setFieldValue("TemplateId", requestedTemplate[0].Id);
          setArrayOfDeltaPosition(formik.values.PageBreakPoints);
        }
        setDocId((formik.values as any).Id)
        delete (formik.values as any).Id;
      } else {
        setSelectedTemplate({ __html: alltemplats[0].Template });
        formik.setFieldValue("TemplateId", alltemplats[0].Id);
      }
    }
  }, [alltemplats]);

  React.useEffect(() => {
    let resumeSize = document.getElementById("resumeForDownload")?.clientHeight;

    if (resumeSize != null) {
      if (initialResumeSize !== resumeSize) {
        setInitialResumeSize(resumeSize);

        let numberOfPageBreak = 0;
        let divisionResult = Math.floor(resumeSize / defaultPageLenght);
        let reminderResult = Math.ceil(resumeSize % defaultPageLenght);
        if (divisionResult < 0) {
          numberOfPageBreak = 0;
        }
        if (divisionResult > 0) {
          if (reminderResult > 0) {
            numberOfPageBreak = divisionResult;
          } else {
            numberOfPageBreak = divisionResult - 1;
          }
        }
        let tempArrayOfDeltaPostion = [{
          x: 0,
          y: 0,
          isError: false,
          errorMessage: "",
        }]
        for (let i = 1; i <= numberOfPageBreak; i++) {

          let yaxisPostion =
            defaultPageLenght * i - resumeTopPadding - resumeBottomPadding;

          let newDeltaElementPostion = {
            x: 0,
            y: yaxisPostion,
            isError: false,
            errorMessage: "",
          };

          tempArrayOfDeltaPostion.push(newDeltaElementPostion);
        }
        setArrayOfDeltaPosition(tempArrayOfDeltaPostion);
      }
    }
  }, [formik.values, componentRef.current?.clientHeight]);

  React.useEffect(() => {
    updateResumeTemplate(
      "profileHeader",
      formik.values.Profile.Header as string
    );

    setAllResumeElementsVisibility();
  }, [formik.values.Profile.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate("profileName", formik.values.Profile.Name as string);
  }, [formik.values.Profile.Name, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "profileSubtitle",
      formik.values.Profile.Subtitle as string
    );
  }, [formik.values.Profile.Subtitle, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "profileMobile",
      formik.values.Profile.PhoneNumber as string
    );
  }, [formik.values.Profile.PhoneNumber, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate("profileEmail", formik.values.Profile.Email as string);
  }, [formik.values.Profile.Email, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "profileWebsite",
      formik.values.Profile.Website as string
    );
  }, [formik.values.Profile.Website, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "profileAddress",
      formik.values.Profile.Address as string
    );
  }, [formik.values.Profile.Address, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "profileDoB",
      GetFormattedDate(formik.values.Profile.DateOfBirth)
    );
  }, [formik.values.Profile.DateOfBirth, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "objectiveHeader",
      formik.values.Objective.Header as string
    );
  }, [formik.values.Objective.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "objectiveSummary",
      formik.values.Objective.Summary as string
    );
  }, [formik.values.Objective.Summary, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate("skillHeader", formik.values.Skill.Header?.toString());
  }, [formik.values.Skill.Header, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "skillTemplate",
        "skillTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.Skill?.Skills?.map((s, i) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;
            let skillName = copiedHtmlElement.querySelector(".skillName");
            let skillLevel = copiedHtmlElement.querySelector(".skillLevel");
            if (skillName !== null) {
              skillName.innerHTML = s.Name as string;
            }
            if (skillLevel !== null) {
              skillLevel.innerHTML = s.Level as string;
            }
            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.Skill.Skills, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "workExperienceHeader",
      formik.values.WorkExperience.Header as string
    );
  }, [formik.values.WorkExperience.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "educationHeader",
      formik.values.Education.Header as string
    );
  }, [formik.values.Education.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "certificationHeader",
      formik.values.Certification.Header as string
    );
  }, [formik.values.Certification.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate("awardHeader", formik.values.Awards.Header as string);
  }, [formik.values.Awards.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "projectHeader",
      formik.values.Project.Header as string
    );
  }, [formik.values.Project.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "languageHeader",
      formik.values.Language.Header as string
    );
  }, [formik.values.Language.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate("hobbyHeader", formik.values.Hobby.Header as string);
  }, [formik.values.Hobby.Header, selectedTemplate]);

  React.useEffect(() => {
    updateResumeTemplate(
      "referenceHeader",
      formik.values.Reference.Header as string
    );
  }, [formik.values.Reference.Header, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "workExperienceTemplate",
        "workExperienceTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.WorkExperience?.WorkHistory?.map((iteam, index) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;

            let companyName = copiedHtmlElement.querySelector(
              ".WorkExperienceCompanyName"
            );
            let postion = copiedHtmlElement.querySelector(
              ".workExperiencePosition"
            );
            let website = copiedHtmlElement.querySelector(
              ".wokExperienceWebsite"
            );
            let DoJ = copiedHtmlElement.querySelector(".workExperienceDoj");
            let DoL = copiedHtmlElement.querySelector(".workExperienceDoL");
            let summary = copiedHtmlElement.querySelector(
              ".workExperienceSummary"
            );

            if (companyName !== null) {
              companyName.innerHTML = iteam.Company as string;
            }

            if (postion !== null) {
              postion.innerHTML = iteam.Position as string;
            }

            if (website !== null) {
              website.innerHTML = iteam.Website as string;
            }

            if (DoJ !== null) {
              DoJ.innerHTML = GetFormattedDate(iteam.StartDate);
            }

            if (DoL !== null) {
              if (iteam.CurrentJob) {
                DoL.innerHTML = "Present";
              } else {
                DoL.innerHTML = GetFormattedDate(iteam.EndDate);
              }
            }

            if (summary !== null) {
              summary.innerHTML = iteam.Summary as string;
            }
            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.WorkExperience.WorkHistory, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "educationTemplate",
        "educationTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.Education?.AcedemyDetails?.map((iteam, index) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;

            let institutionName = copiedHtmlElement.querySelector(
              ".educationInstitutionName"
            );
            let fieldOfStudy = copiedHtmlElement.querySelector(
              ".educationFieldOfStudy"
            );
            let typeOfDegree = copiedHtmlElement.querySelector(
              ".educationTypeOfDegree"
            );
            let Gpa = copiedHtmlElement.querySelector(".educationGpa");
            let startDate = copiedHtmlElement.querySelector(
              ".educationStartDate"
            );
            let endDate = copiedHtmlElement.querySelector(".educationEndDate");
            let summary = copiedHtmlElement.querySelector(".educationSummary");

            if (institutionName !== null) {
              institutionName.innerHTML = iteam.Institution as string;
            }

            if (fieldOfStudy !== null) {
              fieldOfStudy.innerHTML = iteam.FieldOfStudy as string;
            }

            if (typeOfDegree !== null) {
              typeOfDegree.innerHTML = iteam.TypeOfDegree as string;
            }

            if (Gpa !== null) {
              Gpa.innerHTML = iteam.MarkInPercentage as string;
            }

            if (startDate !== null) {
              startDate.innerHTML = GetFormattedDate(iteam.StartDate);
            }

            if (endDate !== null) {
              if (iteam.IsPursuing) {
                endDate.innerHTML = "Present";
              } else {
                endDate.innerHTML = GetFormattedDate(iteam.EndDate);
              }
            }

            if (summary !== null) {
              summary.innerHTML = iteam.Summary as string;
            }
            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.Education.AcedemyDetails, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "certificationTemplate",
        "certificationTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.Certification?.CertificationDetails?.map(
          (iteam, index) => {
            if (getTempElement !== undefined) {
              let copiedHtmlElement: HTMLElement =
                getTempElement[0].content.cloneNode(true) as HTMLElement;

              let title = copiedHtmlElement.querySelector(
                ".certificationTitle"
              );
              let issuer = copiedHtmlElement.querySelector(
                ".certificationIssuer"
              );
              let endDate = copiedHtmlElement.querySelector(
                ".certificationCompleteDate"
              );
              let summary = copiedHtmlElement.querySelector(
                ".certificationSummary"
              );

              if (title !== null) {
                title.innerHTML = iteam.Title as string;
              }

              if (issuer !== null) {
                issuer.innerHTML = iteam.Issuer as string;
              }

              if (endDate !== null) {
                if (iteam.IsPursuing) {
                  endDate.innerHTML = "Present";
                } else {
                  endDate.innerHTML = GetFormattedDate(iteam.Date);
                }
              }

              if (summary !== null) {
                summary.innerHTML = iteam.Summary as string;
              }
              getTempElement[1].appendChild(copiedHtmlElement);
            }
          }
        );
      }
    })();
  }, [formik.values.Certification.CertificationDetails, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "awardTemplate",
        "awardTemplateInjectPoint"
      );
      if (getTempElement !== undefined) {
        formik.values.Awards.AwardsDetails?.map((iteam, index) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;

            let title = copiedHtmlElement.querySelector(".awardTitle");
            let awarder = copiedHtmlElement.querySelector(".awardAwarder");
            let date = copiedHtmlElement.querySelector(".awardDate");
            let summary = copiedHtmlElement.querySelector(".awardSummary");

            if (title !== null) {
              title.innerHTML = iteam.Title as string;
            }

            if (awarder !== null) {
              awarder.innerHTML = iteam.Awarder as string;
            }

            if (date !== null) {
              date.innerHTML = GetFormattedDate(iteam.Date);
            }

            if (summary !== null) {
              summary.innerHTML = iteam.Summary as string;
            }
            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.Awards.AwardsDetails, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "projectTemplate",
        "projectTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.Project?.ProjectsDetail?.map((iteam, index) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;

            let title = copiedHtmlElement.querySelector(".projectTitle");
            let website = copiedHtmlElement.querySelector(".projectWebsite");
            let startDate =
              copiedHtmlElement.querySelector(".projectStartDate");
            let endDate = copiedHtmlElement.querySelector(".projectEndDate");
            let summary = copiedHtmlElement.querySelector(".projectSummary");

            if (title !== null) {
              title.innerHTML = iteam.Title as string;
            }

            if (website !== null) {
              website.innerHTML = iteam.Website as string;
            }

            if (startDate !== null) {
              startDate.innerHTML = GetFormattedDate(iteam.StartDate);
            }

            if (endDate !== null) {
              if (iteam.IsYourCurrentProject) {
                endDate.innerHTML = "Present";
              } else {
                endDate.innerHTML = GetFormattedDate(iteam.EndDate);
              }
            }

            if (summary !== null) {
              summary.innerHTML = iteam.Summary as string;
            }
            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.Project.ProjectsDetail, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "languageTemplate",
        "languageTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.Language?.LanguageDetails?.map((iteam, index) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;

            let name = copiedHtmlElement.querySelector(".languageName");
            let level = copiedHtmlElement.querySelector(".languageLevel");

            if (name !== null) {
              name.innerHTML = iteam.Name as string;
            }

            if (level !== null) {
              level.innerHTML = iteam.Fluency as string;
            }

            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.Language.LanguageDetails, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "hobbyTemplate",
        "hobbyTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.Hobby?.Hobbies?.map((iteam, index) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;

            let name = copiedHtmlElement.querySelector(".hobbyName");

            if (name !== null) {
              name.innerHTML = iteam.Name as string;
            }

            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.Hobby.Hobbies, selectedTemplate]);

  React.useEffect(() => {
    (async () => {
      let getTempElement = getTemplateElement(
        "referenceTemplate",
        "referenceTemplateInjectPoint"
      );

      if (getTempElement !== undefined) {
        formik.values.Reference?.ReferencesDetail?.map((iteam, index) => {
          if (getTempElement !== undefined) {
            let copiedHtmlElement: HTMLElement =
              getTempElement[0].content.cloneNode(true) as HTMLElement;

            let name = copiedHtmlElement.querySelector(".referenceName");
            let email = copiedHtmlElement.querySelector(".referenceEmail");
            let mobile = copiedHtmlElement.querySelector(".referenceMobile");
            let position =
              copiedHtmlElement.querySelector(".referencePosition");
            let summary = copiedHtmlElement.querySelector(".referenceSummary");

            if (name !== null) {
              name.innerHTML = iteam.Name as string;
            }

            if (email !== null) {
              email.innerHTML = iteam.Email as string;
            }

            if (mobile !== null) {
              mobile.innerHTML = iteam.PoneNumber as string;
            }

            if (position !== null) {
              position.innerHTML = iteam.Position as string;
            }

            if (summary !== null) {
              summary.innerHTML = iteam.Summary as string;
            }

            getTempElement[1].appendChild(copiedHtmlElement);
          }
        });
      }
    })();
  }, [formik.values.Reference.ReferencesDetail, selectedTemplate]);

  const isArrayModelInErrorState = (touchedArray: any, errorArray: any) => {
    let isError = false;
    if (Array.isArray(touchedArray) && Array.isArray(errorArray)) {
      let touchedArrayLength = touchedArray.length - 1;
      for (let i = 0; i < errorArray.length; i++) {
        if (errorArray[i] != null || errorArray[i] != undefined) {
          let keys = Object.keys(errorArray[i]);
          if (touchedArrayLength <= i && touchedArray[i] != null) {
            for (let k of keys) {
              if (touchedArray[i][k]) {
                return true;
              }
            }
          }
        }
      }
    } else if (
      Array.isArray(touchedArray) &&
      touchedArray.length == 0 &&
      typeof formik.errors?.WorkExperience?.WorkHistory == "string"
    ) {
      return true;
    }
    return isError;
  };

  // if (formik.errors) 
  // {
  //   console.log(JSON.stringify(formik.errors));
  // }


  return (
    <Layout menuRoute={AuthoriseUri.Resume}>
      <Grid container alignContent="flex-start" justifyContent="center">
        <Grid item xs={12} md={5} xl={3}>
          <form onSubmit={formik.handleSubmit} ref={formRef} style={{ overflow: "auto" }}>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizedPersonalInfo.Header.isError ||
                      memoizedPersonalInfo.Name.isError ||
                      memoizedPersonalInfo.Email.isError ||
                      memoizedPersonalInfo.Mobile.isError ||
                      memoizedPersonalInfo.DOB.isError
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("PersonalInfo")}</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <PersonalInfo
                  Header={memoizedPersonalInfo.Header}
                  Address={memoizedPersonalInfo.Address}
                  DOB={memoizedPersonalInfo.DOB}
                  Email={memoizedPersonalInfo.Email}
                  Mobile={memoizedPersonalInfo.Mobile}
                  Name={memoizedPersonalInfo.Name}
                  Subtitle={memoizedPersonalInfo.Subtitle}
                  Website={memoizedPersonalInfo.Website}
                  onBlurHandler={memoizedPersonalInfo.onBlurHandler}
                  onChangeHandler={memoizedPersonalInfo.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizedPersonalInfo.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizedObjective.Header.isError ||
                      memoizedObjective.Summary.isError
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("Objective")}</Typography>
                <PanelSwitch
                  size="small"
                  sx={{ ml: "auto" }}
                  className="panelSwitch"
                  name="Objective.IsObjectiveThere"
                  checked={formik.values.Objective.IsObjectiveThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "objective",
                      !formik.values.Objective.IsObjectiveThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Objective
                  Header={memoizedObjective.Header}
                  Summary={memoizedObjective.Summary}
                  onBlurHandler={memoizedObjective.onBlurHandler}
                  onChangeHandler={memoizedObjective.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizedObjective.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizedSkill.Header.isError ||
                      Boolean(memoizedSkill.SkillsDetailError)
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("Skills")}</Typography>
                <PanelSwitch
                  name="Skill.IsSkillThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Skill.IsSkillThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "skill",
                      !formik.values.Skill.IsSkillThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Skill
                  Header={memoizedSkill.Header}
                  AddSkillHandler={memoizedSkill.AddSkillHandler}
                  RemoveSkillHandler={memoizedSkill.RemoveSkillHandler}
                  SkillsDetails={memoizedSkill.SkillsDetails}
                  SkillsDetailError={memoizedSkill.SkillsDetailError}
                  onBlurHandler={memoizedSkill.onBlurHandler}
                  onChangeHandler={memoizedSkill.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizedSkill.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizeWorkExperience.Header.isError ||
                      isArrayModelInErrorState(
                        memoizeWorkExperience.WorkHistoryTouched,
                        memoizeWorkExperience.WorkHistoryError
                      )
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("WorkExperience")}</Typography>
                <PanelSwitch
                  name="WorkExperience.IsWorkExperienceThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.WorkExperience.IsWorkExperienceThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "workExperience",
                      !formik.values.WorkExperience.IsWorkExperienceThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <WorkExperience
                  AddWorkHistrory={memoizeWorkExperience.AddWorkHistrory}
                  Header={memoizeWorkExperience.Header}
                  RemoveWorkHistory={memoizeWorkExperience.RemoveWorkHistory}
                  WorkHistory={memoizeWorkExperience.WorkHistory}
                  WorkHistoryError={memoizeWorkExperience.WorkHistoryError}
                  WorkHistoryTouched={memoizeWorkExperience.WorkHistoryTouched}
                  onBlurHandler={memoizeWorkExperience.onBlurHandler}
                  onChangeHandler={memoizeWorkExperience.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizeWorkExperience.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizeEducation.Header.isError ||
                      isArrayModelInErrorState(
                        memoizeEducation.EducationHistoryTouched,
                        memoizeEducation.EducationHistoryError
                      )
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("Education")}</Typography>
                <PanelSwitch
                  name="Education.IsEducationThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Education.IsEducationThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "education",
                      !formik.values.Education.IsEducationThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Education
                  Header={memoizeEducation.Header}
                  AddEducationHistrory={memoizeEducation.AddEducationHistrory}
                  EducationHistory={memoizeEducation.EducationHistory}
                  EducationHistoryError={memoizeEducation.EducationHistoryError}
                  EducationHistoryTouched={
                    memoizeEducation.EducationHistoryTouched
                  }
                  RemoveEducationHistory={
                    memoizeEducation.RemoveEducationHistory
                  }
                  onBlurHandler={memoizeEducation.onBlurHandler}
                  onChangeHandler={memoizeEducation.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizeEducation.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizeCertificate.Header.isError ||
                      isArrayModelInErrorState(
                        memoizeCertificate.CertificationHistoryTouched,
                        memoizeCertificate.CertificationHistoryError
                      )
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("Certifications")}</Typography>
                <PanelSwitch
                  name="Certification.IsCertificationThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Certification.IsCertificationThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "certification",
                      !formik.values.Certification.IsCertificationThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Certification
                  AddCertificationHistrory={
                    memoizeCertificate.AddCertificationHistrory
                  }
                  CertificationHistory={memoizeCertificate.CertificationHistory}
                  CertificationHistoryError={
                    memoizeCertificate.CertificationHistoryError
                  }
                  CertificationHistoryTouched={
                    memoizeCertificate.CertificationHistoryTouched
                  }
                  Header={memoizeCertificate.Header}
                  RemoveCertificationHistory={
                    memoizeCertificate.RemoveCertificationHistory
                  }
                  onBlurHandler={memoizeCertificate.onBlurHandler}
                  onChangeHandler={memoizeCertificate.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizeCertificate.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizeAward.Header.isError ||
                      isArrayModelInErrorState(
                        memoizeAward.AwardHistoryTouched,
                        memoizeAward.AwardHistoryError
                      )
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("Awards")}</Typography>
                <PanelSwitch
                  name="Awards.IsAwardThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Awards.IsAwardThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "award",
                      !formik.values.Awards.IsAwardThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Award
                  AddAwardHistrory={memoizeAward.AddAwardHistrory}
                  AwardHistory={memoizeAward.AwardHistory}
                  AwardHistoryError={memoizeAward.AwardHistoryError}
                  AwardHistoryTouched={memoizeAward.AwardHistoryTouched}
                  Header={memoizeAward.Header}
                  RemoveAwardHistory={memoizeAward.RemoveAwardHistory}
                  onBlurHandler={memoizeAward.onBlurHandler}
                  onChangeHandler={memoizeAward.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizeAward.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                aria-controls="panel8d-content"
                id="panel8d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizeProject.Header.isError ||
                      isArrayModelInErrorState(
                        memoizeProject.ProjectHistoryTouched,
                        memoizeProject.ProjectHistoryError
                      )
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("Projects")}</Typography>
                <PanelSwitch
                  name="Project.IsProjectThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Project.IsProjectThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "project",
                      !formik.values.Project.IsProjectThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Project
                  AddProjectHistrory={memoizeProject.AddProjectHistrory}
                  Header={memoizeProject.Header}
                  ProjectHistory={memoizeProject.ProjectHistory}
                  ProjectHistoryError={memoizeProject.ProjectHistoryError}
                  ProjectHistoryTouched={memoizeProject.ProjectHistoryTouched}
                  RemoveProjectHistory={memoizeProject.RemoveProjectHistory}
                  onBlurHandler={memoizeProject.onBlurHandler}
                  onChangeHandler={memoizeProject.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizeProject.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                aria-controls="panel9d-content"
                id="panel9d-header"
                className="panel_content"
                sx={{
                  background:
                    formik.touched.Language &&
                    formik.errors.Language &&
                    "linear-gradient(45deg, #f3212b 30%, #f35151 90%)",
                }}
              >
                <Typography>{t("Languages")}</Typography>
                <PanelSwitch
                  name="Language.IsLanguageThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Language.IsLanguageThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "language",
                      !formik.values.Language.IsLanguageThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Language
                  AddLanguageHandler={memoizedLanguage.AddLanguageHandler}
                  Header={memoizedLanguage.Header}
                  LanguagesDetail={memoizedLanguage.LanguagesDetail}
                  LanguagesDetailError={memoizedLanguage.LanguagesDetailError}
                  RemoveLanguageHandler={memoizedLanguage.RemoveLanguageHandler}
                  onBlurHandler={memoizedLanguage.onBlurHandler}
                  onChangeHandler={memoizedLanguage.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizedLanguage.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel10"}
              onChange={handleChange("panel10")}
            >
              <AccordionSummary
                aria-controls="panel10d-content"
                id="panel10d-header"
                className="panel_content"
                sx={{
                  background:
                    formik.touched.Hobby &&
                    formik.errors.Hobby &&
                    "linear-gradient(45deg, #f3212b 30%, #f35151 90%)",
                }}
              >
                <Typography>{t("Hobbies")}</Typography>
                <PanelSwitch
                  name="Hobby.IsHobbyThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Hobby.IsHobbyThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "hobby",
                      !formik.values.Hobby.IsHobbyThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Hobby
                  AddHobbyHandler={memoizedHobby.AddHobbyHandler}
                  Header={memoizedHobby.Header}
                  HobbiesDetail={memoizedHobby.HobbiesDetail}
                  RemoveHobbyHandler={memoizedHobby.RemoveHobbyHandler}
                  onBlurHandler={memoizedHobby.onBlurHandler}
                  onChangeHandler={memoizedHobby.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizedHobby.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="panel_main"
              expanded={expanded === "panel11"}
              onChange={handleChange("panel11")}
            >
              <AccordionSummary
                aria-controls="panel11d-content"
                id="panel11d-header"
                className="panel_content"
                sx={{
                  background:
                    memoizeReference.Header.isError ||
                      isArrayModelInErrorState(
                        memoizeReference.ReferenceHistoryTouched,
                        memoizeReference.ReferenceHistoryError
                      )
                      ? "linear-gradient(45deg, #f3212b 30%, #f35151 90%)"
                      : "",
                }}
              >
                <Typography>{t("References")}</Typography>
                <PanelSwitch
                  name="Reference.IsReferenceThere"
                  size="small"
                  sx={{ ml: "auto" }}
                  checked={formik.values.Reference.IsReferenceThere}
                  onChange={(e) => {
                    setResumeElementVisibility(
                      "reference",
                      !formik.values.Reference.IsReferenceThere
                    );
                    formik.handleChange(e);
                  }}
                />
              </AccordionSummary>
              <AccordionDetails className="accordion_expanded">
                <Reference
                  AddReferenceHistrory={memoizeReference.AddReferenceHistrory}
                  Header={memoizeReference.Header}
                  ReferenceHistory={memoizeReference.ReferenceHistory}
                  ReferenceHistoryError={memoizeReference.ReferenceHistoryError}
                  ReferenceHistoryTouched={
                    memoizeReference.ReferenceHistoryTouched
                  }
                  RemoveReferenceHistory={
                    memoizeReference.RemoveReferenceHistory
                  }
                  onBlurHandler={memoizeReference.onBlurHandler}
                  onChangeHandler={memoizeReference.onChangeHandler}
                  SetValueByPropNameHandler={
                    memoizeReference.SetValueByPropNameHandler
                  }
                />
              </AccordionDetails>
            </Accordion>
          </form>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          xl={6}
          sx={{ padding: "10px", overflow: "auto", pt: 0 }}
          alignItems="center"
        >
          <Box component="div" id="test" className="preview">
            <Box
              component="div"
              id="resumeForDownload"
              sx={{
                backgroundColor: "white",
                width: "210mm",
                minHeight: "297mm",
              }}
              ref={componentRef}
            >
              {arrayOfDeltaPosition.map((item, index) => {
                if (index > 0) {
                  let beforeItemPosition = arrayOfDeltaPosition[index - 1].y;
                  let isError = false;
                  let errorMessage = "";
                  let pageLength = item.y - beforeItemPosition;
                  if (pageLength > defaultPageLenght || pageLength < 70) {
                    isError = true;
                    errorMessage = "Again Same Error";
                  }

                  return (
                    <Draggable
                      bounds="parent"
                      axis="y"
                      onDrag={dragHandler(index)}
                      position={{
                        x: item.x,
                        y: item.y,
                      }}
                      key={index}
                    >
                      <Tooltip
                        open={true}
                        placement="top"
                        arrow
                        title={t("PageBreakInfo") as string}
                      >
                        <div className="box draggableDiv">
                          <hr
                            className={
                              isError ? "hrPageBreakError" : "hrPageBreak"
                            }
                          />
                          {/* y=
                          {Math.ceil(item.y * 0.2645833333) +
                            resumeTopPadding +
                            resumeBottomPadding}
                          mm
                          {item.y + resumeTopPadding + resumeBottomPadding}
                          {isError}
                          {errorMessage} */}
                          &nbsp;
                        </div>
                      </Tooltip>
                    </Draggable>
                  );
                }
              })}
              {<div id="container" dangerouslySetInnerHTML={selectedTemplate}></div>}
            </Box>
          </Box>
        </Grid>
        <Grid item>
          {/* <FloatButton.Group
          icon={<PlusOutlined/>}
          shape="circle"
          trigger="click"
          >
            <FloatButton 
            icon={<FileTextFilled/>}
            tooltip="More Template"
            shape="circle"
            onClick={() => setSwipeableDrawerOpenFlag(true)}
            />

            <FloatButton 
            icon={<VerticalAlignBottomOutlined/>}
            tooltip="Download"
            shape="circle"
            onClick={
              (e) => {
                setStartDownload(true);
                formik.handleSubmit();
              } 
            }
            />

            <FloatButton 
            icon={<SaveFilled/>}
            tooltip="Save"
            shape="circle"
            onClick={() => formik.handleSubmit()}
            />
          </FloatButton.Group> */}

          <Tooltip arrow placement="left" title={t("MoreTemplate") as string}>
            <Fab
              size="small"
              sx={{
                position: "fixed",
                bottom: 144,
                right: 16,
                color: "#fff",
                backgroundColor: "#51A2BD", // Set background color using style prop
              }}
              onClick={() => setSwipeableDrawerOpenFlag(true)}
            >
              <DescriptionIcon />
            </Fab>
          </Tooltip>

          <Tooltip arrow placement="left" title={t("Download") as string}>
            <Fab
              size="small"
              sx={{ position: "fixed", bottom: 80, right: 16, color: "#fff", backgroundColor: "#51A2BD", }}
              onClick={
                (e) => {
                  setStartDownload(true);
                  formik.handleSubmit();
                } /*DownloadPdf()*/
              }
            >
              <CloudDownload />
            </Fab>
          </Tooltip>

          <Tooltip arrow placement="left" title={t("Save") as string}>
            <Fab
              size="small"
              sx={{ position: "fixed", bottom: 16, right: 16, color: "#fff", backgroundColor: "#51A2BD", }}
              onClick={() => formik.handleSubmit()}
            >
              <Save />
            </Fab>
          </Tooltip>
        </Grid>
        <Grid item>
          <SwipeableDrawer
            anchor="right"
            open={swipeableDrawerOpenFlag}
            onClose={() => setSwipeableDrawerOpenFlag(false)}
            onOpen={() => setSwipeableDrawerOpenFlag(true)}
            PaperProps={{
              sx: { width: { md: "20%", xs: "100%" } },
            }}
          >
            <Hidden mdUp={true}>
              <CssBaseline />
              <AppBar>
                <Toolbar variant="dense">
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={() => setSwipeableDrawerOpenFlag(false)}
                  >
                    <ArrowBackIosIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
            </Hidden>
            <ResumeTemplateView
              Templates={alltemplats}
              TemplateSelecteHandler={(id: string) => {
                selectTemplate(id);
                setSwipeableDrawerOpenFlag(false);
              }}
            />
          </SwipeableDrawer>
        </Grid>
      </Grid>
    </Layout>
  );
}



export default CreateBuilder;
