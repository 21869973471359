import React, { useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import { GoogleLoginButton } from "react-social-login-buttons";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { LoginModel, LoginValidationSchema } from "../model/LoginModel";
import { AuthoriseUri, UnauthoriseUri } from "../Utility/PageUrls";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import {useLocation} from "react-router-dom";
import LoginMainImage from '../images/LoginMain.svg';
import MagicCVLogo from '../images/Magic CV.svg';
import '../myStyles/loginPage.css';
import { Block } from "@mui/icons-material";
import { fontWeight } from "html2canvas/dist/types/css/property-descriptors/font-weight";
<link
  href="https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap"
  rel="stylesheet"
/>
function Login() {
  const authContext = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);
  const [apiError, setApiError] = useState("");
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  let model: LoginModel = { email: "", password: "" };

  const afterLoginHandler = (
    isError: boolean,
    message: string,
    response: {}
  ): void => {
    if (isError) {
      setApiError(t("InvalidLogincodeAndPassword"));
    } else {
      authContext.addSessionUser(response);
      history.push(AuthoriseUri.Dashboard);
    }
  };

  const afterGoogleLoginHandler = (
    isError: boolean,
    message: string,
    response: {}
  ) => {
    if (isError) {
      setApiError(message);
    } else {
      authContext.addSessionUser(response);
      history.push(AuthoriseUri.Dashboard);
    }
  };

  const googleLoginHandler = () => {
    authContext.googleLoginHandler(afterGoogleLoginHandler);
  };

  const formik = useFormik({
    initialValues: model,
    validationSchema: LoginValidationSchema,
    onSubmit: (values) => {
      setApiError("");
      authContext.loginHandler(
        values.email,
        values.password,
        afterLoginHandler
      );
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const responseGoogle = (response: any) => {
    console.log(response);
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{ }}
    >
      <Grid container full-height item md={12} sm={12} display="block">
        <div className="loginMainContainer">
        <Grid item md={6} sm={6} className="col col-12 col-md-6 right">
          <Hidden smDown>
          <div className="inner text-center">
            <div className="right_image ds-vertical-align" style={{ position: 'relative', alignItems: 'center' }}>
              <img className=" login_right" src={LoginMainImage} alt="not found" />
            </div>
          </div>
          </Hidden>
        </Grid>
        <Grid item sm={6} md={6} sx={{ px: 4, col: 12 }} className="col col-12 col-md-6 left ds-vertical-align">
        <div className="inner">
          <div className='MagicCVlogo'>
            <a href='#' target="_blank">
                <img src={MagicCVLogo} className='MainLogo' alt='Magic CV Logo'></img>
            </a>
          </div>
          <div className='welcometext'>
            <h3>Welcome back !</h3>
            <span className='plainText'>New to Magic CV ?</span><span className='LinkText'>
            <Button
                className="plainText"
                variant="text"
                sx={{ marginLeft: "auto !important", color: "#51A2BD", fontWeight: "600", textTransform: "inherit" }}
                onClick={(e) => history.push(UnauthoriseUri.Signup)}
              >
                {t("Create an account")}
              </Button>
            </span>
          </div>
              <form id="login-form" onSubmit={formik.handleSubmit}>
                {apiError != "" && (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    {apiError}
                  </Alert>
                )}

                <FormGroup row={true} sx={{ mb: 2 }}>
                <div className="form-group form_group_12" style={{ marginBottom: '5px' }}>
                <p>Email/Mobile</p>
                  <TextField
                    id="loginCode"
                    size="small"
                    fullWidth={true}
                    name="email"
                    className="form-control"
                    placeholder="Enter Email ID or Mobile Number"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={
                      formik.touched.email && t(formik.errors.email as string)
                    }
                  />
                  </div>  
                </FormGroup>
                <FormGroup row={true} sx={{ mb: 2 }} style={{marginBottom: "0"}}>
                <div className="form-group form_group_12" id="PW" >
                <p style={{marginTop: "0"}}>Password</p>
                  <TextField
                    id="password"
                    size="small"
                    fullWidth={true}
                    className="form-control"
                  placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password &&
                      t(formik.errors.password as string)
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  </div>
                </FormGroup>
                <div className="col col-12 col-lg-12 text-right" style={{ textAlign: 'right' }}>
                  <Button
                variant="text"
                className="forgot_link"
                onClick={(e) => history.push(UnauthoriseUri.ForgotPassword)}
              >
                {t("Forgot Password ?")}
              </Button>
            </div>
                <FormGroup row={true} sx={{ mb: 2 }}>
                  <Button variant="contained" fullWidth={true} type="submit">
                    {t("Login")}
                  </Button>
                </FormGroup>
              </form>
              {/* <Divider orientation="horizontal" flexItem sx={{ my: 2 }}>
                {t("Or")}
              </Divider> */}
              <FormGroup>
                <FormGroup row={true} style={{ border: "1px solid #51A2BD", borderRadius: "5px"}}>
                  <GoogleLoginButton
                    className="google_btn"
                    onClick={googleLoginHandler}
                    style={{ margin: "0px", fontWeight: "600", fontSize : "16px", textAlign :"center" }}
                    
                  />
                </FormGroup>
              </FormGroup>
            </div>
        </Grid>
        </div>
      </Grid>
    </Grid>
  );
}

export default Login;
