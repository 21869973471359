import React from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Switch,
  Alert,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from "@mui/material";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import DateAdapter from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from "@mui/icons-material/Close";
import SummaryEditor from "./SummaryEditor";
import '../myStyles/inputInformation.css';
import { Padding } from "@mui/icons-material";


const WorkExperience:React.FC<Type.IWorkExperience>=(props)=> {
    const { t } = useTranslation();
  console.log("WorkExperience");

  const getErrorString =(index:number,propsName:string):string=>{
    if (
      Array.isArray(props.WorkHistoryError) &&
      props.WorkHistoryError[index] !== null &&
      props.WorkHistoryError[index] !== undefined &&
      props.WorkHistoryError[index].hasOwnProperty(propsName)
    ) 
    {
      return t(props.WorkHistoryError[index][propsName]);

    }
    return "";
  }

  const getTouchedValue = (index: number, propsName: string): boolean => {
    if (
      Array.isArray(props.WorkHistoryTouched) &&
      props.WorkHistoryTouched[index] !== null &&
      props.WorkHistoryTouched[index] !== undefined &&
      props.WorkHistoryTouched[index].hasOwnProperty(propsName)
    ) {
      return props.WorkHistoryTouched[index][propsName];
    }
    return false;
  };
  
  const noWorkHistoryError = (Array.isArray(props.WorkHistoryTouched) &&
    props.WorkHistoryTouched.length == 0 && typeof(props.WorkHistoryError) == "string" &&
     props.WorkHistoryError != undefined) ? (
      <Alert severity="error" sx={{mt:1}}>{t(props.WorkHistoryError)}</Alert>
    ):<span></span>;


  

  const customToolbar = {
    options: ["inline", "list"],
    inline: {
      options: ["bold", "italic", "underline"],
    },

    list: {
      options: ["unordered", "ordered"],
    },
  };

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="work_div_main">
          <TextField
            id="WorkExperience_Header"
            label={t("Header")}
            className='resume_input'
            variant="outlined"
            size="small"
            fullWidth
            name="WorkExperience.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            onBlur={props.onBlurHandler}
            error={props.Header.isError}
            helperText={props.Header.isError}
            required
          />

          {noWorkHistoryError}
        </Grid>

        {props.WorkHistory?.map((w, i) => {
          let companyNameError =
            getTouchedValue(i, "Company") && getErrorString(i, "Company");
          let positionError =
            getTouchedValue(i, "Position") && getErrorString(i, "Position");
          let endDateError =
            getTouchedValue(i, "EndDate") && getErrorString(i, "EndDate");
          let startDateError =
            getTouchedValue(i, "StartDate") && getErrorString(i, "StartDate");  
          return (
            <React.Fragment key={i}>
              <Grid item xs={12}>
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={(e) => props.RemoveWorkHistory(i)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    sx={{ pb: 0, pt: 1 }}
                  />
                  <CardContent sx={{ pt: "1px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                        className="work_div resume_input"
                          label={t("CompanyName")}
                          variant="outlined"
                          size="small"
                          fullWidth
                          name={`WorkExperience.WorkHistory[${i}].Company`}
                          value={w.Company}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={companyNameError}
                          error={Boolean(companyNameError)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                        className="work_div resume_input"
                          label={t("Position")}
                          variant="outlined"
                          size="small"
                          fullWidth
                          name={`WorkExperience.WorkHistory[${i}].Position`}
                          value={w.Position}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={positionError}
                          error={Boolean(positionError)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                        className="work_div resume_input"
                          id="WorkExperience_Company"
                          label={t("Website")}
                          variant="outlined"
                          size="small"
                          fullWidth
                          name={`WorkExperience.WorkHistory[${i}].Website`}
                          value={w.Website}
                          onChange={props.onChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              name={`WorkExperience.WorkHistory[${i}].CurrentJob`}
                              checked={w.CurrentJob}
                              onChange={props.onChangeHandler}
                            />
                          }
                          label={t("CurrentWorking")}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className="dates_work">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={t("JoiningDate")}
                            format="dd/MM/yyyy"
                            className='resume_input DOB'
                            value={w.StartDate}
                            onChange={(newValue: any) => {
                              props.SetValueByPropNameHandler(
                                `WorkExperience.WorkHistory[${i}].StartDate`,
                                newValue
                              );
                            }}
                            // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                            //   <TextField
                            //     {...params}
                            //     size="small"
                            //     fullWidth
                            //     error={Boolean(startDateError)}
                            //     helperText={startDateError}
                            //     required
                            //   />
                            // )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} className="dates_work">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={t("LeavingDate")}
                            format="dd/MM/yyyy"
                            className='resume_input DOB'
                            value={w.EndDate}
                            disabled={w.CurrentJob}
                            onChange={(newValue: any) => {
                              props.SetValueByPropNameHandler(
                                `WorkExperience.WorkHistory[${i}].EndDate`,
                                newValue
                              );
                            }}
                            slotProps={{ textField: { variant: 'outlined' } }}
                            // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                            //   <TextField
                            //     {...params}
                            //     size="small"
                            //     fullWidth
                            //     error={Boolean(endDateError)}
                            //     helperText={endDateError}
                            //   />
                            // )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <SummaryEditor
                          Summary={w.Summary}
                          ParentArrayLenght={props.WorkHistory?.length}
                          CallbackFun={(newValue: any) => {
                            props.SetValueByPropNameHandler(
                              `WorkExperience.WorkHistory[${i}].Summary`,
                              newValue
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          );
        })}

        <Grid item xs={12} className="work_btn important-padding" style={{ paddingTop: '0px !important' }}>
          <Button variant="contained" fullWidth onClick={props.AddWorkHistrory} className="btn_work">
            {t("AddMoreWorkHistory")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default React.memo(WorkExperience);
