import React, { useState, useContext } from "react";
import {
  ResetPasswordModel,
  ResetPasswordSchema,
} from "../model/ResetPasswordModel";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { AuthContext } from "../context/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import { UnauthoriseUri } from "../Utility/PageUrls";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const search = useLocation().search;
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordSuccessfulReset, setIsPasswordSuccessfulReset] =
    useState(false);
  const [apiError, setApiError] = useState("");
  const { t } = useTranslation();
  let model: ResetPasswordModel = { password: "", passwordConfirmation: "" };

  const oobCode = new URLSearchParams(search).get("oobCode");

  const afterResetPasswordHandler = (
    isError: boolean,
    message: string
  ): void => {
    if (isError) {
      setApiError(message);
    } else {
      setIsPasswordSuccessfulReset(true);
    }
  };

  const formik = useFormik({
    initialValues: model,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values) => {
      setApiError("");
      if (oobCode != null) {
        authContext.confirmPasswordReset(
          oobCode,
          values.password,
          afterResetPasswordHandler
        );
      }
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  if (oobCode==null)
  {
    history.push(UnauthoriseUri.Login)
  }
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ minHeight: "100vh", backgroundColor: "#EAEEF3" }}
      >
        <Grid container item md={8} sm={12}>
          <Grid item md={6}>
            <Hidden mdDown>
              <Typography variant="h3" sx={{ color: "primary.main" }}>
                Create your resume.
              </Typography>
              <Typography variant="h5">
                Our professionals continue looking industry trends and create
                best resume template for you.
              </Typography>
            </Hidden>
          </Grid>
          <Grid item sm={12} md={6} sx={{ px: 4 }}>
            <Hidden mdUp>
              <Typography variant="h3" sx={{ color: "primary.main" }}>
                Create your resume.
              </Typography>
              <Typography variant="h5">
                Our professionals continue looking industry trends and create
                best resume template for you.
              </Typography>
            </Hidden>
            <Card raised={true}>
              <CardContent>
                <form onSubmit={formik.handleSubmit}>
                  {apiError !== "" && (
                    <Alert severity="error" sx={{ mb: 3 }}>
                      {apiError}
                    </Alert>
                  )}

                  {isPasswordSuccessfulReset == true ? (
                    <FormGroup row={true} sx={{ mb: 2 }}>
                      <Alert severity="success">
                        {t("PasswordResetSuccessfulMsg")}
                      </Alert>
                    </FormGroup>
                  ) : (
                    <Box>
                      <FormGroup row={true} sx={{ mb: 2 }}>
                        <TextField
                          label={t("Password")}
                          id="password"
                          size="small"
                          fullWidth={true}
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          helperText={
                            formik.touched.password && t(formik.errors.password as string)
                          }
                        />
                      </FormGroup>

                      <FormGroup row={true} sx={{ mb: 2 }}>
                        <TextField
                          label={t("ConfirmPassword")}
                          id="password"
                          size="small"
                          fullWidth={true}
                          name="passwordConfirmation"
                          type={showPassword ? "text" : "password"}
                          value={formik.values.passwordConfirmation}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.passwordConfirmation &&
                            Boolean(formik.errors.passwordConfirmation)
                          }
                          helperText={
                            formik.touched.passwordConfirmation &&
                            t(formik.errors.passwordConfirmation as string)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormGroup>

                      <FormGroup row={true} sx={{ mb: 2 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth={true}
                        >
                          {t('Submit')}
                        </Button>
                      </FormGroup>
                    </Box>
                  )}
                </form>
                <Divider
                  orientation="horizontal"
                  flexItem
                  sx={{ my: 2 }}
                ></Divider>
                <FormGroup>
                  <Button
                    variant="contained"
                    color="success"
                    fullWidth={true}
                    onClick={(e) => history.push(UnauthoriseUri.Login)}
                  >
                    Go to Login page
                  </Button>
                </FormGroup>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    );
}

export default ResetPassword;
