import React, { useEffect, useState } from "react";
import { Navbar, Nav, Carousel } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import MagicCVLogo from '../images/Magic CV.svg';
import SliderImage1 from '../images/sliderimg1.svg';
import videoDemo from '../images/Group 464.png';
import '../myStyles/Landing_page.css';
import {NavLink, useHistory} from "react-router-dom";
import { getFirestore, collection, addDoc, getDocs, limit, orderBy, query } from 'firebase/firestore/lite';
import fire from '../service/firebase';
import { Button } from "@mui/material";
import { UnauthoriseUri } from "../Utility/PageUrls";

const db = getFirestore(fire);




const NavigationBar = () => {
  const history = useHistory();
  return (
    <Navbar collapseOnSelect expand="lg" bg="light" variant="light" style={{ padding: '10px 20px' }}>
      <Navbar.Brand href="/"><img src={MagicCVLogo} className='MainLogo' alt='Magic CV Logo' /></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" style={{ justifyContent: 'flex-end' }}>
        <Nav className="ml-auto header-btn">
        <Button
                className="loginBTn"
                variant="text"
                sx={{ marginLeft: "auto !important", color: "#51A2BD", fontWeight: "600", textTransform: "inherit" }}
                onClick={(e) => history.push(UnauthoriseUri.Login)}
              >
                Login
              </Button>
        {/* <NavLink to="/Login" href="#" className="loginBTn">Login</NavLink> */}
        <Button
                className="getStarted"
                variant="text"
                sx={{ marginLeft: "auto !important", color: "#51A2BD", fontWeight: "600", textTransform: "inherit" }}
                onClick={(e) => history.push(UnauthoriseUri.Signup)}
              >
                Get Started
              </Button>
        {/* <NavLink to="/Signup" className="getStarted">Get Started</NavLink> */}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

const ImageSlider = () => {
  const [newSlider1Heading, setNewSlider1Heading] = useState('');
  const [newSlider2Heading, setNewSlider2Heading] = useState('');
  const [newSlider3Heading, setNewSlider3Heading] = useState('');
  const [slider1Content, setSlider1Content] = useState('');
  const [slider2Content, setSlider2Content] = useState('');
  const [slider3Content, setSlider3Content] = useState('');

  useEffect(() => {
    const fetchLatestVideoData = async () => {
      try {
        const db = getFirestore(); // Initialize Firestore
        const landingPageContentCollectionRef = collection(db, 'Landing Page Content');
        const q = query(landingPageContentCollectionRef, orderBy('updatedDate', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const sliderData = doc.data();
            setNewSlider1Heading(sliderData.slider1Heading);
            setNewSlider2Heading(sliderData.slider2Heading);
            setNewSlider3Heading(sliderData.slider3Heading);
            setSlider1Content(sliderData.slider1Content);
            setSlider2Content(sliderData.slider2Content);
            setSlider3Content(sliderData.slider3Content);
          });
        } else {
          setNewSlider1Heading('');
          setNewSlider2Heading(''); 
          setNewSlider3Heading('');
          setSlider1Content(''); 
          setSlider2Content('');
          setSlider3Content(''); 
        }
      } catch (error) {
        console.error('Error fetching latest video data:', error);
      }
    };

    fetchLatestVideoData();
  }, []);

  return (
    <Carousel>
      <Carousel.Item className="slider_item1">
        <div className="slider_main_1">
          <Carousel.Caption className="sliderText1">
            <h3>{newSlider1Heading}</h3>
            <p>{slider1Content} </p>
          </Carousel.Caption>
          <div className="right_img">
            <img
              className="d-block w-100"
              src={SliderImage1}
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item className="slider_item1">
        <div className="slider_main_1">
          <Carousel.Caption className="sliderText1">
            <h3>{newSlider2Heading}</h3>
            <p>{slider2Content} </p>
          </Carousel.Caption>
          <div className="right_img">
            <img
              className="d-block w-100"
              src={SliderImage1}
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item className="slider_item1">
        <div className="slider_main_1">
          <Carousel.Caption className="sliderText1">
            <h3>{newSlider3Heading}</h3>
            <p>{slider3Content} </p>
          </Carousel.Caption>
          <div className="right_img">
            <img
              className="d-block w-100"
              src={SliderImage1}
              alt="First slide"
            />
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};

const VideoSection = () => {
  const [videoLink, setVideoLink] = useState('');
  const [videoRightContent, setVideoRightContent] = useState('');

  useEffect(() => {
    const fetchLatestVideoData = async () => {
      try {
        const db = getFirestore(); // Initialize Firestore
        const landingPageContentCollectionRef = collection(db, 'Landing Page Content');
        const q = query(landingPageContentCollectionRef, orderBy('updatedDate', 'desc'), limit(1));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const videoData = doc.data();
            setVideoLink(videoData.videoLink);
            setVideoRightContent(videoData.video_right_content); // Assuming videoRightContent is a field in your Firestore document
          });
        } else {
          setVideoLink('');
          setVideoRightContent(''); // Set empty string for videoRightContent if no data found
        }
      } catch (error) {
        console.error('Error fetching latest video data:', error);
      }
    };

    fetchLatestVideoData();
  }, []);

  return (
    <div className="video_section">
      <div className="video_main">
        <div className="video_left">
          <iframe
            className="d-block w-100"
            width="560"
            height="315"
            src={videoLink}
            title="YouTube video player"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="video_right">
          <h3 className="template_head">About Magic CV</h3>
          <p style={{ marginTop: '20px' }}>{videoRightContent}</p>
          <a className="btn btn-primary learn_more" href="https://www.vntechsolution.com/" target="_blank">Learn More</a>
        </div>
      </div>
    </div>
  );
};

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    contactDate: null, // Initialize contactDate to null
  });

  const [formStatus, setFormStatus] = useState({
    submitted: false,
    success: false,
    errorMessage: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

     // Set contactDate to the current date
     const currentDate = new Date();

    // Basic form validation
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.message) {
      setFormStatus({
        submitted: true,
        success: false,
        errorMessage: 'Please fill in all required fields.',
      });
      return;
    }

    // Email pattern validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      setFormStatus({
        submitted: true,
        success: false,
        errorMessage: 'Please enter a valid email address.',
      });
      return;
    }

    try {
      // Save form data to Firestore
      const contactFormCollection = collection(db, 'ContactForm');
      await addDoc(contactFormCollection, { ...formData, contactDate: currentDate });

      // Set success message
      setFormStatus({ submitted: true, success: true, errorMessage: '' });

      // Reset the form after submission
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
        contactDate: null,
      });

      console.log('Form data submitted to Firestore:', formData);
    } catch (error) {
      // Set error message
      setFormStatus({
        submitted: true,
        success: false,
        errorMessage: 'Error in data. Contact admin.',
      });

      console.error('Error submitting form data to Firestore:', error);
    }
  };

  return (
    <div className="contactMain">
      <div className="contactHead">
        <h3 className="Contact_head">Contact Us</h3>
      </div>
      <div className="contactForm ">
        <form className="landing_Contact container" onSubmit={handleSubmit}>
          <div className="nameRow">
            <input
              type="text"
              className="firstName"
              name="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={handleInputChange}
              
            />
            <input
              type="text"
              className="lastName"
              name="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={handleInputChange}
              
            />
          </div>

          <div className="emailRow">
            <input
              type="email"
              name="email"
              className="EmailID"
              placeholder="Email ID"
              value={formData.email}
              onChange={handleInputChange}
              
            />
          </div>

          <div className="messageRow">
            <textarea
              className="messageInput"
              name="message"
              rows={4}
              placeholder="Enter your message"
              value={formData.message}
              onChange={handleInputChange}
              
            ></textarea>
          </div>

          {/* Response Message */}
          {formStatus.submitted && (
            <div className={`responseMessage ${formStatus.success ? 'success' : 'error'}`}>
              {formStatus.success ? 'Thank you! We will contact you soon.' : formStatus.errorMessage}
            </div>
          )}

          <div className="buttonRow">
            <button className="submitBtn" type="submit">
              Contact Us
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const LandingPageFooter = () => {
  return (
    <footer className="footermain">
      <div className="footer_main">
        <p>Copyright © 2023-2024 <a href="https://www.vntechsolution.com/" target="_blank">VN Tech Solutions</a> All Rights Reserved</p>
      </div>
    </footer>
  );
}


const LandingPage = () => {
  return (
    <div>
      <NavigationBar />
      <ImageSlider />
      <VideoSection />
      <ContactUs />
      <LandingPageFooter />
    </div>
  );
}

export default LandingPage;
