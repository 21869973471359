import React from 'react'
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Box, FormHelperText } from "@mui/material";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { convertFromHTML, convertToHTML } from "draft-convert";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../myStyles/editor.css";

const SummaryEditor: React.FC<Type.ISummaryEditor> = (props) => {
  const result = convertFromHTML(props.Summary as string);
   const [editorVal, setEditorVal] = React.useState(() =>
     EditorState.createWithContent(result)
   );

   React.useEffect(() => {
     //re-initialise state when array lenght change
      let result = convertFromHTML(props.Summary as string);
      let val = EditorState.createWithContent(result);
      setEditorVal(val);
   }, [props.ParentArrayLenght]);
  const customToolbar = {
    options: ["inline", "list"],
    inline: {
      options: ["bold", "italic", "underline"],
    },

    list: {
      options: ["unordered", "ordered"],
    },
  };
  const onEditorStateChange = (editorState: any) => {
    props.CallbackFun(convertToHTML(editorState.getCurrentContent()));

    setEditorVal(editorState);
  };

  return (
    <React.Fragment>
      <Box sx={{ height: "200px" }}>
        <Editor
          editorState={editorVal}
          editorClassName="editor-class"
          toolbar={customToolbar}
          onEditorStateChange={onEditorStateChange}
        />
      </Box>
      {Boolean(props.ErrorMessage) && (
        <FormHelperText error={true}>{props.ErrorMessage}</FormHelperText>
      )}
    </React.Fragment>
  );
};

export default SummaryEditor;
