import * as yup from "yup";
import "yup-phone";

export const SocialNetworkSchema = yup.object({
  Natwork: yup.string(),
  UserName: yup.string(),
  Url: yup.string(),
});

export type SocialNetworkModel = yup.InferType<typeof SocialNetworkSchema>;

export const WorkExperienceSchema = yup.object({
  Company: yup.string(),
  Position: yup.string(),
  Website: yup.string(),
  StartDate: yup.date().default(function () {
    return new Date();
  }),
  EndDate: yup.date().default(function () {
    return new Date();
  }),
  Summary: yup.string(),
  CurrentJob: yup.boolean(),
});

export type WorkExperienceModel = yup.InferType<typeof WorkExperienceSchema>;

export const EducationSchema = yup.object({
  Institution: yup.string(),
  FieldOfStudy: yup.string(),
  TypeOfDegree: yup.string(),
  MarkInPercentage: yup.string(),
  IsPursuing: yup.boolean(),
  StartDate: yup.date().default(function () {
    return new Date();
  }),
  EndDate: yup.date().default(function () {
    return new Date();
  }),
  Summary: yup.string(),
});

export type EducationModel = yup.InferType<typeof EducationSchema>;

export const ProjectSchema = yup.object({
  Title: yup.string(),
  Website: yup.string(),
  IsYourCurrentProject: yup.boolean(),
  StartDate: yup.date().default(() => new Date()),
  EndDate: yup.date().default(() => new Date()),
  Summary: yup.string(),
});

export type ProjectModel = yup.InferType<typeof ProjectSchema>;

export const AwardSchema = yup.object({
  Title: yup.string(),
  Awarder: yup.string(),
  Date: yup.date().default(() => new Date()),
  Summary: yup.string(),
});

export type AwardModel = yup.InferType<typeof AwardSchema>;

export const CertificationSchema = yup.object({
  Title: yup.string(),
  Issuer: yup.string(),
  IsPursuing: yup.boolean(),
  Date: yup.date().default(() => new Date()),
  Summary: yup.string(),
});

export type CertificationModel = yup.InferType<typeof CertificationSchema>;

export const SkillSchema = yup.object({
  Name: yup.string().trim().required("Please specify skill."),
  Level: yup.string().trim(),
});

export type SkillModel = yup.InferType<typeof SkillSchema>;

export const HobbySchema = yup.object({
  Name: yup.string(),
});

export type HobbyModel = yup.InferType<typeof HobbySchema>;

export const LanguageSchema = yup.object({
  Name: yup.string(),
  Fluency: yup.string(),
});

export type LanguageModel = yup.InferType<typeof LanguageSchema>;

export const ReferenceSchema = yup.object({
  Name: yup.string(),
  Position: yup.string(),
  PoneNumber: yup.string(),
  Email: yup.string(),
  Summary: yup.string(),
});

export type ReferenceModel = yup.InferType<typeof ReferenceSchema>;

export const ResumeBuilderSchema = yup.object({
  UserId: yup.string().notRequired(),
  TemplateId: yup.string().notRequired(),
  PageBreakPoints: yup.mixed(),
  CreateDate: yup.date().notRequired(),
  ModifyDate: yup.date().notRequired(),
  Profile: yup
    .object({
      Header: yup.string().required("SpecifyHeader"),
      Name: yup.string().required("SpecifyName"),
      Subtitle: yup.string(),
      DateOfBirth: yup
        .date()
        .default(function () {
          return new Date();
        })
        .typeError("SpecifyValidDate")
        .required("SpecifyDOB"),
      Address: yup.string().required("SpecifyAddress"),
      PhoneNumber: yup
        .string()
        .test("onlyNumbers", "SpecifyVaildMobile", function (value) {
          return /^[0-9]*$/.test(value as string);
        })
        .required("SpecifyMobile"),
      /*.phone("", false, "SpecifyVaildMobile")*/
      Website: yup.string(),
      Email: yup
        .string()
        .email("SpecifyValidEmail")
        .required("SpecifyValidEmail"),
    })
    .notRequired(),
  SocialNetworks: yup.array().of(SocialNetworkSchema.notRequired()),
  Objective: yup
    .object({
      IsObjectiveThere: yup.boolean(),
      Header: yup.string().when("IsObjectiveThere", {
        is: true,
        then: yup.string().required("SpecifyHeader"),
      }),
      Summary: yup
        .string()
        .when("IsObjectiveThere", {
          is: true,
          then: yup.string().required("SpecifySummary"),
        })
        .test("empatyString", "SpecifySummary", function (value) {
          if (this.parent.IsObjectiveThere) {
            return value != "<p></p>";
          } else {
            return true;
          }
        }),
    })
    .notRequired(),
  WorkExperience: yup.object({
    IsWorkExperienceThere: yup.boolean(),
    Header: yup.string().when("IsWorkExperienceThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    WorkHistory: yup
      .array()
      .of(WorkExperienceSchema.notRequired())
      .when("IsWorkExperienceThere", {
        is: true,
        then: yup.array().of(
          WorkExperienceSchema.shape({
            Company: yup.string().required("SpecifyCompanyName"),
            Position: yup.string().required("SpecifyPosition"),
            StartDate: yup
              .date()
              .default(function () {
                return new Date();
              })
              .typeError("SpecifyValidDate")
              .required("SpecifyValidDate"),
            EndDate: yup
              .date()
              .default(function () {
                return new Date();
              })
              .typeError("SpecifyValidDate")
              .test("lessThanStartDate", "lessThanJoiningDate", function (val) {
                if(!this.parent.CurrentJob)
                {
                   return  this.parent.StartDate < val;
                }
                else
                {
                  return true;
                }
               
              }),
          }).notRequired()
        ),
      })
      .test("testWorkHistoryLength", "SpecifyWorkHistory", function (value) {
        let totalWorkExp = this.parent.WorkHistory
          ? this.parent.WorkHistory.length
          : 0;
        return !(this.parent.IsWorkExperienceThere && totalWorkExp == 0);
      }),
  }),
  Education: yup.object({
    IsEducationThere: yup.boolean(),
    Header: yup.string().when("IsEducationThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    AcedemyDetails: yup
      .array()
      .of(EducationSchema.notRequired())
      .when("IsEducationThere", {
        is: true,
        then: yup.array().of(
          EducationSchema.shape({
            Institution: yup.string().required("SpecifyInstitutionName"),
            FieldOfStudy: yup.string().required("SpecifyFieldOfStudy"),
            StartDate: yup
              .date()
              .default(function () {
                return new Date();
              })
              .typeError("SpecifyValidDate"),
            EndDate: yup
              .date()
              .default(function () {
                return new Date();
              })
              .typeError("SpecifyValidDate")
              .test(
                "EduLessThanStartDate",
                "lessThanEduStartDate",
                function (val) {
                  if (!this.parent.IsPursuing) {
                    return this.parent.StartDate < val;
                  } else {
                    return true;
                  }
                }
              ),
          }).notRequired()
        ),
      })
      .test("testAcedemyLength", "SpecifyAcedemy", function (value) {
        let totalAcedemy = this.parent.IsEducationThere
          ? this.parent.AcedemyDetails.length
          : 0;
        return !(this.parent.IsEducationThere && totalAcedemy == 0);
      }),
  }),
  Project: yup.object({
    IsProjectThere: yup.boolean(),
    Header: yup.string().when("IsProjectThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    ProjectsDetail: yup
      .array()
      .of(ProjectSchema.notRequired())
      .when("IsProjectThere", {
        is: true,
        then: yup.array().of(
          ProjectSchema.shape({
            Title: yup.string().required("SpecifyProjectTitle"),
            StartDate: yup
              .date()
              .default(() => new Date())
              .typeError("SpecifyValidDate"),
            EndDate: yup
              .date()
              .default(function () {
                return new Date();
              })
              .typeError("SpecifyValidDate")
              .test(
                "lessThanProStartDate",
                "lessThanProStartDate",
                function (val) {
                  if (!this.parent.IsYourCurrentProject) {
                    return this.parent.StartDate < val;
                  } else {
                    return true;
                  }
                }
              ),
          }).notRequired()
        ),
      })
      .test("testProjectLength", "SpecifyProject", function (value) {
        let totalProjects = this.parent.IsProjectThere
          ? this.parent.ProjectsDetail.length
          : 0;
        return !(this.parent.IsProjectThere && totalProjects == 0);
      }),
  }),
  Awards: yup.object({
    IsAwardThere: yup.boolean(),
    Header: yup.string().when("IsAwardThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    AwardsDetails: yup
      .array()
      .of(AwardSchema.notRequired())
      .when("IsAwardThere", {
        is: true,
        then: yup.array().of(
          AwardSchema.shape({
            Title: yup.string().required("SpecifyAwardTitle"),
            Date: yup
              .date()
              .default(() => new Date())
              .typeError("SpecifyValidDate"),
          }).notRequired()
        ),
      })
      .test("testAwardLength", "SpecifyAward", function (value) {
        let totalAward = this.parent.IsAwardThere
          ? this.parent.AwardsDetails.length
          : 0;
        return !(this.parent.IsAwardThere && totalAward == 0);
      }),
  }),
  Certification: yup.object({
    IsCertificationThere: yup.boolean(),
    Header: yup.string().when("IsCertificationThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    CertificationDetails: yup
      .array()
      .of(CertificationSchema.notRequired())
      .when("IsCertificationThere", {
        is: true,
        then: yup.array().of(
          CertificationSchema.shape({
            Title: yup.string().required("SpecifyCertificateTitle"),
            Date: yup
              .date()
              .default(() => new Date())
              .typeError("SpecifyValidDate"),
          }).notRequired()
        ),
      })
      .test(
        "testCertificationLength",
        "SpecifyCertification",
        function (value) {
          let totalCertification = this.parent.IsCertificationThere
            ? this.parent.CertificationDetails.length
            : 0;
          return !(this.parent.IsCertificationThere && totalCertification == 0);
        }
      ),
  }),
  Skill: yup.object({
    IsSkillThere: yup.boolean(),
    Header: yup.string().when("IsSkillThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    Skills: yup
      .array()
      .of(SkillSchema.notRequired())
      .test("testSkillsLength", "SpecifySkill", function (value) {
        let totalSkill = this.parent.Skills ? this.parent.Skills.length : 0;
        return !(this.parent.IsSkillThere && totalSkill == 0);
      }),
  }),
  Hobby: yup.object({
    IsHobbyThere: yup.boolean(),
    Header: yup.string().when("IsHobbyThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    Hobbies: yup
      .array()
      .of(HobbySchema.notRequired())
      .test("testHobbyLength", "SpecifyHobby", function (value) {
        let totalHobbies = this.parent.Hobbies ? this.parent.Hobbies.length : 0;
        return !(this.parent.IsHobbyThere && totalHobbies == 0);
      }),
  }),
  Language: yup.object({
    IsLanguageThere: yup.boolean(),
    Header: yup.string().when("IsLanguageThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    LanguageDetails: yup
      .array()
      .of(LanguageSchema.notRequired())
      .test("testLanguageLength", "SpecifyLanguage", function (value) {
        let totalLanguage = this.parent.LanguageDetails
          ? this.parent.LanguageDetails.length
          : 0;
        return !(this.parent.IsLanguageThere && totalLanguage == 0);
      }),
  }),
  Reference: yup.object({
    IsReferenceThere: yup.boolean(),
    Header: yup.string().when("IsReferenceThere", {
      is: true,
      then: yup.string().required("SpecifyHeader"),
    }),
    ReferencesDetail: yup
      .array()
      .of(ReferenceSchema.notRequired())
      .when("IsReferenceThere", {
        is: true,
        then: yup.array().of(
          ReferenceSchema.shape({
            Name: yup.string().required("SpecifyReferenceName"),
            PoneNumber: yup
              .string()
              .test("onlyNumbers", "SpecifyVaildMobile", function (value) {
                return /^[0-9]*$/.test(value as string);
              })
              .required("SpecifyVaildMobile")
              /*.phone("", false, "SpecifyVaildMobile")*/,
            Email: yup.string().email("SpecifyValidEmail"),
          }).notRequired()
        ),
      })
      .test("testReferenceLength", "SpecifyReference", function (value) {
        let totalReference = this.parent.IsReferenceThere
          ? this.parent.ReferencesDetail.length
          : 0;
        return !(this.parent.IsReferenceThere && totalReference == 0);
      }),
  }),
});

export type ResumeBuilderModel = yup.InferType<typeof ResumeBuilderSchema>;
