import React from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Stack,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Switch,
  Alert,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from "@mui/material";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import DateAdapter from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import CloseIcon from "@mui/icons-material/Close";
import SummaryEditor from "./SummaryEditor";
import '../myStyles/inputInformation.css';

const Certification: React.FC<Type.ICertification> = (props) => {
     const { t } = useTranslation();
     console.log("Certification");



  const getErrorString = (index: number, propsName: string): string => {
    if (
      Array.isArray(props.CertificationHistoryError) &&
      props.CertificationHistoryError[index] !== null &&
      props.CertificationHistoryError[index] !== undefined &&
      props.CertificationHistoryError[index].hasOwnProperty(propsName)
    ) {
      return t(props.CertificationHistoryError[index][propsName]);
    }
    return "";
  };

  const getTouchedValue = (index: number, propsName: string): boolean => {
    if (
      Array.isArray(props.CertificationHistoryTouched) &&
      props.CertificationHistoryTouched[index] !== null &&
      props.CertificationHistoryTouched[index] !== undefined &&
      props.CertificationHistoryTouched[index].hasOwnProperty(propsName)
    ) {
      return props.CertificationHistoryTouched[index][propsName];
    }
    return false;
  };

  const noCertificationError =
    Array.isArray(props.CertificationHistoryTouched) &&
    props.CertificationHistoryTouched.length == 0 &&
    typeof props.CertificationHistoryError == "string" &&
    props.CertificationHistoryError != undefined ? (
      <Alert severity="error" sx={{ mt: 1 }}>
        {t(props.CertificationHistoryError)}
      </Alert>
    ) : (
      <span></span>
    );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="certification_div">
          <TextField
            id="Certification_Header"
            label={t("Header")}
            variant="outlined"
            className='resume_input'
            size="small"
            fullWidth
            name="Certification.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            onBlur={props.onBlurHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
            required
          />
          {noCertificationError}
        </Grid>

        {props.CertificationHistory?.map((c, i) => {
          let titleError =
            getTouchedValue(i, "Title") && getErrorString(i, "Title");
          let endDateError =
            getTouchedValue(i, "Date") && getErrorString(i, "Date");
          return (
            <React.Fragment key={i}>
              <Grid item xs={12}>
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={(e) => props.RemoveCertificationHistory(i)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    sx={{ pb: 0, pt: 1 }}
                  />
                  <CardContent sx={{ pt: "1px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label={t("Title")}
                          variant="outlined"
                          size="small"
                          className='resume_input'
                          fullWidth
                          name={`Certification.CertificationDetails[${i}].Title`}
                          value={c.Title}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={titleError}
                          error={Boolean(titleError)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={t("Issuer")}
                          variant="outlined"
                          className='resume_input'
                          size="small"
                          fullWidth
                          name={`Certification.CertificationDetails[${i}].Issuer`}
                          value={c.Issuer}
                          onChange={props.onChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className="certification_switch">
                        <FormControlLabel
                          control={
                            <Switch
                              name={`Certification.CertificationDetails[${i}].IsPursuing`}
                              checked={c.IsPursuing}
                              onChange={props.onChangeHandler}
                            />
                          }
                          label={t("PursuingCourse")}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className="certification_date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={t("EndDate")}
                            format="dd/MM/yyyy"
                            className='resume_input DOB'
                            value={c.Date}
                            disabled={c.IsPursuing}
                            onChange={(newValue: any) => {
                              props.SetValueByPropNameHandler(
                                `Certification.CertificationDetails[${i}].Date`,
                                newValue
                              );
                            }}
                            // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                            //   <TextField
                            //     {...params}
                            //     size="small"
                            //     error={Boolean(endDateError)}
                            //     helperText={endDateError}
                            //     fullWidth
                            //   />
                            // )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <SummaryEditor
                          Summary={c.Summary}
                          ParentArrayLenght={props.CertificationHistory?.length}
                          CallbackFun={(html: any) => {
                            props.SetValueByPropNameHandler(
                              `Certification.CertificationDetails[${i}].Summary`,
                              html
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12} className="certification_btn">
          <Button
            variant="contained"
            fullWidth
            onClick={props.AddCertificationHistrory}
            className="btn_work"
          >
            {t("AddMoreCertification")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(Certification);
