import * as yup from 'yup';

export const SignupValidationSchema = yup.object({
  email: yup.string().email("SpecifyEmail").required("SpecifyValidEmail"),
  password: yup
    .string()
    .required("SpecifyPassword")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "StrongPasswordMsg"
    ),
});

export type SignupModel = yup.InferType<typeof SignupValidationSchema>;