import React,{useContext} from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  MenuItem,
  Button,
  FormHelperText,
  Box,
  Divider,
  Alert,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import * as Type from "../Utility/typeDef";
import { AddTemplateModel, AddTemplateSchema } from "../model/AddTemplateModel";
import { useFormik } from "formik";
import { addTemplate, updateTemplate } from "../service/firebase";
import {demoDataModel} from "../Utility/demoData";
import {GetFormattedDate} from "../Utility/common"
import { AuthContext } from "../context/AuthContext";


const AddTemplate: React.FC<Type.IAddTemplateForm> = (props) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  let model: AddTemplateModel;
  if (props.TemplateId) {
    model = {
      Name: props.TemplateDetail?.Name as string,
      Template: props.TemplateDetail?.Template as string,
      Status: props.TemplateDetail?.Status as string,
      Deleted: false,
      ModifyDate: new Date(),
      CreateDate: new Date(),
      CreatedBy: {
        Id: authContext?.getUserDatail?.Id,
        Name: authContext?.getUserDatail?.Name,
        Mobile: authContext?.getUserDatail?.Mobile,
        Email: authContext?.getUserDatail?.Email,
      },
      ModifyBy: {
        Id: authContext?.getUserDatail?.Id,
        Name: authContext?.getUserDatail?.Name,
        Mobile: authContext?.getUserDatail?.Mobile,
        Email: authContext?.getUserDatail?.Email,
      },
      Sequence: props.TemplateDetail?.Sequence ?? 0,
      Weightage: props.TemplateDetail?.Weightage ?? 0,
    };
  } else {
       model = {
         Name:"",
         Template: "",
         Status: "",
         Deleted: false,
         ModifyDate: new Date(),
         CreateDate: new Date(),
         CreatedBy: {
          Id: authContext?.getUserDatail?.Id,
          Name: authContext?.getUserDatail?.Name,
          Mobile: authContext?.getUserDatail?.Mobile,
          Email: authContext?.getUserDatail?.Email,
        },
         ModifyBy: {
           Id: authContext?.getUserDatail?.Id,
           Name: authContext?.getUserDatail?.Name,
           Mobile: authContext?.getUserDatail?.Mobile,
           Email: authContext?.getUserDatail?.Email,
         },
         Sequence: 0,
         Weightage:0
       };
  }

    const formik = useFormik({
      initialValues: model,
      validationSchema: AddTemplateSchema,
      onSubmit: (values) => {

        const callbackFunc = (
          isError: boolean,
          message: string
        ) => {
          if(isError)
          {
            window.showGenericAlertDialog(undefined, t(message));
          }
          else
          {
              window.showGenericAlertDialog(undefined, t(message), [
                { title: "Ok", fn: props.CloseAndResetParent },
              ]);
          }
        };


        window.showProgressbar();
        let data = formik.values;
        if(props.TemplateId)
        {
          delete data.CreateDate;
          delete data.CreatedBy;
          updateTemplate(props.TemplateId, data, callbackFunc);
        }
        else
        {
          addTemplate(data, callbackFunc);
        }
         window.hideProgressbar();
       
      },
    });



  

  const populateValue = async () => {
    let templateView = document.getElementById("resumeForDownload");

    let updatePofile = async () => {
      let profileHeader = templateView?.getElementsByClassName("profileHeader");
      let profileName = templateView?.getElementsByClassName("profileName");
      let profileSubtitle =
        templateView?.getElementsByClassName("profileSubtitle");
      let profileDoB = templateView?.getElementsByClassName("profileDoB");
      let profileMobile = templateView?.getElementsByClassName("profileMobile");
      let profileEmail = templateView?.getElementsByClassName("profileEmail");
      let profileWebsite =
        templateView?.getElementsByClassName("profileWebsite");
      let profileAddress =
        templateView?.getElementsByClassName("profileAddress");

      if (profileHeader !== undefined) {
        for (let i = 0; i < profileHeader.length; i++) {
          profileHeader[i].innerHTML = demoDataModel.Profile.Header as string;
        }
      }
      if (profileName !== undefined) {
        for (let i = 0; i < profileName.length; i++) {
          profileName[i].innerHTML = demoDataModel.Profile.Name as string;
        }
      }

      if (profileSubtitle !== undefined) {
        for (let i = 0; i < profileSubtitle.length; i++) {
          profileSubtitle[i].innerHTML = demoDataModel.Profile.Subtitle as string;
        }
      }

      if (profileDoB !== undefined) {
        for (let i = 0; i < profileDoB.length; i++) {
          profileDoB[i].innerHTML = GetFormattedDate(demoDataModel.Profile.DateOfBirth);
        }
      }

      if (profileMobile !== undefined) {
        for (let i = 0; i < profileMobile.length; i++) {
          profileMobile[i].innerHTML = demoDataModel.Profile.PhoneNumber as string;
        }
      }

      if (profileEmail !== undefined) {
        for (let i = 0; i < profileEmail.length; i++) {
          profileEmail[i].innerHTML = demoDataModel.Profile.Email as string;
        }
      }

      if (profileWebsite !== undefined) {
        for (let i = 0; i < profileWebsite.length; i++) {
          profileWebsite[i].innerHTML = demoDataModel.Profile.Website as string;
        }
      }

      if (profileAddress !== undefined) {
        for (let i = 0; i < profileAddress.length; i++) {
          profileAddress[i].innerHTML = demoDataModel.Profile.Address as string;
        }
      }
    };

    let updateObjective = async () => {
      let objectiveHeader =
        templateView?.getElementsByClassName("objectiveHeader");
      let objectiveSummary =
        templateView?.getElementsByClassName("objectiveSummary");

      if (objectiveHeader !== undefined) {
        for (let i = 0; i < objectiveHeader.length; i++) {
          objectiveHeader[i].innerHTML = demoDataModel.Objective.Header as string;
        }
      }
      if (objectiveSummary !== undefined) {
        for (let i = 0; i < objectiveSummary.length; i++) {
          objectiveSummary[i].innerHTML = demoDataModel.Objective.Summary as string;
        }
      }
    };

    let updateSkill = async () => {
      let skillHeader = templateView?.getElementsByClassName("skillHeader");
      let skillTemplate = templateView?.getElementsByClassName("skillTemplate");

      if (skillHeader !== undefined) {
        for (let i = 0; i < skillHeader.length; i++) {
          skillHeader[i].innerHTML = demoDataModel.Skill.Header as string;
        }
      }

      if (skillTemplate !== undefined) {
        for (let i = 0; i < skillTemplate.length; i++) {
          let arrayLength = demoDataModel.Skill.Skills
            ? demoDataModel.Skill?.Skills?.length
            : 0;
           debugger; 
          let injectPoint = skillTemplate[i]
            .closest("#resumeForDownload")!
            .querySelector(".skillTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                skillTemplate[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let skillName = cloneElement.querySelector(".skillName");
              let skillLevel = cloneElement.querySelector(".skillLevel");
              if (skillName !== null) {
                skillName.innerHTML = demoDataModel.Skill.Skills![x].Name as string;
              }
              if (skillLevel !== null) {
                skillLevel.innerHTML = demoDataModel.Skill?.Skills![x].Level as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateWorkingExpe = async () => {
      let header = templateView?.getElementsByClassName("workExperienceHeader");
      let template = templateView?.getElementsByClassName(
        "workExperienceTemplate"
      );

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.WorkExperience.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.WorkExperience.WorkHistory
            ? demoDataModel.WorkExperience?.WorkHistory?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".workExperienceTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let companyName = cloneElement.querySelector(
                ".WorkExperienceCompanyName"
              );
              let position = cloneElement.querySelector(
                ".workExperiencePosition"
              );
              let website = cloneElement.querySelector(".wokExperienceWebsite");
              let Doj = cloneElement.querySelector(".workExperienceDoj");
              let Dol = cloneElement.querySelector(".workExperienceDoL");
              let summary = cloneElement.querySelector(
                ".workExperienceSummary"
              );
              if (companyName !== null) {
                companyName.innerHTML = demoDataModel.WorkExperience.WorkHistory![x]
                  .Company as string;
              }

              if (position !== null) {
                position.innerHTML = demoDataModel.WorkExperience.WorkHistory![x]
                  .Position as string;
              }

              if (website !== null) {
                website.innerHTML = demoDataModel.WorkExperience.WorkHistory![x]
                  .Website as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  demoDataModel.WorkExperience.WorkHistory![x].StartDate
                );
              }

              if (Dol !== null) {
                Dol.innerHTML = GetFormattedDate(
                  demoDataModel.WorkExperience.WorkHistory![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = demoDataModel.WorkExperience.WorkHistory![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateEducation = async () => {
      let header = templateView?.getElementsByClassName("educationHeader");
      let template = templateView?.getElementsByClassName("educationTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.Education.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.Education.AcedemyDetails
            ? demoDataModel.Education?.AcedemyDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".educationTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(
                ".educationInstitutionName"
              );
              let fieldOfStudy = cloneElement.querySelector(
                ".educationFieldOfStudy"
              );
              let typeOfDegree = cloneElement.querySelector(
                ".educationTypeOfDegree"
              );
              let Gpa = cloneElement.querySelector(".educationGpa");
              let Doj = cloneElement.querySelector(".educationStartDate");
              let Dol = cloneElement.querySelector(".educationEndDate");
              let summary = cloneElement.querySelector(".educationSummary");
              if (name !== null) {
                name.innerHTML = demoDataModel.Education.AcedemyDetails![x]
                  .Institution as string;
              }

              if (fieldOfStudy !== null) {
                fieldOfStudy.innerHTML = demoDataModel.Education.AcedemyDetails![x]
                  .FieldOfStudy as string;
              }

              if (typeOfDegree !== null) {
                typeOfDegree.innerHTML = demoDataModel.Education.AcedemyDetails![x]
                  .TypeOfDegree as string;
              }

              if (Gpa !== null) {
                Gpa.innerHTML = demoDataModel.Education.AcedemyDetails![x]
                  .MarkInPercentage as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  demoDataModel.Education.AcedemyDetails![x].StartDate
                );
              }

              if (Dol !== null) {
                Dol.innerHTML = GetFormattedDate(
                  demoDataModel.Education.AcedemyDetails![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = demoDataModel.Education.AcedemyDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateCertification = async () => {
      let header = templateView?.getElementsByClassName("certificationHeader");
      let template = templateView?.getElementsByClassName(
        "certificationTemplate"
      );

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.Certification.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.Certification.CertificationDetails
            ? demoDataModel.Certification.CertificationDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".certificationTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".certificationTitle");
              let issuer = cloneElement.querySelector(".certificationIssuer");
              let Doj = cloneElement.querySelector(
                ".certificationCompleteDate"
              );
              let summary = cloneElement.querySelector(".certificationSummary");

              if (title !== null) {
                title.innerHTML = demoDataModel.Certification.CertificationDetails![x]
                  .Title as string;
              }

              if (issuer !== null) {
                issuer.innerHTML = demoDataModel.Certification.CertificationDetails![x]
                  .Issuer as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  demoDataModel.Certification.CertificationDetails![x].Date
                );
              }

              if (summary !== null) {
                summary.innerHTML = demoDataModel.Certification.CertificationDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateAward = async () => {
      let header = templateView?.getElementsByClassName("awardHeader");
      let template = templateView?.getElementsByClassName("awardTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.Awards.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.Awards.AwardsDetails
            ? demoDataModel.Awards.AwardsDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".awardTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".awardTitle");
              let issuer = cloneElement.querySelector(".awardAwarder");
              let Doj = cloneElement.querySelector(".awardDate");
              let summary = cloneElement.querySelector(".awardSummary");

              if (title !== null) {
                title.innerHTML = demoDataModel.Awards.AwardsDetails![x]
                  .Title as string;
              }

              if (issuer !== null) {
                issuer.innerHTML = demoDataModel.Awards.AwardsDetails![x]
                  .Awarder as string;
              }

              if (Doj !== null) {
                Doj.innerHTML = GetFormattedDate(
                  demoDataModel.Awards.AwardsDetails![x].Date
                );
              }

              if (summary !== null) {
                summary.innerHTML = demoDataModel.Awards.AwardsDetails![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateProject = async () => {
      let header = templateView?.getElementsByClassName("projectHeader");
      let template = templateView?.getElementsByClassName("projectTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.Project.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.Project.ProjectsDetail
            ? demoDataModel.Project.ProjectsDetail?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".projectTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let title = cloneElement.querySelector(".projectTitle");
              let website = cloneElement.querySelector(".projectWebsite");
              let startDate = cloneElement.querySelector(".projectStartDate");
              let endDate = cloneElement.querySelector(".projectEndDate");
              let summary = cloneElement.querySelector(".projectSummary");

              if (title !== null) {
                title.innerHTML = demoDataModel.Project.ProjectsDetail![x]
                  .Title as string;
              }

              if (website !== null) {
                website.innerHTML = demoDataModel.Project.ProjectsDetail![x]
                  .Website as string;
              }

              if (startDate !== null) {
                startDate.innerHTML = GetFormattedDate(
                  demoDataModel.Project.ProjectsDetail![x].StartDate
                );
              }

              if (endDate !== null) {
                endDate.innerHTML = GetFormattedDate(
                  demoDataModel.Project.ProjectsDetail![x].EndDate
                );
              }

              if (summary !== null) {
                summary.innerHTML = demoDataModel.Project.ProjectsDetail![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateLanguage = async () => {
      let header = templateView?.getElementsByClassName("languageHeader");
      let template = templateView?.getElementsByClassName("languageTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.Language.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.Language.LanguageDetails
            ? demoDataModel.Language.LanguageDetails?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".languageTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".languageName");
              let level = cloneElement.querySelector(".languageLevel");

              if (name !== null) {
                name.innerHTML = demoDataModel.Language.LanguageDetails![x]
                  .Name as string;
              }

              if (level !== null) {
                level.innerHTML = demoDataModel.Language.LanguageDetails![x]
                  .Fluency as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateHobby = async () => {
      let header = templateView?.getElementsByClassName("hobbyHeader");
      let template = templateView?.getElementsByClassName("hobbyTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.Hobby.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.Hobby.Hobbies
            ? demoDataModel.Hobby.Hobbies?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".hobbyTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".hobbyName");

              if (name !== null) {
                name.innerHTML = demoDataModel.Hobby.Hobbies![x].Name as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    let updateReference = async () => {
      let header = templateView?.getElementsByClassName("referenceHeader");
      let template = templateView?.getElementsByClassName("referenceTemplate");

      if (header !== undefined) {
        for (let i = 0; i < header.length; i++) {
          header[i].innerHTML = demoDataModel.Reference.Header as string;
        }
      }

      if (template !== undefined) {
        for (let i = 0; i < template.length; i++) {
          let arrayLength = demoDataModel.Reference.ReferencesDetail
            ? demoDataModel.Reference.ReferencesDetail?.length
            : 0;
          let injectPoint = template[i]
            .closest("#resumeForDownload")!
            .querySelector(".referenceTemplateInjectPoint");
          if (injectPoint !== null) {
            injectPoint.innerHTML = "";

            for (let x = 0; x < arrayLength; x++) {
              let cloneElement: HTMLElement = (
                template[i] as HTMLTemplateElement
              ).content.cloneNode(true) as HTMLElement;

              let name = cloneElement.querySelector(".referenceName");
              let email = cloneElement.querySelector(".referenceEmail");
              let mobile = cloneElement.querySelector(".referenceMobile");
              let position = cloneElement.querySelector(".referencePosition");
              let summary = cloneElement.querySelector(".referenceSummary");

              if (name !== null) {
                name.innerHTML = demoDataModel.Reference.ReferencesDetail![x]
                  .Name as string;
              }

              if (email !== null) {
                email.innerHTML = demoDataModel.Reference.ReferencesDetail![x]
                  .Email as string;
              }

              if (mobile !== null) {
                mobile.innerHTML = demoDataModel.Reference.ReferencesDetail![x]
                  .PoneNumber as string;
              }

              if (position !== null) {
                position.innerHTML = demoDataModel.Reference.ReferencesDetail![x]
                  .Position as string;
              }

              if (summary !== null) {
                summary.innerHTML = demoDataModel.Reference.ReferencesDetail![x]
                  .Summary as string;
              }

              injectPoint.append(cloneElement);
            }
          }
        }
      }
    };

    await Promise.all([
      updatePofile(),
      updateObjective(),
      updateSkill(),
      updateWorkingExpe(),
      updateEducation(),
      updateCertification(),
      updateAward(),
      updateProject(),
      updateLanguage(),
      updateHobby(),
      updateReference(),
    ]);
  };

  React.useEffect(()=>{
    populateValue();
  },[formik.values.Template]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={5} sx={{ pr: 2 }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} alignContent="flex-start">
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="Name"
                label={t("TamplateName")}
                value={formik.values.Name}
                onChange={formik.handleChange}
                error={formik.touched.Name && Boolean(formik.errors.Name)}
                helperText={
                  formik.touched.Name && t(formik.errors.Name as string)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={t("Template")}
                multiline
                rows={5}
                name="Template"
                value={formik.values.Template}
                onChange={formik.handleChange}
                error={
                  formik.touched.Template && Boolean(formik.errors.Template)
                }
                helperText={
                  formik.touched.Template && t(formik.errors.Template as string)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ mb: 2 }} />

              <Alert severity="info" sx={{ mb: 2 }}>
                {t("OrderInfo")}
              </Alert>
              <TextField
                fullWidth
                type="number"
                label={t("Sequence")}
                name="Sequence"
                value={formik.values.Sequence}
                onChange={formik.handleChange}
                error={
                  formik.touched.Sequence && Boolean(formik.errors.Sequence)
                }
                helperText={
                  formik.touched.Sequence && t(formik.errors.Sequence as string)
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label={t("Weightage")}
                name="Weightage"
                value={formik.values.Weightage}
                onChange={formik.handleChange}
                error={
                  formik.touched.Weightage && Boolean(formik.errors.Weightage)
                }
                helperText={
                  formik.touched.Weightage &&
                  t(formik.errors.Weightage as string)
                }
              />
              <Divider sx={{ mt: 2 }} />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={formik.touched.Status && Boolean(formik.errors.Status)}
              >
                <InputLabel id="lblstatus">{t("Status")}</InputLabel>
                <Select
                  labelId="lblstatus"
                  label={t("Status")}
                  name="Status"
                  value={formik.values.Status}
                  onChange={formik.handleChange}
                >
                  <MenuItem value={Type.TemplateStatus.Unpublish}>
                    {t("Unpublish")}
                  </MenuItem>
                  <MenuItem value={Type.TemplateStatus.Publish}>
                    {t("Publish")}
                  </MenuItem>
                </Select>
                <FormHelperText>
                  {formik.touched.Status && t(formik.errors.Status as string)}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button variant="contained" type="submit" className="addtemplate_button" style={{width: '100%'}}>
                {t("Submit")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid
        item
        xs={12}
        md={7}
        sx={{ backgroundColor: "black", padding: "10px", overflow: "auto" }}
      >
        <Box component="div" id="test" className="preview">
          <Box
            component="div"
            id="resumeForDownload"
            sx={{
              backgroundColor: "white",
              width: "210mm",
              minHeight: "297mm",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: formik.values.Template }}
            ></div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddTemplate;
