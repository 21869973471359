import {
  FormControl,
  Grid,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Menu,
  Dialog,
  AppBar,
  Toolbar,
  DialogContent,
  Slide,
  Typography,
  Rating,
  Icon,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useRef,
} from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import DraftsIcon from "@mui/icons-material/Drafts";
import { useTranslation } from "react-i18next";
import { FeedbackSearchCriteria } from "../../src/Utility/typeDef";
import { FeedbackSchema, FeedbackModel } from "../model/FeedbackModel";
import { StyledTableCell, StyledTableRow } from "../components/CustomTableRow";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import {getFeedbacks} from "../service/firebase"
import InfiniteScroll from "react-infinite-scroll-component";
import UserFeedbackDetails from "./UserFeedbackDetails";
import '../myStyles/UserFeedback.css';


const Transition = React.forwardRef(function Transition(
  props: any,
  ref: any
): any {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserFeedback:React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [searchIn, setSearchIn] = useState(FeedbackSearchCriteria.All);
  const [userFeedbacks, setUserFeedback] = useState<FeedbackModel[]>([]);
  const [viewFeedback,setViewFeedback] = useState<FeedbackModel | null>(null);
  const [contextanchorEl, setContextanchorEl] = React.useState<any[]>();
  const [openViewDialog, setOpenViewDialog] = React.useState(false);
  const [lastObj,setLastObj] =React.useState<any>(null)
   const tableEl = useRef<HTMLDivElement>(null);

  const searchClickHandler = () => {
    let element = document.getElementById("scrollableTable");
    if (element) {
      element.scrollTo(0, 0);
    }
    getUserFeedback();
  };

  const searchInCriteriaChangeHandler =(value:any)=>{
    let element = document.getElementById("scrollableTable");
    if (element)
    {
      element.scrollTo(0, 0);
    }
    getUserFeedback(false, value);
  }

  const closeViewDialog = () => {
    setOpenViewDialog(false);
  };

  const viewClickHandler = (Id: string) => {
    setOpenViewDialog(true);
    setViewFeedback(userFeedbacks.filter(item => item.Id == Id)[0]);
  };

  const createTable = (data: FeedbackModel[]) => {
    let jsxElement = <Alert severity="info">{t("NoDataFound")}</Alert>;

    const openMenuHandler = (event: any, index: number) => {
      let arr: any[] = [];
      data?.map(() => arr.push(null));
      arr[index] = event.currentTarget;
      setContextanchorEl(arr);
    };

    const closeMenuHandler = () => {
      let arr: any[] = [];
      data?.map(() => arr.push(null));
      setContextanchorEl(arr);
    };

    if (data && data.length > 0) {
      jsxElement = (
        <React.Fragment>
          <TableContainer sx={{ maxHeight: "324px" }} id="scrollableTable">
            <InfiniteScroll
              dataLength={data.length}
              next={() => getUserFeedback(true)}
              hasMore={true}
              loader={<h4></h4>}
              scrollableTarget="scrollableTable"
            >
              <Table aria-label="user feedback" size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="template_table_head">S No.</StyledTableCell>
                    <StyledTableCell className="template_table_head">{t("UserName")}</StyledTableCell>
                    <StyledTableCell className="template_table_head">{t("Email")}</StyledTableCell>
                    <StyledTableCell className="template_table_head">{t("Rating")}</StyledTableCell>
                    <StyledTableCell className="template_table_head">File</StyledTableCell>
                    <StyledTableCell className="template_table_head" sx={{ width: "10px" }}>Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => {
                    let anchorEl =
                      contextanchorEl && contextanchorEl.length >= index
                        ? contextanchorEl[index]
                        : null;

                    return (
                      <StyledTableRow key={item.Id}>
                        <StyledTableCell className="template_table_data">{item.Read?<DraftsIcon/>:<MarkEmailUnreadIcon/>}</StyledTableCell>
                        <StyledTableCell className="template_table_data">{item.User.Name}</StyledTableCell>
                        <StyledTableCell className="template_table_data">{item.User.Email}</StyledTableCell>
                        <StyledTableCell className="template_table_data">
                          <Rating value={item.FeedbackRating} readOnly />
                        </StyledTableCell>

                        <StyledTableCell className="template_table_data">
                          {item.ImagePath && <AttachFileIcon />}
                        </StyledTableCell>
                        <StyledTableCell className="template_table_data">
                          <IconButton
                            aria-label="settings"
                            onClick={(e) => openMenuHandler(e, index)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={(e) => openMenuHandler(e, index)}
                            onClick={(e) => closeMenuHandler()}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                minWidth: "150px",
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              onClick={() =>
                                viewClickHandler(item.Id as string)
                              }
                            >
                              {t("View")}
                            </MenuItem>
                          </Menu>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </React.Fragment>
      );
    }

    return jsxElement;
  };

 

 const getUserFeedback = async (
   isScrollEventFire: boolean = false,
   searchInCriteria: FeedbackSearchCriteria = searchIn
 ) => {
   const callback = (
     isError: boolean,
     message: string,
     data: FeedbackModel[],
     lastObje: any
   ) => {
     if (isError) {
       console.log(message);
     } else {
       if (isScrollEventFire) {
         let allFeedback = userFeedbacks.concat(data);
         setUserFeedback(allFeedback);
       } else {
         setUserFeedback(data);
       }
       lastObje && setLastObj(lastObje);
     }
   };

   let lastObject = isScrollEventFire ? lastObj : null;
   await getFeedbacks(search, searchInCriteria, lastObject, callback);
 };

  useEffect(()=>{
    getUserFeedback();
  },[])
  return (
    <Grid container spacing={2} sx={{ height: "450px" }}>
      <Grid item container xs={12}>
        <Grid item xs={12} md={4}>
          <FormControl className="feedback_drop">
            <Select
              size="small"
              value={searchIn}
              onChange={(e) => {
                setSearchIn(e.target.value as FeedbackSearchCriteria);
                searchInCriteriaChangeHandler(e.target.value);
              }}
            >
              <MenuItem value={FeedbackSearchCriteria.All}>{t("All")}</MenuItem>
              <MenuItem value={FeedbackSearchCriteria.Read}>
                {t("Read")}
              </MenuItem>
              <MenuItem value={FeedbackSearchCriteria.Unread}>
                {t("Unread")}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          sm={12}
          md={8}
          alignContent="right"
          sx={{ textAlign: "end" }}
        >
          <div className="searchbar">
          <TextField
            id="search_feedback"
            size="small"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t("SearchByName")}
            className="search_bar"
          />
          <Button
            className="search_feedback_button"
            sx={{ ml: 1 }}
            variant="contained"
            onClick={searchClickHandler}
          >
            <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                <path fill="#666666" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
              </svg>
            {/* {t("Search")} */}
          </Button>
          </div>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider></Divider>
      </Grid>
      <Grid item sx={{ height: "375px" }} xs={12}>
        {createTable(userFeedbacks)}
      </Grid>
      <Grid item xs={12}>
        <Dialog
          fullScreen
          open={openViewDialog}
          onClose={closeViewDialog}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: "relative" }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={closeViewDialog}
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {t("FeedbackDetail")}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <UserFeedbackDetails Data={viewFeedback as FeedbackModel} />
          </DialogContent>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default UserFeedback;
