import {ResumeBuilderModel} from "../model/ResumeBuilderModel"
const defalutTextValue = (testSize: number) => {
  switch (testSize) {
    case 1:
      return "#### #### ###";
    case 2:
      return "#### #### #### ###";
    case 3:
      return `#### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###
                        #### #### #### ### #### #### #### ###`;

    default:
      return "#### #### ###";
  }
};

 export const demoDataModel: ResumeBuilderModel = {
    UserId: "",
    TemplateId: "",
    PageBreakPoints: [],
    CreateDate: new Date(),
    ModifyDate: new Date(),
    Awards: {
      IsAwardThere: true,
      Header: "Award",
      AwardsDetails: [
        {
          Awarder: defalutTextValue(1),
          Date: new Date(),
          Summary: defalutTextValue(3),
          Title: defalutTextValue(2),
        },
      ],
    },
    Certification: {
      IsCertificationThere: true,
      Header: "Certification",
      CertificationDetails: [
        {
          Date: new Date(),
          Issuer: defalutTextValue(1),
          Summary: defalutTextValue(3),
          Title: defalutTextValue(2),
          IsPursuing: false,
        },
      ],
    },
    Education: {
      IsEducationThere: true,
      Header: "Education",
      AcedemyDetails: [
        {
          EndDate: new Date(),
          FieldOfStudy: defalutTextValue(1),
          Institution: defalutTextValue(1),
          IsPursuing: false,
          MarkInPercentage: "###",
          StartDate: new Date(),
          Summary: defalutTextValue(3),
          TypeOfDegree: defalutTextValue(1),
        },
      ],
    },
    Hobby: {
      IsHobbyThere: true,
      Header: "Hobbie",
      Hobbies: [{ Name: defalutTextValue(2) }],
    },
    Language: {
      IsLanguageThere: true,
      Header: "Language",
      LanguageDetails: [
        { Name: defalutTextValue(2), Fluency: defalutTextValue(1) },
      ],
    },
    Objective: {
      IsObjectiveThere: true,
      Header: "Summary",
      Summary: defalutTextValue(3),
    },
    Profile: {
      Address: defalutTextValue(2),
      DateOfBirth: new Date(),
      Email: defalutTextValue(1),
      Name: defalutTextValue(2),
      PhoneNumber: defalutTextValue(1),
      Subtitle: defalutTextValue(1),
      Website: defalutTextValue(1),
      Header: "Profile",
    },
    Project: {
      IsProjectThere: true,
      Header: "Project",
      ProjectsDetail: [
        {
          EndDate: new Date(),
          IsYourCurrentProject: false,
          StartDate: new Date(),
          Summary: defalutTextValue(3),
          Title: defalutTextValue(2),
          Website: defalutTextValue(1),
        },
      ],
    },
    Reference: {
      IsReferenceThere: true,
      Header: "Reference",
      ReferencesDetail: [
        {
          Email: defalutTextValue(1),
          Name: defalutTextValue(2),
          PoneNumber: defalutTextValue(1),
          Position: defalutTextValue(1),
          Summary: defalutTextValue(3),
        },
      ],
    },
    SocialNetworks: [],
    WorkExperience: {
      IsWorkExperienceThere: true,
      Header: "Work Experience",
      WorkHistory: [
        {
          Company: defalutTextValue(1),
          CurrentJob: false,
          Summary: defalutTextValue(3),
          Website: defalutTextValue(1),
          Position: defalutTextValue(1),
          StartDate: new Date(),
          EndDate: new Date(),
        },
      ],
    },
    Skill: {
      IsSkillThere: true,
      Header: "Skills",
      Skills: [{ Name: defalutTextValue(2), Level: defalutTextValue(1) }],
    },
  };
