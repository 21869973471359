export enum AuthoriseUri {
  Dashboard="/dashboard",
  Resume="/resume",
  About="/about",
  Users="/Users",
  Template="/template",
  Feedback="/feedback"
}

export enum UnauthoriseUri{
  LandingPage= "/landingpage",
  Login="/login",
  Signup="/signup",
  ForgotPassword="/forgotpassword",
  ResetPassword="/resetpassword",
  VerifyingEmail="/verifyingEmail"
}