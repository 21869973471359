import React from 'react'
import Layout from '../components/Layout';
import { AuthoriseUri } from "../Utility/PageUrls";

const Users:React.FC = ()=> {
    return (
      <Layout menuRoute={AuthoriseUri.Users}>
        <div>Users</div>
      </Layout>
    );
}

export default Users
