import * as yup from "yup";

export const UserFormValidationSchema = yup.object({
  Name: yup.string().required("SpecifyName"),
  Mobile: yup
    .string()
    .test("onlyNumbers", "SpecifyVaildMobile", function (value) {
      return  /^[0-9]*$/.test(value as string);
    }),
  Email: yup.string().email("SpecifyEmail").required("SpecifyValidEmail"),
  Address: yup.string(),
  Role: yup.mixed().oneOf(["user", "admin"]),
  ResumeLimit:yup.number().required(),
  CreateDate: yup.date().notRequired(),
  ModifyDate: yup.date().notRequired(),
});

export type UserFormModel = yup.InferType<typeof UserFormValidationSchema>;