import React from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Alert,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants,
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import CloseIcon from "@mui/icons-material/Close";
import SummaryEditor from "./SummaryEditor";
import '../myStyles/inputInformation.css';



const Award: React.FC<Type.IAward> = (props) => {
    const { t } = useTranslation();
    console.log("Award")

  const getErrorString = (index: number, propsName: string): string => {
    if (
      Array.isArray(props.AwardHistoryError) &&
      props.AwardHistoryError[index] !== null &&
      props.AwardHistoryError[index] !== undefined &&
      props.AwardHistoryError[index].hasOwnProperty(propsName)
    ) {
      return t(props.AwardHistoryError[index][propsName]);
    }
    return "";
  };

  const getTouchedValue = (index: number, propsName: string): boolean => {
    if (
      Array.isArray(props.AwardHistoryTouched) &&
      props.AwardHistoryTouched[index] !== null &&
      props.AwardHistoryTouched[index] !== undefined &&
      props.AwardHistoryTouched[index].hasOwnProperty(propsName)
    ) {
      return props.AwardHistoryTouched[index][propsName];
    }
    return false;
  };

  const noAwardHistoryError =
    Array.isArray(props.AwardHistoryTouched) &&
    props.AwardHistoryTouched.length == 0 &&
    typeof props.AwardHistoryError == "string" &&
    props.AwardHistoryError != undefined ? (
      <Alert severity="error" sx={{ mt: 1 }}>
        {t(props.AwardHistoryError)}
      </Alert>
    ) : (
      <span></span>
    );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="awards_div">
          <TextField
            id="Awards_Headers"
            label={t("Header")}
            variant="outlined"
            className='resume_input'
            size="small"
            fullWidth
            name="Awards.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            onBlur={props.onBlurHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
            required
          />
          {noAwardHistoryError}
        </Grid>
        <Grid item xs={12}>
          {props.AwardHistory?.map((w, i) => {
            let titleError =
              getTouchedValue(i, "Title") && getErrorString(i, "Title");
            let dateError =
              getTouchedValue(i, "Date") && getErrorString(i, "Date");
            return (
              <React.Fragment key={i}>
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={(e) => props.RemoveAwardHistory(i)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    sx={{ pb: 0, pt: 1 }}
                  />
                  <CardContent sx={{ pt: "1px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label={t("Title")}
                          variant="outlined"
                          className='resume_input'
                          size="small"
                          fullWidth
                          name={`Awards.AwardsDetails[${i}].Title`}
                          value={w.Title}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={titleError}
                          error={Boolean(titleError)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className="awards_awarder">
                        <TextField
                          label={t("Awarder")}
                          variant="outlined"
                          className='resume_input'
                          size="small"
                          fullWidth
                          name={`Awards.AwardsDetails[${i}].Awarder`}
                          value={w.Awarder}
                          onChange={props.onChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className="awards_date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={t("Date")}
                            className='resume_input DOB'
                            format="dd/MM/yyyy"
                            value={w.Date}
                            onChange={(newValue: any) => {
                              props.SetValueByPropNameHandler(
                                `Awards.AwardsDetails[${i}].Date`,
                                newValue
                              );
                            }}
                            // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                            //   <TextField
                            //     {...params}
                            //     size="small"
                            //     error={Boolean(dateError)}
                            //     helperText={dateError}
                            //     fullWidth
                            //   />
                            // )}
                            // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                            //   <TextField
                            //     {...params}
                            //     size="small"
                            //     error={Boolean(dateError)}
                            //     helperText={dateError}
                            //     fullWidth
                            //   />
                            // )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <SummaryEditor
                          Summary={w.Summary}
                          ParentArrayLenght={props.AwardHistory?.length}
                          CallbackFun={(html: any) => {
                            props.SetValueByPropNameHandler(
                              `Awards.AwardsDetails[${i}].Summary`,
                              html
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item xs={12} className="awards_btn">
          <Button
            variant="contained"
            fullWidth
            className="btn_work"
            onClick={props.AddAwardHistrory}
          >
            {t("AddMoreAward")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};


export default React.memo(Award);
