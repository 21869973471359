import React, { useContext } from "react";
import { Alert, Button, Grid, TextField } from "@mui/material";
import {
  UserFormModel,
  UserFormValidationSchema,
} from "../model/UserFormModel";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Type from "../Utility/typeDef";
import { AuthContext } from "../context/AuthContext";
import {addUser} from "../service/firebase"

const UserForm: React.FC<Type.IUserForm> = (props) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [errorMessage,setErrorMessage]= React.useState("");
  let model: UserFormModel = {
    Name: props.Name,
    Mobile: "",
    Email: props.Email,
    Address: "",
    Role: "user",
    ResumeLimit:100,
    CreateDate:new Date(),
    ModifyDate: new Date()
  };

  const formik = useFormik({
    initialValues: model,
    validationSchema: UserFormValidationSchema,
    onSubmit: (values) => {
        window.showProgressbar();
        setErrorMessage("");
        addUser(props.UserId, values, addUserCallback);
        window.hideProgressbar();
    },
  });

  const addUserCallback=(isError:boolean,message:string)=>{
      if(isError)
      {
          setErrorMessage(message);
      }
      else
      {
          authContext.setUserDatailMissing(false);
      }
  }

  console.log(formik.errors)
  return (
    <React.Fragment>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{}}>
          {errorMessage && (
            <Grid item xs={12}>
              <Alert severity="error">{errorMessage}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              label={t("Name")}
              size="small"
              fullWidth
              name="Name"
              value={formik.values.Name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.Name && Boolean(formik.errors.Name)}
              helperText={
                formik.touched.Name && t(formik.errors.Name as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("Email")}
              size="small"
              fullWidth
              name="Email"
              value={formik.values.Email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.Email && Boolean(formik.errors.Email)}
              helperText={
                formik.touched.Email && t(formik.errors.Email as string)
              }
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("Mobile")}
              size="small"
              fullWidth
              name="Mobile"
              value={formik.values.Mobile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.Mobile && Boolean(formik.errors.Mobile)}
              helperText={
                formik.touched.Mobile && t(formik.errors.Mobile as string)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t("Address")}
              size="small"
              fullWidth
              multiline
              rows={3}
              name="Address"
              value={formik.values.Address}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.Address && Boolean(formik.errors.Address)}
              helperText={
                formik.touched.Address && t(formik.errors.Address as string)
              }
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button type="submit" variant="contained">
              {t("Submit")}
            </Button>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default UserForm;
