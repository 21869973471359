import React, { useEffect, useContext, useState } from "react";
import Layout from "../components/Layout";
import { AuthoriseUri } from "../Utility/PageUrls";
import {
  getAllResumeTemplate,
  publishTemplate,
  unpublishTemplate,
  updateTemplate,
} from "../service/firebase";
import * as Type from "../Utility/typeDef";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Grid,
  Button,
  TextField,
  Alert,
  Divider,
  Dialog,
  DialogContent,
  TablePagination,
  TableFooter,
} from "@mui/material";
import { StyledTableCell, StyledTableRow } from "../components/CustomTableRow";
import AddTemplate from "../components/AddTemplate";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import { AddTemplateModel, AddTemplateSchema } from "../model/AddTemplateModel";
import { AuthContext } from "../context/AuthContext";
import { GetFormattedDate } from "../Utility/common";
import '../myStyles/template.css';
import Modal from 'react-bootstrap/Modal';
import { getFirestore, collection, getDocs, updateDoc, doc } from 'firebase/firestore/lite'; 
import fire from '../service/firebase';
import TextArea from "antd/es/input/TextArea";
import { set } from "date-fns";

const Transition = React.forwardRef(function Transition(
  props: any,
  ref: any
): any {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Template: React.FC = () => {
  const [templates, setTemplate] = React.useState<any[]>();
  const [searchText, setSearchText] = React.useState("");
  const [searchResultTemplate, setsearchResultTemplate] =
    React.useState<any[]>();
  const { t } = useTranslation();
  const [contextanchorEl, setContextanchorEl] = React.useState<any[]>();
  const [openAddform, setOpenAddform] = React.useState(false);
  const [forEditTemplate, setforEditTemplate] = React.useState<any>(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const openAddFormClick = () => {
    setOpenAddform(true);
  };

  const closeAddFormClick = () => {
    setOpenAddform(false);
  };

  const getTemplates = async () => {
    window.showProgressbar();
    let result = await getAllResumeTemplate(true);
    setTemplate(result);
    if (searchText == "") {
      setsearchResultTemplate(result);
    } else {
      setsearchResultTemplate(
        result?.filter((item) =>
          item.Name.trim()
            .toLocaleLowerCase()
            .includes(searchText.trim().toLocaleLowerCase())
        )
      );
    }
    window.hideProgressbar();
  };

  const searchHandler = () => {
    if (searchText == "") {
      setsearchResultTemplate(templates);
    } else {
      setsearchResultTemplate(
        templates?.filter((item) =>
          item.Name.trim()
            .toLocaleLowerCase()
            .includes(searchText.trim().toLocaleLowerCase())
        )
      );
    }
  };

  const editClickHandler = (Id: string) => {
    let temp = templates?.filter((item) => item.Id == Id);
    if (temp) {
      setforEditTemplate(temp[0]);
    }
    openAddFormClick();
  };

  const createTable = (listOfTemplates: any[] | undefined): any => {
    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      newPage: number
    ) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    
    let jsxTemplate = <Alert severity="info">{t("NoDataFound")}</Alert>;
    const openMenuHandler = (event: any, index: number) => {
      let arr: any[] = [];
      listOfTemplates?.map(() => arr.push(null));
      arr[index] = event.currentTarget;
      setContextanchorEl(arr);
    };

    const closeMenuHandler = () => {
      let arr: any[] = [];
      listOfTemplates?.map(() => arr.push(null));
      setContextanchorEl(arr);
    };

    if (listOfTemplates && listOfTemplates.length > 0) {
      listOfTemplates.sort(function (a, b) {
        return (
          a.Sequence - b.Sequence ||
          b.Weightage - a.Weightage ||
          a.Name.localeCompare(b.Name)
        );
      });
      jsxTemplate = (
        <TableContainer component={Paper}>
          <Table aria-label="template table" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell className="template_table_head">{t("TamplateName")}</StyledTableCell>
                <StyledTableCell className="template_table_head">{t("CreatedBy")}</StyledTableCell>
                <StyledTableCell className="template_table_head">{t("Sequence")}</StyledTableCell>
                <StyledTableCell className="template_table_head">{t("Weightage")}</StyledTableCell>
                <StyledTableCell className="template_table_head">{t("CreatedDate")}</StyledTableCell>
                <StyledTableCell className="template_table_head">{t("ModifiedBy")}</StyledTableCell>
                <StyledTableCell className="template_table_head">{t("ModifiedDate")}</StyledTableCell>
                <StyledTableCell className="template_table_head">{t("Status")}</StyledTableCell>
                <StyledTableCell className="template_table_head" sx={{ width: "10px" }}>Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfTemplates &&
                listOfTemplates
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    //debugger;
                    let anchorEl =
                      contextanchorEl && contextanchorEl.length >= index
                        ? contextanchorEl[index]
                        : null;
                    return (
                      <StyledTableRow key={row.Id}>
                        <StyledTableCell className="template_table_data">{row.Name}</StyledTableCell>
                        <StyledTableCell className="template_table_data">
                          {row.CreatedBy.Name} <br></br> ({row.CreatedBy.Email})
                        </StyledTableCell>
                        <StyledTableCell className="template_table_data">{row.Sequence}</StyledTableCell>
                        <StyledTableCell className="template_table_data">{row.Weightage}</StyledTableCell>
                        <StyledTableCell className="template_table_data">
                          {row.CreateDate &&
                            GetFormattedDate(row.CreateDate.toDate())}
                        </StyledTableCell>
                        <StyledTableCell className="template_table_data">
                          {row.ModifyBy.Name} <br></br>({row.ModifyBy.Email})
                        </StyledTableCell>
                        <StyledTableCell className="template_table_data">
                          {row.ModifyDate &&
                            GetFormattedDate(row.ModifyDate.toDate())}
                        </StyledTableCell>
                        <StyledTableCell className="template_table_data">{row.Status}</StyledTableCell>
                        <StyledTableCell className="template_table_data">
                          <IconButton
                            aria-label="settings"
                            onClick={(e) => openMenuHandler(e, index)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={(e) => openMenuHandler(e, index)}
                            onClick={(e) => closeMenuHandler()}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                minWidth: "150px",
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem onClick={() => editClickHandler(row.Id)}>
                              {t("Edit")}
                            </MenuItem>
                            {row.Status == "publish" ? (
                              <MenuItem
                                onClick={() => UnpublishClickHandler(row.Id)}
                              >
                                {t("Unpublish")}
                              </MenuItem>
                            ) : (
                              <MenuItem
                                onClick={() => publishClickHandler(row.Id)}
                              >
                                {t("Publish")}
                              </MenuItem>
                            )}
                          </Menu>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={8}
                  rowsPerPageOptions={[10]}
                  count={listOfTemplates.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      );
    }
    return jsxTemplate;
  };

  const publishClickHandler = (Id: string) => {
    const publish = async () => {
      window.showProgressbar();
      await publishTemplate(
        Id,
        { Status: Type.TemplateStatus.Publish },
        publishCallback
      );
      window.hideProgressbar();
    };

    const publishCallback = (isError: boolean, message: string) => {
      if (isError) {
        window.showGenericAlertDialog(undefined, t(message));
      } else {
        window.showGenericAlertDialog(undefined, t(message), [
          {
            title: "Ok",
            fn: getTemplates,
          },
        ]);
      }
    };

    window.showConfirmationDialog(
      undefined,
      t("PublishConfirmetionMsg"),
      publish
    );
  };

  const UnpublishClickHandler = (Id: string) => {
    const unpublish = async () => {
      window.showProgressbar();
      await unpublishTemplate(
        Id,
        { Status: Type.TemplateStatus.Unpublish },
        unpublishCallback
      );
      window.hideProgressbar();
    };

    const unpublishCallback = (isError: boolean, message: string) => {
      if (isError) {
        window.showGenericAlertDialog(undefined, t(message));
      } else {
        window.showGenericAlertDialog(undefined, t(message), [
          { title: "Ok", fn: getTemplates },
        ]);
      }
    };

    window.showConfirmationDialog(
      undefined,
      t("UnpublishConfirmetionMsg"),
      unpublish
    );
  };

  useEffect(() => {
    getTemplates();
  }, []);

  // const authContext = useContext(AuthContext);
  // const updateData = ()=>{
  //   templates?.map((row)=>{
  //     let CreatedBy={}
  //     updateTemplate(
  //       row.Id,
  //       {
  //         CreatedBy: {
  //           Id: authContext?.getUserDatail?.Id,
  //           Name: authContext?.getUserDatail?.Name,
  //           Mobile: authContext?.getUserDatail?.Mobile,
  //           Email: authContext?.getUserDatail?.Email,
  //         },
  //         ModifyBy: {
  //           Id: authContext?.getUserDatail?.Id,
  //           Name: authContext?.getUserDatail?.Name,
  //           Mobile: authContext?.getUserDatail?.Mobile,
  //           Email: authContext?.getUserDatail?.Email,
  //         },
  //         Sequence: 0,
  //         Weightage:0,
  //       },
  //       null
  //     );
  //   })
  // }




  // const [show, setShow] = useState(false);
  // const [newVideoLink, setNewVideoLink] = useState('');
  // const [newSlider1Heading, setNewSlider1Heading] = useState('');
  // const [newSlider2Heading, setNewSlider2Heading] = useState('');
  // const [newSlider3Heading, setNewSlider3Heading] = useState('');
  // const [slider1Content, setSlider1Content] = useState('');
  // const [slider2Content, setSlider2Content] = useState('');
  // const [slider3Content, setSlider3Content] = useState('');
  // const [video_right_content, setvideo_right_content] = useState('');

  // const handleClose = () => {
  //   setShow(false);
  // };
  
  // const handleShow = () => {
  //   setShow(true);
  // };
  

  // const handleSaveChanges = async () => {
  //   const db = getFirestore(); // Initialize Firestore
  //   const LandingPageContentCollection = collection(db, 'Landing Page Content'); // Specify the collection where you want to save the data

  //   // Add a new document to the 'videos' collection with the new video link and the current date
  //   await addDoc(LandingPageContentCollection, {
  //     slider1Heading: newSlider1Heading,
  //     slider1Content: slider1Content,
  //     slider2Heading: newSlider2Heading,
  //     slider2Content: slider2Content,
  //     slider3Heading: newSlider3Heading,
  //     slider3Content: slider3Content,
  //     video_right_content: video_right_content,
  //     videoLink: newVideoLink,
  //     updatedDate: new Date()
  //   });

  //   console.log('Data saved to Firestore');
  //   handleClose();
  // };

  const [show, setShow] = useState(false);
  const [newVideoLink, setNewVideoLink] = useState('');
  const [newSlider1Heading, setNewSlider1Heading] = useState('');
  const [newSlider2Heading, setNewSlider2Heading] = useState('');
  const [newSlider3Heading, setNewSlider3Heading] = useState('');
  const [slider1Content, setSlider1Content] = useState('');
  const [slider2Content, setSlider2Content] = useState('');
  const [slider3Content, setSlider3Content] = useState('');
  const [video_right_content, setvideo_right_content] = useState('');

  const handleClose = () => {
    setShow(false);
  };
  
  const handleShow = () => {
    setShow(true);
    // Fetch the latest content from Firestore and populate the fields
    fetchLatestContent();
  };

  const fetchLatestContent = async () => {
    try {
      const db = getFirestore();
      const landingPageContentCollection = collection(db, 'Landing Page Content');
      const querySnapshot = await getDocs(landingPageContentCollection);
      if (!querySnapshot.empty) {
        const latestData = querySnapshot.docs[0].data();
        setNewVideoLink(latestData.videoLink);
        setNewSlider1Heading(latestData.slider1Heading);
        setSlider1Content(latestData.slider1Content);
        setNewSlider2Heading(latestData.slider2Heading);
        setSlider2Content(latestData.slider2Content);
        setNewSlider3Heading(latestData.slider3Heading);
        setSlider3Content(latestData.slider3Content);
        setvideo_right_content(latestData.video_right_content);
      }
    } catch (error) {
      console.error('Error fetching latest content:', error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const db = getFirestore();
      const landingPageContentCollection = collection(db, 'Landing Page Content');
      
      // Query the existing document
      const querySnapshot = await getDocs(landingPageContentCollection);
      const docId = querySnapshot.docs[0].id; // Assuming you have only one document

      // Update the existing document
      await updateDoc(doc(landingPageContentCollection, docId), {
        slider1Heading: newSlider1Heading,
        slider1Content: slider1Content,
        slider2Heading: newSlider2Heading,
        slider2Content: slider2Content,
        slider3Heading: newSlider3Heading,
        slider3Content: slider3Content,
        video_right_content: video_right_content,
        videoLink: newVideoLink,
        updatedDate: new Date()
      });

      console.log('Data updated in Firestore');
      handleClose();
    } catch (error) {
      console.error('Error updating content:', error);
    }
  };

  // useEffect hook to fetch the latest content when the modal is opened
  useEffect(() => {
    if (show) {
      fetchLatestContent();
    }
  }, [show]);

  return (
    <Layout menuRoute={AuthoriseUri.Template}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item container xs={12}>
         {/*  <Grid item xs={12}>
            <Button variant="contained" onClick={updateData}>
              Update
            </Button>
          </Grid> */}
          <Grid className="template_table_left" item xs={12} md={3}>
            {/* <TextField
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={t("SearchByName")}
            />
            <Button sx={{ ml: 1 }} variant="contained" onClick={searchHandler}>
              {t("Search")}
            </Button> */}
            <h3 className="template_head_main">Template Management</h3>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            className="searchbar_main"
            alignContent="right"
            sx={{ textAlign: "end" }}
          >
            {/* <div className="searchBar">
            <TextField
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder={t("SearchByName")}
              className="search_bar"
            />
            <Button sx={{ ml: 1 }} className="search_btn" variant="contained" onClick={searchHandler}>
              {t("Search")}
            </Button>
            </div> */}
            <div className="searchBar">
            <input
              id="searchQueryInput"
              type="text"
              name="searchQueryInput"
              placeholder={t("Search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search_bar"
                      />
            <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit" className="search_btn" onClick={searchHandler}>
              <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
                <path fill="#666666" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
              </svg>
            </button>
            </div>

            <div>
              <Button variant="contained" className="updatevideoBtn" onClick={handleShow}>
                Update Content
              </Button>

              <Modal className="videoDemo" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Update Landing Page Content</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="contents_update">
                    <div className="slider_content">
                        <div className="slider_head_main">
                          <p>Slider Content</p>
                          <hr/>
                        </div>
                        <div className="slider_1_content">
                          <div className="slider_1_heading">
                          <TextField
                            label="Slider 1 Heading" 
                            variant="outlined"
                            fullWidth
                            value={newSlider1Heading}
                            onChange={(e) => setNewSlider1Heading(e.target.value)}
                          />
                          </div>
                          <div className="slider_1_below_head">
                            <p className="slider_1_head">Slide 1 Content</p>
                            <TextArea
                            rows={5}
                            className="slider_1_head_content"
                            value={slider1Content}
                            onChange={(e) => setSlider1Content(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* Slider 1 content End */}
                        <div className="slider_1_content">
                          <div className="slider_1_heading">
                          <TextField
                            label="Slider 2 Heading" 
                            variant="outlined"
                            fullWidth
                            value={newSlider2Heading}
                            onChange={(e) => setNewSlider2Heading(e.target.value)}
                          />
                          </div>
                          <div className="slider_1_below_head">
                            <p className="slider_1_head">Slide 2 Content</p>
                            <TextArea
                            rows={5}
                            className="slider_2_head_content"
                            value={slider2Content}
                            onChange={(e) => setSlider2Content(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* Slider 2 content End */}
                        <div className="slider_1_content">
                          <div className="slider_1_heading">
                          <TextField
                            label="Slider 3 Heading" 
                            variant="outlined"
                            fullWidth
                            value={newSlider3Heading}
                            onChange={(e) => setNewSlider3Heading(e.target.value)}
                          />
                          </div>
                          <div className="slider_1_below_head">
                            <p className="slider_1_head">Slide 3 Content</p>
                            <TextArea
                            rows={5}
                            className="slider3_head_content"
                            value={slider3Content}
                            onChange={(e) => setSlider3Content(e.target.value)}
                            />
                          </div>
                        </div>
                        {/* Slider 3 content End */}
                      </div>

                      <div className="video_section_content">
                        <div className="video_section_head">
                          <p>Video Section Content</p>
                          <hr/>
                        </div>
                        <div className="video_section_content_main">
                          <div className="video_link">
                          <TextField
                            label="New Video Link"
                            variant="outlined"
                            fullWidth
                            value={newVideoLink}
                            onChange={(e) => setNewVideoLink(e.target.value)}
                          />
                          </div>
                          <div className="video_right_content">
                          <p className="slider_1_head">About Magic CV Content</p>
                          <TextArea
                            rows={7}
                            className="video_right_content"
                            value={video_right_content}
                            onChange={(e) => setvideo_right_content(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="contained" className="videoModalbtn" onClick={handleClose}>
                    Close
                  </Button>
                  <Button variant="contained" className="videoModalbtn"  onClick={handleSaveChanges} style={{marginLeft: '15px'}}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>

              
            <Button
              variant="contained"
              onClick={() => {
                setforEditTemplate(null);
                openAddFormClick();
              }}
              className="createTemplate_btn"
            >
              {t("CreateTemplate")}
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
        <Grid item xs={12}>
          {createTable(searchResultTemplate)}
        </Grid>
        <Grid item xs={12}>
          <Dialog
            className="addTemplateDialog"
            fullScreen
            open={openAddform}
            onClose={closeAddFormClick}
            TransitionComponent={Transition}
          >
            <AppBar className="addTemplateDialog_main" sx={{ position: "relative" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={closeAddFormClick}
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <DialogContent>
              <AddTemplate
                TemplateId={forEditTemplate?.Id}
                TemplateDetail={{
                  Name: forEditTemplate?.Name,
                  Template: forEditTemplate?.Template,
                  Status: forEditTemplate?.Status,
                  Sequence: forEditTemplate?.Sequence,
                  Weightage: forEditTemplate?.Weightage,
                }}
                CloseAndResetParent={() => {
                  closeAddFormClick();
                  getTemplates();
                }}
              />
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Template;
