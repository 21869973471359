import React, { useState, useContext } from "react";
import {
  ResumeBuilderModel,
} from "../model/ResumeBuilderModel";
import { useTranslation } from "react-i18next";
import { getUserAllResume, deleteUserResume } from "../service/firebase";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Menu,
  MenuItem,
  Divider,
  Alert,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { GetFormattedDate } from "../Utility/common";
import { AuthContext } from "../context/AuthContext";
import * as Type from "../Utility/typeDef";
import "../myStyles/UserAllResumeStyle.css";
import { useHistory } from "react-router-dom";
import { AuthoriseUri, UnauthoriseUri } from "../Utility/PageUrls";
import { overflow } from "html2canvas/dist/types/css/property-descriptors/overflow";

const UserAllResume: React.FC<Type.IUserAllResume> = (props) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const user = authContext.getSessionUser() as any;
  const [count,setCount] = useState(0);
  const [userAlltemplats, setUserAllTeplate] = useState<ResumeBuilderModel[]>(
    props.UserResumes as ResumeBuilderModel[]
  );
  const [contextanchorEl, setContextanchorEl] = React.useState<any[]>();
  const history = useHistory();

  const setResumeElementVisibility = (element: HTMLCollectionOf<Element>, show: boolean) => {
     if (element.length > 0) {
       (element[0] as HTMLElement).style.display = show ? "" : "none";
     }
  };

  const populateValue =  () => {
    const updateValue= async()=>{
            let userTemplate =
              document.getElementsByClassName("userTemplateView1");

            if (userAlltemplats) {
              for (let i = 0; i < userTemplate.length; i++) {
                let templateView = userTemplate[i];

                let updatePofile = async () => {
                  let profileHeader =
                    templateView?.getElementsByClassName("profileHeader");
                  let profileName =
                    templateView?.getElementsByClassName("profileName");
                  let profileSubtitle =
                    templateView?.getElementsByClassName("profileSubtitle");
                  let profileDoB =
                    templateView?.getElementsByClassName("profileDoB");
                  let profileMobile =
                    templateView?.getElementsByClassName("profileMobile");
                  let profileEmail =
                    templateView?.getElementsByClassName("profileEmail");
                  let profileWebsite =
                    templateView?.getElementsByClassName("profileWebsite");
                  let profileAddress =
                    templateView?.getElementsByClassName("profileAddress");

                  if (profileHeader !== undefined && profileHeader.length > 0) {
                    profileHeader[0].innerHTML = userAlltemplats[i].Profile
                      .Header as string;
                  }
                  if (profileName !== undefined && profileName.length>0) {
                    profileName[0].innerHTML = userAlltemplats[i].Profile
                      .Name as string;
                  }

                  if (profileSubtitle !== undefined && profileSubtitle.length > 0) {
                    profileSubtitle[0].innerHTML = userAlltemplats[i].Profile
                      .Subtitle as string;
                  }

                  if (profileDoB !== undefined && profileDoB.length > 0) {
                    profileDoB[0].innerHTML = GetFormattedDate(
                      userAlltemplats[i].Profile.DateOfBirth
                    );
                  }

                  if (profileMobile !== undefined && profileMobile.length > 0) {
                    profileMobile[0].innerHTML = userAlltemplats[i].Profile
                      .PhoneNumber as string;
                  }

                  if (profileEmail !== undefined && profileEmail.length > 0) {
                    profileEmail[0].innerHTML = userAlltemplats[i].Profile
                      .Email as string;
                  }

                  if (profileWebsite !== undefined && profileWebsite.length>0) {
                    profileWebsite[0].innerHTML = userAlltemplats[i].Profile
                      .Website as string;
                  }

                  if (profileAddress !== undefined && profileAddress.length > 0) {
                    profileAddress[0].innerHTML = userAlltemplats[i].Profile
                      .Address as string;
                  }
                };

                let updateObjective = async () => {
                  let objectiveHeader =
                    templateView?.getElementsByClassName("objectiveHeader");
                  let objectiveSummary =
                    templateView?.getElementsByClassName("objectiveSummary");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("objective"),
                    userAlltemplats[i].Objective.IsObjectiveThere as boolean
                  );

                  if (
                    objectiveHeader !== undefined &&
                    objectiveHeader.length > 0
                  ) {
                    objectiveHeader[0].innerHTML = userAlltemplats[i].Objective
                      .Header as string;
                  }
                  if (
                    objectiveSummary !== undefined &&
                    objectiveSummary.length > 0
                  ) {
                    objectiveSummary[0].innerHTML = userAlltemplats[i].Objective
                      .Summary as string;
                  }
                };

                let updateSkill = async () => {
                  let skillHeader =
                    templateView?.getElementsByClassName("skillHeader");
                  let skillTemplate =
                    templateView?.getElementsByClassName("skillTemplate");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("skill"),
                    userAlltemplats[i].Skill.IsSkillThere as boolean
                  );

                  if (skillHeader !== undefined && skillHeader.length > 0) {
                    skillHeader[0].innerHTML = userAlltemplats[i].Skill
                      .Header as string;
                  }

                  if (skillTemplate !== undefined && skillTemplate.length > 0) {
                    let arrayLength: number = (
                      userAlltemplats[i].Skill.Skills
                        ? userAlltemplats[i].Skill?.Skills?.length
                        : 0
                    ) as number;
                    let injectPoint = skillTemplate[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".skillTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          skillTemplate[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let skillName =
                          cloneElement.querySelector(".skillName");
                        let skillLevel =
                          cloneElement.querySelector(".skillLevel");
                        if (skillName !== null) {
                          skillName.innerHTML = userAlltemplats[i].Skill
                            .Skills![x].Name as string;
                        }
                        if (skillLevel !== null) {
                          skillLevel.innerHTML = userAlltemplats[i].Skill
                            ?.Skills![x].Level as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                let updateWorkingExpe = async () => {
                  let header = templateView?.getElementsByClassName(
                    "workExperienceHeader"
                  );
                  let template = templateView?.getElementsByClassName(
                    "workExperienceTemplate"
                  );

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("workExperience"),
                    userAlltemplats[i].WorkExperience.IsWorkExperienceThere as boolean
                  );

                  if (header !== undefined && header.length > 0) {
                    header[0].innerHTML = userAlltemplats[i].WorkExperience
                      .Header as string;
                  }

                  if (template !== undefined && template.length > 0) {
                    let arrayLength = (
                      userAlltemplats[i].WorkExperience.WorkHistory
                        ? userAlltemplats[i].WorkExperience?.WorkHistory?.length
                        : 0
                    ) as number;
                    let injectPoint = template[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".workExperienceTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          template[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let companyName = cloneElement.querySelector(
                          ".WorkExperienceCompanyName"
                        );
                        let position = cloneElement.querySelector(
                          ".workExperiencePosition"
                        );
                        let website = cloneElement.querySelector(
                          ".wokExperienceWebsite"
                        );
                        let Doj =
                          cloneElement.querySelector(".workExperienceDoj");
                        let Dol =
                          cloneElement.querySelector(".workExperienceDoL");
                        let summary = cloneElement.querySelector(
                          ".workExperienceSummary"
                        );
                        if (companyName !== null ) {
                          companyName.innerHTML = userAlltemplats[i]
                            .WorkExperience.WorkHistory![x].Company as string;
                        }

                        if (position !== null) {
                          position.innerHTML = userAlltemplats[i].WorkExperience
                            .WorkHistory![x].Position as string;
                        }

                        if (website !== null) {
                          website.innerHTML = userAlltemplats[i].WorkExperience
                            .WorkHistory![x].Website as string;
                        }

                        if (Doj !== null) {
                          Doj.innerHTML = GetFormattedDate(
                            userAlltemplats[i].WorkExperience.WorkHistory![x]
                              .StartDate
                          );
                        }

                        if (Dol !== null) {
                          Dol.innerHTML = GetFormattedDate(
                            userAlltemplats[i].WorkExperience.WorkHistory![x]
                              .EndDate
                          );
                        }

                        if (summary !== null) {
                          summary.innerHTML = userAlltemplats[i].WorkExperience
                            .WorkHistory![x].Summary as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                let updateEducation = async () => {
                  let header =
                    templateView?.getElementsByClassName("educationHeader");
                  let template =
                    templateView?.getElementsByClassName("educationTemplate");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("education"),
                    userAlltemplats[i].Education.IsEducationThere as boolean
                  );  

                  if (header !== undefined && header.length >0) {
                    header[0].innerHTML = userAlltemplats[i].Education
                      .Header as string;
                  }

                  if (template !== undefined && template.length > 0) {
                    let arrayLength = (
                      userAlltemplats[i].Education.AcedemyDetails
                        ? userAlltemplats[i].Education?.AcedemyDetails?.length
                        : 0
                    ) as number;
                    let injectPoint = template[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".educationTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          template[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let name = cloneElement.querySelector(
                          ".educationInstitutionName"
                        );
                        let fieldOfStudy = cloneElement.querySelector(
                          ".educationFieldOfStudy"
                        );
                        let typeOfDegree = cloneElement.querySelector(
                          ".educationTypeOfDegree"
                        );
                        let Gpa = cloneElement.querySelector(".educationGpa");
                        let Doj = cloneElement.querySelector(
                          ".educationStartDate"
                        );
                        let Dol =
                          cloneElement.querySelector(".educationEndDate");
                        let summary =
                          cloneElement.querySelector(".educationSummary");
                        if (name !== null) {
                          name.innerHTML = userAlltemplats[i].Education
                            .AcedemyDetails![x].Institution as string;
                        }

                        if (fieldOfStudy !== null) {
                          fieldOfStudy.innerHTML = userAlltemplats[i].Education
                            .AcedemyDetails![x].FieldOfStudy as string;
                        }

                        if (typeOfDegree !== null) {
                          typeOfDegree.innerHTML = userAlltemplats[i].Education
                            .AcedemyDetails![x].TypeOfDegree as string;
                        }

                        if (Gpa !== null) {
                          Gpa.innerHTML = userAlltemplats[i].Education
                            .AcedemyDetails![x].MarkInPercentage as string;
                        }

                        if (Doj !== null) {
                          Doj.innerHTML = GetFormattedDate(
                            userAlltemplats[i].Education.AcedemyDetails![x]
                              .StartDate
                          );
                        }

                        if (Dol !== null) {
                          Dol.innerHTML = GetFormattedDate(
                            userAlltemplats[i].Education.AcedemyDetails![x]
                              .EndDate
                          );
                        }

                        if (summary !== null) {
                          summary.innerHTML = userAlltemplats[i].Education
                            .AcedemyDetails![x].Summary as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                let updateCertification = async () => {
                  let header = templateView?.getElementsByClassName(
                    "certificationHeader"
                  );
                  let template = templateView?.getElementsByClassName(
                    "certificationTemplate"
                  );

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("certification"),
                    userAlltemplats[i].Certification.IsCertificationThere as boolean
                  ); 

                  if (header !== undefined && header.length >0) {
                   header[0].innerHTML = userAlltemplats[i].Certification
                     .Header as string;
                  }

                  if (template !== undefined && template.length > 0) {
                    let arrayLength = (
                      userAlltemplats[i].Certification.CertificationDetails
                        ? userAlltemplats[i].Certification.CertificationDetails
                            ?.length
                        : 0
                    ) as number;
                    let injectPoint = template[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".certificationTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          template[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let title = cloneElement.querySelector(
                          ".certificationTitle"
                        );
                        let issuer = cloneElement.querySelector(
                          ".certificationIssuer"
                        );
                        let Doj = cloneElement.querySelector(
                          ".certificationCompleteDate"
                        );
                        let summary = cloneElement.querySelector(
                          ".certificationSummary"
                        );

                        if (title !== null) {
                          title.innerHTML = userAlltemplats[i].Certification
                            .CertificationDetails![x].Title as string;
                        }

                        if (issuer !== null) {
                          issuer.innerHTML = userAlltemplats[i].Certification
                            .CertificationDetails![x].Issuer as string;
                        }

                        if (Doj !== null) {
                          Doj.innerHTML = GetFormattedDate(
                            userAlltemplats[i].Certification
                              .CertificationDetails![x].Date
                          );
                        }

                        if (summary !== null) {
                          summary.innerHTML = userAlltemplats[i].Certification
                            .CertificationDetails![x].Summary as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                let updateAward = async () => {
                  let header =
                    templateView?.getElementsByClassName("awardHeader");
                  let template =
                    templateView?.getElementsByClassName("awardTemplate");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("award"),
                    userAlltemplats[i].Awards.IsAwardThere as boolean
                  ); 

                  if (header !== undefined && header.length > 0) {
                    header[0].innerHTML = userAlltemplats[i].Awards
                      .Header as string;
                  }

                  if (template !== undefined && template.length > 0) {
                    let arrayLength = (
                      userAlltemplats[i].Awards.AwardsDetails
                        ? userAlltemplats[i].Awards.AwardsDetails?.length
                        : 0
                    ) as number;
                    let injectPoint = template[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".awardTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          template[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let title = cloneElement.querySelector(".awardTitle");
                        let issuer =
                          cloneElement.querySelector(".awardAwarder");
                        let Doj = cloneElement.querySelector(".awardDate");
                        let summary =
                          cloneElement.querySelector(".awardSummary");

                        if (title !== null) {
                          title.innerHTML = userAlltemplats[i].Awards
                            .AwardsDetails![x].Title as string;
                        }

                        if (issuer !== null) {
                          issuer.innerHTML = userAlltemplats[i].Awards
                            .AwardsDetails![x].Awarder as string;
                        }

                        if (Doj !== null) {
                          Doj.innerHTML = GetFormattedDate(
                            userAlltemplats[i].Awards.AwardsDetails![x].Date
                          );
                        }

                        if (summary !== null) {
                          summary.innerHTML = userAlltemplats[i].Awards
                            .AwardsDetails![x].Summary as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                let updateProject = async () => {
                  let header =
                    templateView?.getElementsByClassName("projectHeader");
                  let template =
                    templateView?.getElementsByClassName("projectTemplate");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("project"),
                    userAlltemplats[i].Project.IsProjectThere as boolean
                  ); 

                  if (header !== undefined && header.length > 0) {
                    header[0].innerHTML = userAlltemplats[i].Project
                      .Header as string;
                  }

                  if (template !== undefined && template.length > 0) {
                    let arrayLength = (
                      userAlltemplats[i].Project.ProjectsDetail
                        ? userAlltemplats[i].Project.ProjectsDetail?.length
                        : 0
                    ) as number;
                    let injectPoint = template[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".projectTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          template[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let title = cloneElement.querySelector(".projectTitle");
                        let website =
                          cloneElement.querySelector(".projectWebsite");
                        let startDate =
                          cloneElement.querySelector(".projectStartDate");
                        let endDate =
                          cloneElement.querySelector(".projectEndDate");
                        let summary =
                          cloneElement.querySelector(".projectSummary");

                        if (title !== null) {
                          title.innerHTML = userAlltemplats[i].Project
                            .ProjectsDetail![x].Title as string;
                        }

                        if (website !== null) {
                          website.innerHTML = userAlltemplats[i].Project
                            .ProjectsDetail![x].Website as string;
                        }

                        if (startDate !== null) {
                          startDate.innerHTML = GetFormattedDate(
                            userAlltemplats[i].Project.ProjectsDetail![x]
                              .StartDate
                          );
                        }

                        if (endDate !== null) {
                          endDate.innerHTML = GetFormattedDate(
                            userAlltemplats[i].Project.ProjectsDetail![x]
                              .EndDate
                          );
                        }

                        if (summary !== null) {
                          summary.innerHTML = userAlltemplats[i].Project
                            .ProjectsDetail![x].Summary as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                let updateLanguage = async () => {
                  let header =
                    templateView?.getElementsByClassName("languageHeader");
                  let template =
                    templateView?.getElementsByClassName("languageTemplate");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("language"),
                    userAlltemplats[i].Language.IsLanguageThere as boolean
                  );

                  if (header !== undefined && header.length > 0) {
                    header[0].innerHTML = userAlltemplats[i].Language
                      .Header as string;
                  } 

                  if (template !== undefined && template.length > 0) {
                   let arrayLength = (
                     userAlltemplats[i].Language.LanguageDetails
                       ? userAlltemplats[i].Language.LanguageDetails?.length
                       : 0
                   ) as number;
                   let injectPoint = template[0]
                     .closest(".userTemplateView1")!
                     .querySelector(".languageTemplateInjectPoint");
                   if (injectPoint !== null) {
                     injectPoint.innerHTML = "";

                     for (let x = 0; x < arrayLength; x++) {
                       let cloneElement: HTMLElement = (
                         template[0] as HTMLTemplateElement
                       ).content.cloneNode(true) as HTMLElement;

                       let name = cloneElement.querySelector(".languageName");
                       let level = cloneElement.querySelector(".languageLevel");

                       if (name !== null) {
                         name.innerHTML = userAlltemplats[i].Language
                           .LanguageDetails![x].Name as string;
                       }

                       if (level !== null) {
                         level.innerHTML = userAlltemplats[i].Language
                           .LanguageDetails![x].Fluency as string;
                       }

                       injectPoint.append(cloneElement);
                     }
                   }
                  }
                };

                let updateHobby = async () => {
                  let header =
                    templateView?.getElementsByClassName("hobbyHeader");
                  let template =
                    templateView?.getElementsByClassName("hobbyTemplate");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("hobby"),
                    userAlltemplats[i].Hobby.IsHobbyThere as boolean
                  ); 

                  if (header !== undefined && header.length > 0) {
                     header[0].innerHTML = userAlltemplats[i].Hobby
                       .Header as string;
                  }

                  if (template !== undefined && template.length > 0) {
                    let arrayLength = (
                      userAlltemplats[i].Hobby.Hobbies
                        ? userAlltemplats[i].Hobby.Hobbies?.length
                        : 0
                    ) as number;
                    let injectPoint = template[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".hobbyTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          template[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let name = cloneElement.querySelector(".hobbyName");

                        if (name !== null) {
                          name.innerHTML = userAlltemplats[i].Hobby.Hobbies![x]
                            .Name as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                let updateReference = async () => {
                  let header =
                    templateView?.getElementsByClassName("referenceHeader");
                  let template =
                    templateView?.getElementsByClassName("referenceTemplate");

                  setResumeElementVisibility(
                    templateView?.getElementsByClassName("reference"),
                    userAlltemplats[i].Reference.IsReferenceThere as boolean
                  ); 

                  if (header !== undefined && header.length > 0) {
                   header[0].innerHTML = userAlltemplats[i].Reference
                     .Header as string;
                  }

                  if (template !== undefined && template.length > 0) {
                    let arrayLength = (
                      userAlltemplats[i].Reference.ReferencesDetail
                        ? userAlltemplats[i].Reference.ReferencesDetail?.length
                        : 0
                    ) as number;
                    let injectPoint = template[0]
                      .closest(".userTemplateView1")!
                      .querySelector(".referenceTemplateInjectPoint");
                    if (injectPoint !== null) {
                      injectPoint.innerHTML = "";

                      for (let x = 0; x < arrayLength; x++) {
                        let cloneElement: HTMLElement = (
                          template[0] as HTMLTemplateElement
                        ).content.cloneNode(true) as HTMLElement;

                        let name = cloneElement.querySelector(".referenceName");
                        let email =
                          cloneElement.querySelector(".referenceEmail");
                        let mobile =
                          cloneElement.querySelector(".referenceMobile");
                        let position =
                          cloneElement.querySelector(".referencePosition");
                        let summary =
                          cloneElement.querySelector(".referenceSummary");

                        if (name !== null) {
                          name.innerHTML = userAlltemplats[i].Reference
                            .ReferencesDetail![x].Name as string;
                        }

                        if (email !== null) {
                          email.innerHTML = userAlltemplats[i].Reference
                            .ReferencesDetail![x].Email as string;
                        }

                        if (mobile !== null) {
                          mobile.innerHTML = userAlltemplats[i].Reference
                            .ReferencesDetail![x].PoneNumber as string;
                        }

                        if (position !== null) {
                          position.innerHTML = userAlltemplats[i].Reference
                            .ReferencesDetail![x].Position as string;
                        }

                        if (summary !== null) {
                          summary.innerHTML = userAlltemplats[i].Reference
                            .ReferencesDetail![x].Summary as string;
                        }

                        injectPoint.append(cloneElement);
                      }
                    }
                  }
                };

                await Promise.all([
                  updatePofile(),
                  updateObjective(),
                  updateSkill(),
                  updateWorkingExpe(),
                  updateEducation(),
                  updateCertification(),
                  updateAward(),
                  updateProject(),
                  updateLanguage(),
                  updateHobby(),
                  updateReference(),
                ]);
              }
            }

    }
    updateValue();

  };

  const openMenuHandler = (event: any, index: number) => {
    let arr: any[] = [];
    userAlltemplats?.map(() => arr.push(null));
    arr[index] = event.currentTarget;
    setContextanchorEl(arr);
  };

  const closeMenuHandler = () => {
    let arr: any[] = [];
    userAlltemplats?.map(() => arr.push(null));
    setContextanchorEl(arr);
  };

  React.useEffect(() => {
    setUserAllTeplate(props.UserResumes as ResumeBuilderModel[]);
  }, [props.UserResumes]);
  
  return (
    <Box sx={{ width: "100%" }}>
      {userAlltemplats != null && userAlltemplats.length > 0 && (
        <Typography variant="h6">{t("DashboardUserTemplateHeader")}</Typography>
      )}
      <br />
      <Grid
        container
        spacing={3}
        sx={{
          pr: { md: "10px" },
        }}
      >
        {userAlltemplats?.map((item, index) => {
          let totalTemplate = userAlltemplats.length;
          let createDate = item.ModifyDate as any;
          let resumeTemp = props.Template?.filter(
            (x) => x.Id == item.TemplateId
          );
          let anchorEl =
            contextanchorEl && contextanchorEl.length >= index
              ? contextanchorEl[index]
              : null;

          window.setTimeout(() => populateValue());
          return (
            resumeTemp && (
              <Grid item xs={12} key={index}>
                <Card>
                  <CardHeader
                    action={
                      <React.Fragment>
                        <IconButton
                          aria-label="settings"
                          onClick={(e) => openMenuHandler(e, index)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={(e) => openMenuHandler(e, index)}
                          onClick={(e) => closeMenuHandler()}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              minWidth: "150px",
                              overflow: "visible",
                              filter:
                                "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                              mt: 1.5,
                              "& .MuiAvatar-root": {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                              },
                              "&:before": {
                                content: '""',
                                display: "block",
                                position: "absolute",
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: "background.paper",
                                transform: "translateY(-50%) rotate(45deg)",
                                zIndex: 0,
                              },
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              history.push({
                                pathname: AuthoriseUri.Resume,
                                state: item,
                              });
                            }}
                          >
                            {t("Edit")}
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    }
                    title={
                      <Typography variant="subtitle2">
                        {item.Profile.Name}
                      </Typography>
                    }
                    subheader={
                      <Typography variant="subtitle2">
                        {t("ModifyDate") +
                          ": " +
                          GetFormattedDate(createDate.toDate())}
                        <Divider />
                      </Typography>
                    }
                  ></CardHeader>
                  <CardContent sx={{ pt: 0, paddingBottom: "16px !important" }}>
                    <div className="userAllResumeDiv1">
                      {resumeTemp.length > 0 && (
                        <div
                          className="userTemplateView1"
                          key={index}
                          data-index={index}
                          dangerouslySetInnerHTML={{
                            __html: resumeTemp[0].Template,
                          }}
                        ></div>
                      )}

                      {resumeTemp.length == 0 && (
                        <Alert severity="warning">
                          {t("SelectedTemplateRemovedInfo")}
                        </Alert>
                      )}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            )
          );
        })}
      </Grid>
    </Box>
  );
};

export default UserAllResume;
