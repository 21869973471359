import React, { useEffect, useRef, useState } from 'react'
import * as Type from '../Utility/typeDef';
import { useTranslation } from "react-i18next";
import {FilledTextFieldProps, Grid,OutlinedTextFieldProps,StandardTextFieldProps,TextField, TextFieldVariants} from "@mui/material";
//import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import DateAdapter from "@mui/lab/AdapterDateFns";
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import '../myStyles/inputInformation.css';
import { format } from 'date-fns';

//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const PersonalInfo:React.FC<Type.IPersonalInfo> =(props)=> {
  const { t } = useTranslation();
  console.log("PersonalInfo");
  
    return (
      <React.Fragment>
        <Grid className='personalinfo_main' container spacing={2}>
          <Grid item xs={12} className='personalInfo_div'>
            <TextField
              label={t("Header")}
              id="Profile_Header"
              size="small"
              className='resume_input'
              fullWidth={true}
              name="Profile.Header"
              value={props.Header.value}
              onChange={props.onChangeHandler}
              onBlur={props.onBlurHandler}
              error={props.Header.isError}
              helperText={props.Header.errorMessage}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} className='personalInfo_div'>
            <TextField
              label={t("Name")}
              variant="outlined"
              size="small"
              fullWidth
              className='resume_input'
              name="Profile.Name"
              value={props.Name.value}
              onChange={props.onChangeHandler}
              onBlur={props.onBlurHandler}
              error={props.Name.isError}
              helperText={props.Name.errorMessage}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} className='personalInfo_div'>
            <TextField
              id="Profile_Subtitle"
              label={t("Subtitle")}
              variant="outlined"
              className='resume_input'
              size="small"
              fullWidth
              name="Profile.Subtitle"
              value={props.Subtitle.value}
              onChange={props.onChangeHandler}
              onBlur={props.onBlurHandler}
              error={props.Subtitle.isError}
              helperText={props.Subtitle.errorMessage}
            />
          </Grid>
          <Grid item xs={12} md={6} className='personalInfo_div'>
          {console.log('Date of Birth:', props.DOB.value)}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
            className='resume_input DOB'
              label={t("DateOfBirth")}
              format="dd/MM/yyyy"
              value={props.DOB.value}
              onChange={(newValue: any) => {
                props.SetValueByPropNameHandler(
                  "Profile.DateOfBirth",
                  newValue
                );
              }}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={6} className='personalInfo_div'>
            <TextField
              id="Profile_PhoneNumber"
              label={t("Mobile")}
              variant="outlined"
              size="small"
              fullWidth
              className='resume_input'
              name="Profile.PhoneNumber"
              value={props.Mobile.value}
              onChange={props.onChangeHandler}
              onBlur={props.onBlurHandler}
              error={props.Mobile.isError}
              helperText={props.Mobile.errorMessage}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} className='personalInfo_div'>
            <TextField
              id="Profile_Email"
              label={t("Email")}
              variant="outlined"
              size="small"
              fullWidth
              className='resume_input'
              name="Profile.Email"
              value={props.Email.value}
              onChange={props.onChangeHandler}
              onBlur={props.onBlurHandler}
              error={props.Email.isError}
              helperText={props.Email.errorMessage}
              required
            />
          </Grid>
          <Grid item xs={12} md={6} className='personalInfo_div'>
            <TextField
              id="Profile_Website"
              label={t("Website")}
              variant="outlined"
              size="small"
              fullWidth
              className='resume_input'
              name="Profile.Website"
              value={props.Website.value}
              onChange={props.onChangeHandler}
              onBlur={props.onBlurHandler}
              error={props.Website.isError}
              helperText={props.Website.errorMessage}
            />
          </Grid>
          <Grid item xs={12} className='personalInfo_div'>
            <TextField
              id="Profile_Address"
              label="Address"
              variant="outlined"
              fullWidth
              multiline
              className='resume_input address_profile'
              rows={3}
              name="Profile.Address"
              value={props.Address.value}
              onChange={props.onChangeHandler}
              onBlur={props.onBlurHandler}
              error={props.Address.isError}
              helperText={props.Address.errorMessage}
              required
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
}

export default React.memo(PersonalInfo);


