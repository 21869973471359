import React, { useState, useContext } from "react";
import {
  ForgotPasswordModel,
  ForgotPasswordSchema,
} from "../model/ForgotPasswordModel";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import LoginMainImage from '../images/LoginMain.svg';
import MagicCVLogo from '../images/Magic CV.svg';
import '../myStyles/loginPage.css';

function ForgotPassword() {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [hasMailSend,setHasMailSend]= useState(false);
  const [apiError, setApiError] = useState("");
  const { t } = useTranslation();
  let model: ForgotPasswordModel = { email: "" };

  const afterForgotPasswordHandler = (
    isError: boolean,
    message: string
  ): void => {
    if (isError) {
      setApiError(t(message as string));
    } else {
        setHasMailSend(true);
    }
  };

  const formik = useFormik({
    initialValues: model,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values) => {
      setApiError("");
      
      authContext.sendResetPasswordEmail(
        values.email,
        afterForgotPasswordHandler
      );
    
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{ }}
    >
      <Grid container full-height item md={12} sm={12} display="block">
      <div className="loginMainContainer">
        <Grid item md={6} sm={6} className="col col-12 col-md-6 right">
        <Hidden smDown>
          <div className="inner text-center">
            <div className="right_image ds-vertical-align" style={{ position: 'relative', alignItems: 'center' }}>
              <img className=" login_right" src={LoginMainImage} alt="not found" />
            </div>
          </div>
          </Hidden>
        </Grid>
        <Grid item sm={6} md={6} sx={{ px: 4, col: 12 }} className="col col-12 col-md-6 left ds-vertical-align">
          <div className="inner">
          <div className='MagicCVlogo'>
            <a href='#' target="_blank">
                <img src={MagicCVLogo} className='MainLogo' alt='Magic CV Logo'></img>
            </a>
          </div>
          <div className='welcometext' style={{marginBottom: "15px"}}>
            <h3>Forgot Your Password ?</h3>
          </div>
          <form onSubmit={formik.handleSubmit}>
                {apiError !== "" && (
                  <Alert severity="error" sx={{ mb: 3 }}>
                    {apiError}
                  </Alert>
                )}

                {hasMailSend == true ? (
                  <FormGroup row={true} sx={{ mb: 2 }}>
                    <Alert severity="success">
                      {t("ForgotPasswordSuccessMsg")}
                    </Alert>
                    <Alert severity="success">
                      {t("MailInSpanFolderInfo")}
                    </Alert>
                  </FormGroup>
                ) : (
                  <Box>
                    <FormGroup row={true} sx={{ mb: 2 }}>
                    <div className="form-group form_group_12" style={{ marginBottom: '5px' }}>
                <p>Email/Mobile</p>
                <TextField
                        id="loginCode"
                        size="small"
                        fullWidth={true}
                        name="email"
                        className="form-control"
                        placeholder="Enter Email ID or Mobile Number"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={
                          formik.touched.email &&
                          t(formik.errors.email as string)
                        }
                      />
                  </div>  
                    </FormGroup>

                    <FormGroup row={true} sx={{ mb: 2 }}>
                      <Button
                        type="submit"
                        variant="contained"
                        fullWidth={true}
                        className="submit_btn"
                      >
                        {t("Submit")}
                      </Button>
                    </FormGroup>
                  </Box>
                )}
              </form>
              <Divider orientation="horizontal" flexItem sx={{ my: 2 }}>
                {t("OR")}
              </Divider>
              <FormGroup>
                <Button
                  variant="contained"
                  className="back_btn"
                  fullWidth={true}
                  onClick={(e) => history.goBack()}
                >
                  {t("Go Back & Login")}
                </Button>
              </FormGroup>
            </div>
        </Grid>
      </div>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
