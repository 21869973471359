import React,{useContext} from 'react';
import { AuthContext } from '../context/AuthContext';
import {  Route,Redirect} from "react-router-dom";
import { UnauthoriseUri } from "../Utility/PageUrls";

function AuthenticatedRoute({children,...rest}) {
  const authContext = useContext(AuthContext);
    return (
        <Route {...rest}
        render={({location})=>
             authContext.isAuthenticated ?
             (children):
             (
                 <Redirect
                 to={{
                     pathname:UnauthoriseUri.Login,
                     state:{from:location}
                 }}
                 />
             )
        }></Route>
    )
}

export default AuthenticatedRoute
