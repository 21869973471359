import React from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Switch,
  Alert,
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
  TextFieldVariants
} from "@mui/material";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import DateAdapter from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CloseIcon from "@mui/icons-material/Close";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import '../myStyles/inputInformation.css';
import SummaryEditor from "./SummaryEditor";

const Education: React.FC<Type.IEducation> = (props) => {
  const { t } = useTranslation();
  console.log("Education");
  const objectiveToolbar = {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" },
            ],
          ],
  };

  const getErrorString = (index: number, propsName: string): string => {
    if (
      Array.isArray(props.EducationHistoryError) &&
      props.EducationHistoryError[index] !== null &&
      props.EducationHistoryError[index] !== undefined &&
      props.EducationHistoryError[index].hasOwnProperty(propsName)
    ) {
      return t(props.EducationHistoryError[index][propsName]);
    }
    return "";
  };

  const getTouchedValue = (index: number, propsName: string): boolean => {
    if (
      Array.isArray(props.EducationHistoryTouched) &&
      props.EducationHistoryTouched[index] !== null &&
      props.EducationHistoryTouched[index] !== undefined &&
      props.EducationHistoryTouched[index].hasOwnProperty(propsName)
    ) {
      return props.EducationHistoryTouched[index][propsName];
    }
    return false;
  };

  const noEducationHistoryError =
    Array.isArray(props.EducationHistoryTouched) &&
    props.EducationHistoryTouched.length == 0 &&
    typeof props.EducationHistoryError == "string" &&
    props.EducationHistoryError != undefined ? (
      <Alert severity="error" sx={{ mt: 1 }}>
        {t(props.EducationHistoryError)}
      </Alert>
    ) : (
      <span></span>
    );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="education_div">
          <TextField
            id="Education_Header"
            label={t("Header")}
            className='resume_input'
            variant="outlined"
            size="small"
            fullWidth
            name="Education.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            onBlur={props.onBlurHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
          />
          {noEducationHistoryError}
        </Grid>
        {props.EducationHistory?.map((e, i) => {
          let institutionNameError =
            getTouchedValue(i, "Institution") &&
            getErrorString(i, "Institution");
          let fieldOfStudyError =
            getTouchedValue(i, "FieldOfStudy") &&
            getErrorString(i, "FieldOfStudy");
          let endDateError =
            getTouchedValue(i, "EndDate") && getErrorString(i, "EndDate");
          let startDateError =
            getTouchedValue(i, "StartDate") && getErrorString(i, "StartDate");;  
          return (
            <React.Fragment key={i}>
              <Grid item xs={12} >
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={(e) => props.RemoveEducationHistory(i)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    sx={{ pb: 0, pt: 1 }}
                  />
                  <CardContent sx={{ pt: "1px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} >
                        <TextField
                          label={t("InstitutionName")}
                          variant="outlined"
                          size="small"
                          className='resume_input'
                          fullWidth
                          name={`Education.AcedemyDetails[${i}].Institution`}
                          value={e.Institution}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={institutionNameError}
                          error={Boolean(institutionNameError)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={t("FieldOfStudy")}
                          variant="outlined"
                          size="small"
                          className='resume_input'
                          fullWidth
                          name={`Education.AcedemyDetails[${i}].FieldOfStudy`}
                          value={e.FieldOfStudy}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={fieldOfStudyError}
                          error={Boolean(fieldOfStudyError)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t("TypeOfDegree")}
                          variant="outlined"
                          className='resume_input'
                          size="small"
                          fullWidth
                          name={`Education.AcedemyDetails[${i}].TypeOfDegree`}
                          value={e.TypeOfDegree}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          id="GPA"
                          label={t("GPA")}
                          className='resume_input'
                          variant="outlined"
                          size="small"
                          fullWidth
                          name={`Education.AcedemyDetails[${i}].MarkInPercentage`}
                          value={e.MarkInPercentage}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              name={`Education.AcedemyDetails[${i}].IsPursuing`}
                              checked={e.IsPursuing}
                              onChange={props.onChangeHandler}
                            />
                          }
                          label={t("PursuingCourse")}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className="education_date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={t("StartDate")}
                            format="dd/MM/yyyy"
                            className='resume_input DOB'
                            value={e.StartDate}
                            onChange={(newValue: any) => {
                              props.SetValueByPropNameHandler(
                                `Education.AcedemyDetails[${i}].StartDate`,
                                newValue
                              );
                            }}
                            // renderInput={(params:string) => (
                            //   <TextField
                            //     //{...params}
                            //     size="small"
                            //     fullWidth
                            //     error={Boolean(startDateError)}
                            //     helperText={startDateError}
                            //     required
                            //   />
                            // )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} md={6} className="education_date">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={t("EndDate")}
                            format="dd/MM/yyyy"
                            value={e.EndDate}
                            className='resume_input DOB'
                            disabled={e.IsPursuing}
                            onChange={(newValue: any) => {
                              props.SetValueByPropNameHandler(
                                `Education.AcedemyDetails[${i}].EndDate`,
                                newValue
                              );
                            }}
                            // renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => (
                            //   <TextField
                            //     {...params}
                            //     size="small"
                            //     fullWidth
                            //     error={Boolean(endDateError)}
                            //     helperText={endDateError}
                            //   />
                            // )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <SummaryEditor
                          Summary={e.Summary}
                          ParentArrayLenght={props.EducationHistory?.length}
                          CallbackFun={(html: any) => {
                            props.SetValueByPropNameHandler(
                              `Education.AcedemyDetails[${i}].Summary`,
                              html
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12} className="education_btn">
          <Button
            variant="contained"
            fullWidth
            onClick={props.AddEducationHistrory}
            className="btn_work"
          >
            {t("AddMoreAccademy")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(Education);
