import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthoriseUri,UnauthoriseUri } from "../Utility/PageUrls";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import {
  Hidden,
  Typography,
  Card,
  CardContent,
  Divider,
  FormGroup,
  Button,
} from "@mui/material";

const EmailVerifying:React.FC =()=> {
  const authContext = useContext(AuthContext);
  const [error, setError] = React.useState("");
  const history = useHistory();
  const search = useLocation().search; 
  const { t } = useTranslation();
  const oobCode = new URLSearchParams(search).get("oobCode");

    const afterEmailVerificationHandler = (
      isError: boolean,
      message: string
    ): void => {
      if (isError) {
        setError(t(message));
      } else {
        history.push(AuthoriseUri.Dashboard);
      }
    };

  React.useEffect(()=>{
      if(oobCode)
      {
         authContext.applyActionCode(oobCode, afterEmailVerificationHandler);
      }
      else
      {
         setError(t("InvalidUrl"));
      }
  },[])

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={2}
      sx={{ minHeight: "100vh", backgroundColor: "#EAEEF3" }}
    >
      <Grid container item md={8} sm={12}>
        <Grid item md={6}>
          <Hidden mdDown>
            <Typography variant="h3" sx={{ color: "primary.main" }}>
              Create your resume.
            </Typography>
            <Typography variant="h5">
              Our professionals continue looking industry trends and create best
              resume template for you.
            </Typography>
          </Hidden>
        </Grid>
        <Grid item sm={12} md={6} sx={{ px: 4 }}>
          <Hidden mdUp>
            <Typography variant="h3" sx={{ color: "primary.main" }}>
              Create your resume.
            </Typography>
            <Typography variant="h5">
              Our professionals continue looking industry trends and create best
              resume template for you.
            </Typography>
          </Hidden>
          <Card raised={true}>
            <CardContent>
              {error !== "" && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {error}
                </Alert>
              )}
              <Divider
                orientation="horizontal"
                flexItem
                sx={{ my: 2 }}
              ></Divider>
              <FormGroup>
                <Button
                  variant="contained"
                  color="success"
                  fullWidth={true}
                  onClick={(e) => history.push(UnauthoriseUri.Login)}
                >
                  Go to Login page
                </Button>
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EmailVerifying
