import React, { useContext } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import IconButton from "@mui/material/IconButton";
import Settings from "@mui/icons-material/Settings";
import {  useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Button, Box, Typography, Divider } from "@mui/material";
import { AuthoriseUri, UnauthoriseUri } from "../Utility/PageUrls";
import ChangePassword from "./ChangePassword";
import { useTranslation } from "react-i18next";
import * as pageUrl from "../Utility/PageUrls"
import '../myStyles/layout.css';
import MagicCVLogo from '../images/Magic CV (1).svg';
import { fontWeight } from "@mui/system";

const useStyles = makeStyles((theme: any) => ({
  menu: {
    "& .MuiPaper-root": {
      backgroundColor: "black",
      color: "white",
      width: "320px",
    },
  },
  navigationBar: {
    "& .MuiBottomNavigationAction-label": {
      fontSize: ".90rem",
      color: "#fff",
      border: "2px solid #fff",
      borderRadius : "4px",
      padding: "12px 22px",
      fontWeight: "600"
    },
    "& .css-imwso6-MuiBottomNavigationAction-label.Mui-selected": {
      fontSize: "1.2rem",
      color: "rgb(0 145 201)",
    },
  },
  appLogo: {
    "& .MuiBottomNavigationAction-label": {
      fontWeight: "bold",
      fontFamily: "cursive",
      color: "gray",
      fontSize: "25px",
    },
  },
}));

interface propLayout {
  menuRoute: string;
  children: PropTypes.ReactElementLike
}

function Layout(props: propLayout) {
  const classes = useStyles();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [value, setValue] = React.useState(props.menuRoute);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showChagePassword,setShowChangePassword] = React.useState(false);
  const trigger = useScrollTrigger(); 
  

  const open = Boolean(anchorEl);

  const showChangePasswordClickHandler =()=>{
    setShowChangePassword(true);
  }

  const hideChangePasswordClickHandler=()=>{
    setShowChangePassword(false);
  }

  const settingsClickHandler = (event:any) => {
    setAnchorEl(event.currentTarget);
  };
  const settingsCloseHandler = () => {
    setAnchorEl(null);
  };

  const logout = (isError: boolean, message: string) => {
    if(isError)
    {
      message != "" && window.showDialog("", message);
    }
    else
    {
      history.push(UnauthoriseUri.Login);
    }
  };

  const navigationClickHandler = (event: any, newValue: any) => {
    history.push(newValue);
  };

  let adminMenu = [];
  if (authContext.getUserDatail && authContext.getUserDatail.Role=="admin") {
    adminMenu.push(
      <BottomNavigationAction
      id="template_btn_main"
        label={t("TemplateMainMenu")}
        value={pageUrl.AuthoriseUri.Template}
        className={classes.navigationBar}
      />
    );
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar className="menubar_main" color="inherit">
          <Toolbar className="menubar">
            <Box sx={{ flexGrow: 1 }}>
              <BottomNavigation
                showLabels
                value={value}
                onChange={navigationClickHandler}
                className="menubar_navigationHandler"
                sx={{ justifyContent: "flex-start" }}
              >
                <BottomNavigationAction
                  //label={t("AppHeader")}
                  id="main_logo"
                  icon={<img src={MagicCVLogo}  alt="Your Image" />}
                  value={pageUrl.AuthoriseUri.Dashboard}
                  className={classes.appLogo}
                />
                {/* <BottomNavigationAction
                  label={t("ResumeMainMenu")}
                  value={pageUrl.AuthoriseUri.Resume}
                  className={classes.navigationBar}
                /> */}
                {adminMenu}

                {/* <BottomNavigationAction
                  label={t("AboutMainMenu")}
                  value={pageUrl.AuthoriseUri.About}
                  className={classes.navigationBar}
                /> */}
              </BottomNavigation>
            </Box>
            <Typography
              variant="h6"
              className="header_name"
              sx={{ marginRight: "29px", textAlign: "center" }}
            >Hello,&nbsp;
              {authContext.getUserDatail && authContext.getUserDatail.Name}
              {/* <div style={{ fontSize: "12px" }}>
                {authContext.getUserDatail?.Email}
              </div> */}
            </Typography>
            <IconButton
              //color="primary"
              aria-label="Settings"
              component="span"
              className="layout_setting_btn"
              onClick={settingsClickHandler}
            >
              <Settings />
            </IconButton>
          </Toolbar>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={settingsCloseHandler}
            onClick={settingsCloseHandler}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                //filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  //bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem onClick={showChangePasswordClickHandler}>
              {t("ChangePassword")}
            </MenuItem>
            <MenuItem onClick={() => history.push(AuthoriseUri.Feedback)}>
              {t("Feedback")}
            </MenuItem>
            <Divider />
            <MenuItem onClick={(e) => authContext.signOut(logout)}>
              {t("Logout")}
            </MenuItem>
          </Menu>
        </AppBar>
      </Slide>
      <Toolbar />
      <Box sx={{ pl: 1, pr: 1,  mt: 3 }}>
        {props.children}
      </Box>

      <ChangePassword
        openflag={showChagePassword}
        closeHandler={hideChangePasswordClickHandler}
      />
    </React.Fragment>
  );
}

export default Layout;