const translation_en = {
  AppHeader: "Magic CV",
  LoginCode: "Email",
  Password: "Password",
  Login: "Login",
  Or: "Or",
  LoginWithPassword: "Login with Google",
  ForgotPassword: "Forgot Password",
  Signup: "Sign up",
  SpecifyEmail: "Please specify valid email.",
  SpecifyValidEmail: "Please specify valid email.",
  SpecifyPassword: "Please specify password.",
  InvalidLogincodeAndPassword: "Invalid email or password.",
  Create: "Create",
  Back: "Back",
  Submit: "Submit",
  ForgotPasswordSuccessMsg: `A password reset message was sent to your email address.
                      Please click the link in that message to reset your
                      password.`,
  MailInSpanFolderInfo: `If you do not receive the password reset message within a
                      few momemnts, please check your spam folder or other
                      filtering tools.`,
  UserNotFound: "User not found.",
  EmailAlreadyInUse: "Email already in use.",
  PasswordWeak: "Password must contain at least 6 characters.",
  Opps: "Oops, something went wrong",
  InvalidAction: "Oops, something went wrong. Please try again.",
  ResetLinkExpire:
    "Your request to reset your password has expired or the link has already been used",
  PasswordResetSuccessfulMsg: "Password reset successful.",
  ConfirmPassword: "Confirm Password",
  PasswordMustMatch: "Passwords must match.",
  PersonalInfo: "Personal Information",
  Objective: "Objective",
  Skills: "Skills",
  WorkExperience: "Work Experience",
  Education: "Education",
  Certifications: "Certifications",
  Awards: "Awards",
  Projects: "Projects",
  Languages: "Languages",
  Hobbies: "Hobbies",
  References: "References",
  ResumeHeading: "Resume Heading",
  Header: "Title",
  Name: "Name",
  Subtitle: "Position",
  DateOfBirth: "Date of Birth",
  Mobile: "Mobile No.",
  Email: "Email",
  Website: "Website",
  Skill: "Skill",
  Level: "Level",
  Add: "Add",
  CompanyName: "Company Name",
  Position: "Position",
  JoiningDate: "Joining Date",
  LeavingDate: "Leaving Date",
  CurrentWorking: "Current Company",
  AddMoreWorkHistory: "Add more work history",
  InstitutionName: "Institution Name",
  FieldOfStudy: "Field of Study",
  TypeOfDegree: "Type of Degree",
  PursuingCourse: "Pursuing Course",
  StartDate: "Start Date",
  EndDate: "End Date",
  AddMoreAccademy: "Add more accademy detail",
  AddMoreCertification: "Add more certification",
  Title: "Title",
  Issuer: "Issuer",
  AddMoreAward: "Add more award",
  Awarder: "Awarder",
  Date: "Date",
  AddMoreProject: "Add more project",
  CurrentWorkingProject: "Current working project",
  AddMoreReference: "Add References",
  Hobby: "Hobby",
  GPA: "GPA",
  SpecifyHeader: "Please specify Header.",
  SpecifyName: "Please specify Name.",
  SpecifyDOB: "Please specify Date of Birth.",
  SpecifyAddress: "Please specify Address.",
  SpecifyEmal: "Please specify valid Email Id.",
  SpecifySummary: "Please specify Summary.",
  SpecifySkill: "Please specify atleast one Skill details.",
  SpecifyWorkHistory: "Please specify atleast one Work Experiance details.",
  SpecifySkillName: "Please Specify Skill.",
  SkillLevelHelpText: "i.e Novice, Competent, Proficient or Expert.",
  Novice: "Novice",
  AdvancedBeginner: "Advanced Beginner",
  Competent: "Competent",
  Proficient: "Proficient",
  Expert: "Expert",
  SpecifyReference: "Please specify Reference.",
  SpecifyLanguage: "Please specify Language.",
  SpecifyHobby: "Please specify Hobby.",
  SpecifyCertification: "Please specify Certification.",
  SpecifyAward: "Please specify Award.",
  SpecifyProject: "Please specify Project.",
  SpecifyAcedemy: "Please specify Education.",
  SpecifyCompanyName: "Please specify Company Name.",
  SpecifyPosition: "Please specify Postion.",
  lessThanJoiningDate: "Leaving Date can not less then Joining Date.",
  SpecifyInstitutionName: "Please specify Institution Name",
  SpecifyFieldOfStudy: "Please specify Field of Study",
  lessThanEduStartDate: "End Date can not less then Start Date.",
  SpecifyCertificateTitle: "Please specify Title.",
  SpecifyProjectTitle: "Please specify Title",
  lessThanProStartDate: "End Date can not less then Start Date.",
  SpecifyAwardTitle: "Please specify Title.",
  SpecifyReferenceName: "Please specify Name",
  Native: "Native",
  Fluent: "Fluent",
  Advanced: "Advanced",
  SpecifyLanguageName: "Please specify Language.",
  SpecifyHobbyName: "Please specify Hobby.",
  PageBreakInfo: "Page will break from here. you can adjust it by dragging.",
  Download: "Download",
  MoreTemplate: "More template",
  Save: "Save",
  ResumeSaveSuccessfullyMessage: "Resume saved successfully.",
  ResumeUpdateSuccessfullMessage: "Resume updated successfully.",
  SpecifyMobile: "Please specify Mobile Number.",
  SpecifyValidDate: "Please specify valid Date.",
  SpecifyVaildMobile: "Please specify valid Mobile Number",
  DashboardTemplateHeader: "Resume Templates",
  DashboardUserTemplateHeader: "Your Resume",
  ModifyDate: "Modify Date",
  Edit: "Edit",
  HomeMainMenu: "Home",
  ResumeMainMenu: "Resume",
  AboutMainMenu: "About",
  UsersMainMenu: "Users",
  TemplateMainMenu: "Template",
  TamplateName: "Template Name",
  CreatedDate: "Created Date",
  ModifiedDate: "Modified Date",
  Status: "Status",
  Publish: "Publish",
  Unpublish: "Unpublish",
  CreateTemplate: "Create Template",
  Search: "Search",
  SearchByName: "Search By Name",
  NoDataFound: "No Template data found.",
  PublishConfirmetionMsg: "Are you sure, you want to Publish Template.",
  UnpublishConfirmetionMsg: "Are you sure, you want to Unpublish Template.",
  PublishSuccessMsg: "Template Published successfully.",
  UnpublishSuccessMsg: "Template Unpublished successfully.",
  Template: "Template",
  TemplateSavedSuccessfully: "Template saved successfully.",
  TemplateUpdatedSuccessfully: "Template updated successfully.",
  StrongPasswordMsg:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
  InvalidUrl: "Invalid Url.",
  EmailVerifyingMsg:
    "Please click on the link that has been sent to your email account to verify your email.",
  AccountCreatedSuccessMsg:
    "Your account has been created successfully. Please click on the link that has been sent to your email account to verify your email.",
  Delete: "Delete",
  DeleteConfirmation: "Are you sure you want to delete.",
  DeleteSuccessMsg: "Resume deleted successfully.",
  CreateResume: "Choose Template",
  SelectedTemplateRemovedInfo:
    "Your selected Template has been removed because of some technical issue, so please edit this and select other template.",
  SpecifyTemplate: "Please specify Template.",
  SpecifyStatus: "Please specify Status.",
  NameNotExceedFiftyCharecter: "Name character cannot be exceed 50 characters.",
  Update: "Update",
  Close: "Close",
  PasswordResetSuccessful: "Password reset successful.",
  NetworkRequestFailed: "Internet connection is interrupted..",
  ResendMail: "Resend Email",
  SpecifyFeedback: "Please specify feedback.",
  FeedbackLengthMsg: "Feedback can contain only 4000 character.",
  Feedback: "Feedback",
  FeedbackSuccessMsg: "Feedback submitted successfully.",
  FeedbackLabel: "Feedback",
  FeedbackCaption: "Feedback",
  Rating: "Rating",
  UserFeedbackCaption: "User Feedback",
  All: "All",
  Read: "Read",
  Unread: "Unread",
  UserName: "User Name",
  View: "View",
  TotalRecord: "Total Records",
  FeedbackDetail: "User Feedback Details",
  ChangePassword: "Change Password",
  Logout: "Logout",
  OnlyPositiveNumberAllow: "Please specify only positive valid number.",
  Sequence: "Sequence",
  CreatedBy: "Created By",
  ModifiedBy: "Modified By",
  Weightage: "Weightage",
  OrderInfo:"Sequence and Weightage field will use for ordering Template. First Template will order by Sequence then by Weightage."
};

export default translation_en;