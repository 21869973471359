import React from 'react';
import Layout from '../components/Layout';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { AuthoriseUri } from "../Utility/PageUrls";

export default function About() {
    return (
      <Layout menuRoute={AuthoriseUri.About}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ my: 2 }}>
              {[...new Array(24)]
                .map(
                  () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`
                )
                .join("\n")}
            </Box>
          </Grid>
        </Grid>
      </Layout>
    );
}
