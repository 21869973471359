import React from "react";
import * as Types from "../Utility/typeDef";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const AlertDialog: React.FC<Types.IDialogProps> = ({
  showAlert,
  onCloseHandler,
  title,
  description,
  buttons,
}) => {
  

  const dialogTitle = title && (
    <DialogTitle id="alert-dialog-title">
      {title}
    </DialogTitle>
  );
  const dialogButtons = buttons?.map((btn,index) =>(
      <Button key={index}
        onClick={() => {
          onCloseHandler();
          btn.fn();
        }}
        color="primary"
        autoFocus={index === buttons.length - 1}
      >
        {btn.title}
      </Button>
  ))

  return (
    <Dialog
      open={showAlert}
      onClose={onCloseHandler}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby={dialogTitle && "alert-dialog-title"}
      aria-describedby="alert-dialog-description"
    >
      {dialogTitle && dialogTitle}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {dialogButtons ? (
          dialogButtons
        ) : (
          <Button onClick={onCloseHandler} color="primary" autoFocus>
            Okay
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
