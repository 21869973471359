

export const GetFormattedDate =(date:Date)=>
{
  try
  {
  let day = ('0' + date.getDate()).slice(-2);
  let month = ('0' + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  if (day !== undefined && month != undefined && year != undefined) {
    return day + "/" + month + "/" + year;
  }
  else
  {
     return "";
  }
    
  }
  catch(error)
  {
    return ""
  }

}

export const TryToGetDateTime = (object:any)=>
{
  if(object.constructor.name == 'pt')
  {
    return object.toDate();
  }

  if (
    (object.constructor.name == "e" || object.constructor.name == "Object") &&
    object.hasOwnProperty("seconds") &&
    object.hasOwnProperty("nanoseconds")
  ) {
    return new Date(object.seconds * 1000);
  }
  
  return object;
}
