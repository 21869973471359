import React,{useEffect} from 'react';
import {  FeedbackModel } from "../model/FeedbackModel";
import { Grid, Box, Typography, Rating } from "@mui/material";
import { useTranslation } from "react-i18next";
import { updateFeedbackStatus, filePath } from "../service/firebase";

const UserFeedbackDetails: React.FC<{ Data: FeedbackModel }> = (props) => {
 const { t } = useTranslation();
 
 const updateStatus = async () => {
   window.showProgressbar();
   await updateFeedbackStatus(props.Data.Id, { Read: true });
   window.hideProgressbar();
 };

 const getImageUrl =async () => {
  const getImageUrlCallback =(isError:boolean,message:string,url:string)=>{
    if(!isError)
    {
    const img = document.getElementById("feedbackImage");
    img!.setAttribute("src", url);
    }

  }


   window.showProgressbar();
   if (props.Data.ImagePath)
   {
    await filePath(props.Data.ImagePath, getImageUrlCallback);
   }
     
   window.hideProgressbar();
 }

 useEffect(()=>{
     updateStatus();
     getImageUrl();
 },[])

  return (
    <Grid container spacing={2} justifyContent="center">
      <Grid container item xs={12} md={8}>
        <Grid item xs={12} md={6}>
          <Box >
            <Typography className='kunal' variant="subtitle2">{t("Name")}</Typography>
            <Typography variant="caption">{props.Data.User.Name}</Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle2">{t("Mobile")}</Typography>
            <Typography variant="caption">{props.Data.User.Mobile}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="subtitle2">{t("Email")}</Typography>
            <Typography variant="caption">{props.Data.User.Email}</Typography>
          </Box>
          <br />
          <Box>
            <Typography variant="subtitle2">{t("Rating")}</Typography>
            <Rating value={props.Data.FeedbackRating} readOnly />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <br />
          <Box>
            <Typography variant="subtitle2">{t("Feedback")}</Typography>
            <Typography variant="caption">{props.Data.Feedback}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <img id="feedbackImage" />
        </Grid>
      </Grid>
    </Grid>
  );
};


export default UserFeedbackDetails;
