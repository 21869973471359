import React from "react";
import * as Type from "../Utility/typeDef";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SummaryEditor from "./SummaryEditor";
import '../myStyles/inputInformation.css';


const Reference: React.FC<Type.IReference> = (props) => {
  const { t } = useTranslation();
  console.log("Reference");

  const getErrorString = (index: number, propsName: string): string => {
    if (
      Array.isArray(props.ReferenceHistoryError) &&
      props.ReferenceHistoryError[index] !== null &&
      props.ReferenceHistoryError[index] !== undefined &&
      props.ReferenceHistoryError[index].hasOwnProperty(propsName)
    ) {
      return t(props.ReferenceHistoryError[index][propsName]);
    }
    return "";
  };

  const getTouchedValue = (index: number, propsName: string): boolean => {
    if (
      Array.isArray(props.ReferenceHistoryTouched) &&
      props.ReferenceHistoryTouched[index] !== null &&
      props.ReferenceHistoryTouched[index] !== undefined &&
      props.ReferenceHistoryTouched[index].hasOwnProperty(propsName)
    ) {
      return props.ReferenceHistoryTouched[index][propsName];
    }
    return false;
  };

  const noReferenceHistoryError =
    Array.isArray(props.ReferenceHistoryTouched) &&
    props.ReferenceHistoryTouched.length == 0 &&
    typeof props.ReferenceHistoryError == "string" &&
    props.ReferenceHistoryError != undefined ? (
      <Alert severity="error" sx={{ mt: 1 }}>
        {t(props.ReferenceHistoryError)}
      </Alert>
    ) : (
      <span></span>
    );

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} className="reference_div">
          <TextField
            id="Reference_Headers"
            label="Header"
            variant="outlined"
            className='resume_input'
            size="small"
            fullWidth
            name="Reference.Header"
            value={props.Header.value}
            onChange={props.onChangeHandler}
            onBlur={props.onBlurHandler}
            error={props.Header.isError}
            helperText={props.Header.errorMessage}
            required
          />
          {noReferenceHistoryError}
        </Grid>
        <Grid item xs={12}>
          {props.ReferenceHistory?.map((w, i) => {
          let nameError =
            getTouchedValue(i, "Name") && getErrorString(i, "Name");
          let emailError =
            getTouchedValue(i, "Email") && getErrorString(i, "Email");
          let phoneError =
            getTouchedValue(i, "PoneNumber") && getErrorString(i, "PoneNumber");
            return (
              <React.Fragment key={i}>
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardHeader
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={(e) => props.RemoveReferenceHistory(i)}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                    sx={{ pb: 0, pt: 1 }}
                  />
                  <CardContent sx={{ pt: "1px" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t("Name")}
                          variant="outlined"
                          className='resume_input'
                          size="small"
                          fullWidth
                          name={`Reference.ReferencesDetail[${i}].Name`}
                          value={w.Name}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={nameError}
                          error={Boolean(nameError)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t("Email")}
                          variant="outlined"
                          size="small"
                          className='resume_input'
                          fullWidth
                          name={`Reference.ReferencesDetail[${i}].Email`}
                          value={w.Email}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={emailError}
                          error={Boolean(emailError)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t("MobileNumber")}
                          variant="outlined"
                          size="small"
                          className='resume_input'
                          fullWidth
                          name={`Reference.ReferencesDetail[${i}].PoneNumber`}
                          value={w.PoneNumber}
                          onChange={props.onChangeHandler}
                          onBlur={props.onBlurHandler}
                          helperText={phoneError}
                          error={Boolean(phoneError)}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t("Position")}
                          variant="outlined"
                          size="small"
                          fullWidth
                          className='resume_input'
                          name={`Reference.ReferencesDetail[${i}].Position`}
                          value={w.Position}
                          onChange={props.onChangeHandler}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SummaryEditor
                          Summary={w.Summary}
                          ParentArrayLenght={props.ReferenceHistory?.length}
                          CallbackFun={(html: any) => {
                            props.SetValueByPropNameHandler(
                              `Reference.ReferencesDetail[${i}].Summary`,
                              html
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item xs={12} className="reference_btn">
          <Button
            variant="contained"
            fullWidth
            onClick={props.AddReferenceHistrory}
            className="btn_work"
          >
            {t("AddMoreReference")}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};


export default React.memo(Reference);
