import React, { useContext, useState } from "react";
import {useLocation} from "react-router-dom";
import Layout from "../components/Layout";
import {
  Grid,
  Dialog,
  Alert,
  DialogContent,
  Button,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import { Send } from "@mui/icons-material";
import { AuthContext } from "../context/AuthContext";

import "react-quill/dist/quill.snow.css";
import DashboardTemplateView from "../components/DashboardTemplateView";
import UserAllResume from "../components/UserAllResume";
import {
  getAllResumeTemplate,
  SendEmailVerification,
  getUserAllResume,
} from "../service/firebase";
import UserForm from "../components/UserForm";
import { AuthoriseUri } from "../Utility/PageUrls";
import { useTranslation } from "react-i18next";
import { ResumeBuilderModel } from "../model/ResumeBuilderModel";
import '../myStyles/layout.css';

const Home: React.FC = () => {
  const [templates, setTemplate] = React.useState<any[]>();
  const [userResumes, setUserResumes] = useState<ResumeBuilderModel[]>();
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const sessionUser = authContext.getSessionUser();
  const user = (sessionUser as any).user;
const location = useLocation();

  const getTemplates = async () => {
    window.showProgressbar();
    let result = await getAllResumeTemplate(
      authContext?.getUserDatail?.Role == "admin"
    );
    setTemplate(result);
    window.hideProgressbar();
  };

  const getuserAllResume = async () => {
    const callbackFunc = (isError: boolean, message: string) => {
      !isError &&
        Boolean(message) &&
        window.showGenericAlertDialog(undefined, t(message));
    };

    window.showProgressbar();
    let result = await getUserAllResume(user.uid, callbackFunc);
    setUserResumes(result);
    window.hideProgressbar();
  };

  React.useEffect(() => {
    getTemplates();
    getuserAllResume();
  }, [authContext?.getUserDatail?.Role]);

  return (

    <Layout menuRoute={AuthoriseUri.Dashboard}>
      <Grid
        sx={{ pl: 1, pr: 1 }}
        spacing={2}
        className="home_main"
        style={{display: 'block !important;'}}
      >
        <Grid
          container
          item
          xs={12}
          md={userResumes?.length == 0 ? 10 : 9}
          xl={userResumes?.length == 0 ? 8 : 6}
          alignContent="flex-start"
          justifyContent="flex-start"
          className="home_template_grp"
        >
          <DashboardTemplateView
            Template={templates?.sort(function (a, b) {
              return (
                a.Sequence - b.Sequence ||
                b.Weightage - a.Weightage ||
                a.Name.localeCompare(b.Name)
              );
            })}
            UserResumes={userResumes}
          />
        </Grid>
        {/* <Grid item
         xs={12}
          >
{window.location.href}
        </Grid> */}
        <Grid
          container
          item
          xs={12}
          md={userResumes?.length == 0 ? 12 : 3}
          xl={userResumes?.length == 0 ? 12 : 2}
          className="userResume"
        >
          <UserAllResume Template={templates} UserResumes={userResumes} />
        </Grid>
        <Grid item>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={
              (authContext.userDetailStatus.isDetailMissig &&
                authContext.userDetailStatus.isGettingProcessCompleted) ||
              (authContext.isGetCurrentUserCompleted && !user.emailVerified)
            }
          >
            <DialogContent>
              {!user.emailVerified && (
                <Alert
                  severity="info"
                  action={
                    <Tooltip title={t("ResendMail") as string}>
                      <IconButton
                        color="inherit"
                        size="small"
                        aria-label="upload picture"
                        component="span"
                        onClick={SendEmailVerification}
                      >
                        <Send />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  {t("EmailVerifyingMsg")}
                </Alert>
              )}

              {authContext.userDetailStatus.isDetailMissig && (
                <React.Fragment>
                  <br />
                  <UserForm
                    UserId={user.uid}
                    Email={user.email}
                    Name={user["displayName"] ? user.displayName : ""}
                  />
                </React.Fragment>
              )}
            </DialogContent>
          </Dialog>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;
